export class KgReviver {
  static Revive<T>(reviveObject: T, classConstructor?: new (a: any) => T): T {
    return new classConstructor(reviveObject);
  }

  static ReviveArray<T>(
    reviveObject: T[],
    classConstructor?: new (a: any) => T
  ): T[] {
    if (Array.isArray(reviveObject)) {
      if (reviveObject != null) {
        return reviveObject.map(x => {
          let newp = new classConstructor(x);
          return newp;
        });
      }
    }
  }
}
