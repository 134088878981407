import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgComponentChipsComponent } from "./kg-component-chips/kg-component-chips.component";
import { ChipStyleDirective } from "src/app/modules/libs/kg-chips/kg-component-chips/kg-chips.directive";

@NgModule({
  imports: [CommonModule],
  exports: [KgComponentChipsComponent],
  declarations: [KgComponentChipsComponent, ChipStyleDirective]
})
export class KgChipsModule {}
