import { Injectable } from "@angular/core";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { HttpClient, HttpRequest, HttpHeaders } from "@angular/common/http";
import { KgUnitsConfigResponse } from "src/app/model/kg-communities-config/kg-units-config-response";
import { map, catchError } from "rxjs/operators";
import { KgReviver } from "src/app/framework/model/kg-reviver";
import { throwError, Observable } from "rxjs";
import { environment } from "environments/environment";
import { KgCommunitiesSettingsResponse } from "src/app/model/kg-communities-config/kg-communities-settings-response";

@Injectable()
export class KgCommunityConfigService {
  random = Math.random();
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  getCommunitiesUnitsConfig(): Observable<KgUnitsConfigResponse> {
    return this.httpClient
      .get<KgUnitsConfigResponse>(
        `${environment.ApiHost}api/communitiesconfig/unitsconfig`
      )
      .pipe(
        map(x =>
          KgReviver.Revive<KgUnitsConfigResponse>(x, KgUnitsConfigResponse)
        ),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getCommunitiesSettings(): Observable<KgCommunitiesSettingsResponse> {
    return this.httpClient
      .get<KgCommunitiesSettingsResponse>(
        `${environment.ApiHost}api/communitiesconfig/communitiessettings`
      )
      .pipe(
        map(x =>
          KgReviver.Revive<KgCommunitiesSettingsResponse>(
            x,
            KgCommunitiesSettingsResponse
          )
        ),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
