import { EventEmitter } from "@angular/core";

// export class KgComponentMaterialsTabModel {
//   onTextfieldFind = new EventEmitter<boolean>();
//   items: KgComponentMaterialsTabItemModel[];
//   constructor(items: KgComponentMaterialsTabItemModel[]) {
//     this.items = items;
//   }
// }

export class KgComponentMaterialsTabItemModel {
  label: string;
  path: string;
  icon?: string;
  onTabItemClick?: EventEmitter<KgComponentMaterialsTabItemModel>;

  constructor(value: any, label: string) {
    this.path = value;
    this.label = label;
  }
}

export enum KgTabType {
  tab = 1,
  tabBar = 2,
  tabBarScroller = 3
}
