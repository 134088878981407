import {
  Salutation,
  Gender,
  IdentificationTypes,
  PeopleStatuses,
  PeopleRoles
} from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";
import { KgAccessCardModel } from "../../model/kg-people/kg-accesscard.model";
import { KgFileItemInfoModel } from "src/app/model/kg-filemanager/kg-filemanager.model";
import { KgUnitModel } from "src/app/model/kg-units/kg-unit.model";

export enum PeoplesSearchCategory {
  name = 0,
  unitno = 1,
  icpassport = 2,
  phonenumber = 3,
  email = 4,
  country = 5
}

export enum NewPeopleMode {
  CreateNew,
  NewMasterTenant,
  NewTenant,
  NewResident,
  EditExisting
}

export enum PhotoSizeTypes {
  Raw = 0,
  Icon24px = 1,
  Icon36px = 2,
  Icon48px = 3,
  Icon128px = 4,
  Icon192px = 5,
  Large600px = 6
}
export class PushNotificationToken {
  Token: string;
  DateAdded: Date;
  IsActive: boolean;
  UserAgent: string;
  constructor(
    token: string,
    dateAdded: Date,
    isActive: boolean,
    userAgent: string
  ) {
    this.Token = token;
    this.DateAdded = dateAdded;
    this.IsActive = isActive;
    this.UserAgent = userAgent;
  }
}

export class ProfilePhotosModel extends KgFileItemInfoModel {
  constructor() {
    super();
    // Object.assign(this, { IsPrimary: false, PhotoSizeType: [] });
  }
  IsPrimary: boolean;
  PhotoSizeType: PhotoSizeTypes;
  PhotoUuid: string;
}

export class KgUnitResponse extends KgUnitModel {
  PeoplesDetails: KgPeopleModel[];
}

export class KgPeopleModel extends KgBaseModel {
  Salutation: Salutation;
  FirstName: string;
  LastName: string;
  Gender: Gender;
  IdentificationType: IdentificationTypes;
  IdentificationNumber: string;
  Nationality: string;
  ContactNumberCountryCode: string;
  ContactNumber: string;
  MobileNumberCountryCode: string;
  MobileNumber: string;
  DateOfBirth: Date;
  Email: string;
  Password: string;
  LastPasswordChangedDate: Date;
  FailedPasswordAttempt: number;
  PeopleStatus: PeopleStatuses;
  Remarks: string;
  Roles: PeopleRoles[];
  AccessCards: KgAccessCardModel[];
  ProfilePhotos: ProfilePhotosModel[];
  NotificationTokens: PushNotificationToken[];

  constructor(peopleObject?: KgPeopleModel) {
    super();
    if (peopleObject != null) {
      Object.assign(this, peopleObject, {
        LastPasswordChangedDate: new Date(peopleObject.LastPasswordChangedDate),
        CreateDate: new Date(peopleObject.CreateDate),
        ActionDate: new Date(peopleObject.ActionDate),
        DateOfBirth: new Date(peopleObject.DateOfBirth),
        AccessCards: peopleObject.AccessCards
          ? peopleObject.AccessCards.map(x => {
              return new KgAccessCardModel(x);
            })
          : [],
        Roles: peopleObject.Roles ? peopleObject.Roles : [],
        ProfilePhotos: peopleObject.ProfilePhotos
          ? peopleObject.ProfilePhotos
          : [],
        NotificationTokens: peopleObject.NotificationTokens
          ? peopleObject.NotificationTokens
          : []
      });
    } else {
      Object.assign(this, {
        Salutation: 0,
        FirstName: "",
        LastName: "",
        Gender: Gender,
        IdentificationType: 0,
        IdentificationNumber: "",
        Nationality: "",
        ContactNumber: "",
        ContactNumberCountryCode: "",
        MobileNumber: "",
        MobileNumberCountryCode: "",
        DateOfBirth: new Date(0),
        Email: "",
        Password: "",
        LastPasswordChangedDate: new Date(0),
        FailedPasswordAttempt: 0,
        PeopleStatus: 0,
        Remarks: "",
        Roles: [],
        AccessCards: [],
        ProfilePhotos: [],
        NotificationTokens: []
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  // toJSON(): any {
  //   // copy all fields from `this` to an empty object and return
  //   return Object.assign({}, this, {
  //     // convert fields that need converting
  //     LastPasswordChangedDate: this.LastPasswordChangedDate.toISOString(),
  //     CreateDate: this.CreateDate.toISOString(),
  //     ActionDate: this.ActionDate.toISOString(),
  //     DateOfBirth: this.DateOfBirth.toISOString()
  //   });
  // }

  // // JSON.parse() will call this automatically
  // static fromJSON(json: string | KgPeopleModel): KgPeopleModel {
  //   if (typeof json === 'string') {
  //     // if it's a string, parse it first
  //     return JSON.parse(json, KgPeopleModel.reviver);
  //   } else {
  //     // create an instance of the User class
  //     let people = Object.create(KgPeopleModel.prototype);
  //     // copy all the fields from the json object
  //     return Object.assign(people, json, {
  //       // convert fields that need converting
  //       LastPasswordChangedDate: new Date(json.LastPasswordChangedDate),
  //       CreateDate: new Date(json.CreateDate),
  //       ActionDate: new Date(json.ActionDate),
  //       DateOfBirth: new Date(json.DateOfBirth)
  //     });
  //   }
  // }

  // static reviver(key: string, value: any) {
  //   return key === "" ? KgPeopleModel.fromJSON(value) : value;
  // }
}
