import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { HttpSentEvent } from "@angular/common/http";
import { HttpHeaderResponse } from "@angular/common/http";
import { HttpProgressEvent } from "@angular/common/http";
import { HttpResponse } from "@angular/common/http";
import { HttpUserEvent } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError as _throw } from "rxjs";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import {
  KgSnackbarService,
  MDCSnackbarData
} from "src/app/services/kg-snackbar/kg-snackbar.service";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";
import { UUID } from "angular2-uuid";
import { finalize, take, filter, catchError, switchMap } from "rxjs/operators";
import { environment } from "environments/environment";
import { contains } from "@firebase/util";

@Injectable()
export class KgRequestInterceptorService implements HttpInterceptor {
  random: number;
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  authenticationService: KgAuthenticationService;
  snackbarService: KgSnackbarService;
  progressBarService: KgProgressBarService;
  isResetPassword = false;

  constructor(private injector: Injector) {
    this.random = Math.random() * 10000;
    console.log("intercepter instance id: ", this.random);
  }

  addBearerAndHeaders(
    req: HttpRequest<any>,
    token: string,
    overwrite?: boolean
  ): HttpRequest<any> {
    var reqHeaders = req.headers.set("kgrequestid", UUID.UUID());

    if (
      overwrite ||
      (req.headers &&
        !req.headers.has("Authorization") &&
        !req.headers.has("SkipAuthorization"))
    ) {
      // let cloned = req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
      reqHeaders = reqHeaders.set("Authorization", "Bearer " + token);
    }

    reqHeaders = reqHeaders.append("kg-api-version", environment.ApiVersion);

    return req.clone({ headers: reqHeaders });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    this.authenticationService = this.authenticationService
      ? this.authenticationService
      : this.injector.get<KgAuthenticationService>(KgAuthenticationService);
    this.isResetPassword = req.headers.has("ResetPassword");
    return next
      .handle(
        this.addBearerAndHeaders(req, this.authenticationService.accessToken)
      )
      .pipe(
        catchError((error, cought) => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 400:
                return this.handle400Error(error);
              case 401:
                return this.handle401Error(error, req, next);
              case 403:
                return this.handle403Error(error);
              default:
                return _throw(error);
            }
          } else {
            return _throw(error);
          }
        })
      );
  }

  handle401Error(
    error: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (this.isResetPassword) {
      //throw back error to resetpassword caller
      return _throw(error);
    } else if (!this.isRefreshingToken) {
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;
      console.log("isRefreshingToken", this.isRefreshingToken);

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.authenticationService = this.authenticationService
        ? this.authenticationService
        : this.injector.get<KgAuthenticationService>(KgAuthenticationService);

      return this.authenticationService.renewToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            // console.log("newToken Recieved:", newToken);

            this.tokenSubject.next(newToken);
            this.authenticationService.storeRenewedToken(newToken);
            return next.handle(this.addBearerAndHeaders(req, newToken, true));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
          console.log("isRefreshingToken", this.isRefreshingToken);
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          console.log(
            "newtoken:",
            token.substr(token.length - 20, token.length - 1),
            req.url
          );
          return next.handle(this.addBearerAndHeaders(req, token, true));
        })
      );
    }
  }

  handle400Error(error) {
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === "invalid_grant"
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }

    return _throw(error);
  }

  handle403Error(error) {
    if (error.status === 403) {
      this.snackbarService = this.snackbarService
        ? this.snackbarService
        : this.injector.get<KgSnackbarService>(KgSnackbarService);
      this.progressBarService = this.progressBarService
        ? this.progressBarService
        : this.injector.get<KgProgressBarService>(KgProgressBarService);
      let snackbarData: MDCSnackbarData = {
        onClosed: () => {
          this.progressBarService.stop();
          // this._location.back();
        },
        labelText: "Sorry, permission denied",
        actionButtonText: "Dismiss",
        timeoutMs: 10000
      };
      this.snackbarService.show(snackbarData);
    }

    return _throw(error);
  }

  // /*
  //     This method is only here so the example works.
  //     Do not include in your code, just use 'req' instead of 'this.getNewRequest(req)'.
  // */
  // getNewRequest(req: HttpRequest<any>): HttpRequest<any> {
  //   if (req.url.indexOf('getData') > 0) {
  //     return new HttpRequest('GET', 'http://private-4002d-testerrorresponses.apiary-mock.com/getData');
  //   }

  //   return new HttpRequest('GET', 'http://private-4002d-testerrorresponses.apiary-mock.com/getLookup');
  // }

  logoutUser() {
    // Route to the login page (implementation up to you)

    return _throw("");
  }
}
