import { EventEmitter } from "@angular/core";

export class KgComponentMaterialsCheckboxModel {
  onClick = new EventEmitter<boolean>();
  items: KgComponentMaterialsCheckboxItemModel[];
  constructor(items: KgComponentMaterialsCheckboxItemModel[]) {
    this.items = items;
  }
}

export class KgComponentMaterialsCheckboxItemModel {
  value: any;
  label: string;

  constructor(value: any, label: string) {
    this.value = value;
    this.label = label;
  }
}
