import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import {
  KgComplaintModel,
  ComplaintStatuses
} from "src/app/model/kg-complaint/kg-complaint";
import { KgComponentMaterialsDataListSettingModel } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { KgComplaintService } from "src/app/services/kg-complaint/kg-complaint.service";
import { Subscription } from "rxjs";
import { KgLoggingService } from "src/app/framework/logging/kg-logging.service";
import { PeopleRoles } from "src/app/model/kg-core";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";

@Component({
  selector: "app-kg-complaint-dashboard",
  templateUrl: "./kg-complaint-dashboard.component.html",
  styleUrls: ["./kg-complaint-dashboard.component.scss"]
})
export class KgComplaintDashboardComponent implements OnInit, OnDestroy {
  maxItems: number = 5;

  @Input() kgcomplaintList: KgComplaintModel[] = [];
  @Input() kgcomplaintSetting: KgComponentMaterialsDataListSettingModel<
    KgComplaintModel
  >;

  subscriptionGetComplaint: Subscription;
  complaintLink: string;
  errorText: string;

  ComplaintStatusEnum = ComplaintStatuses;

  constructor(
    private complaintService: KgComplaintService,
    private authenticationService: KgAuthenticationService,
    private logs: KgLoggingService
  ) {
    if (
      this.authenticationService.hasAccessRoles([
        PeopleRoles.Administrator,
        PeopleRoles.Chairperson,
        PeopleRoles.Committee,
        PeopleRoles.Manager,
        PeopleRoles.Clerk
      ])
    ) {
      this.complaintLink = "/complaint";
    } else {
      this.complaintLink = "/complaint/createdbyme";
    }

    if (this.kgcomplaintList == null || this.kgcomplaintList.length == 0) {
      this.errorText = "Complaint list is empty";
    }
  }

  getByStatus(complaintStatus: ComplaintStatuses) {
    if (
      this.authenticationService.hasAccessRoles([
        PeopleRoles.Administrator,
        PeopleRoles.Chairperson,
        PeopleRoles.Committee,
        PeopleRoles.Manager,
        PeopleRoles.Clerk
      ])
    ) {
      this.subscriptionGetComplaint = this.complaintService
        .getLimitedComplaintByComplaintStatus(this.maxItems, complaintStatus)
        .subscribe(
          complaint => {
            let resultparse = complaint as KgComplaintModel[];
            this.kgcomplaintList = resultparse.map(x => {
              return new KgComplaintModel(x);
            });
          },
          err => {
            this.kgcomplaintList = null;
            this.errorText = "Complaint list is empty";
            this.logs.error("Get complaint by status dashboard", err);
          }
        );
    } else {
      this.subscriptionGetComplaint = this.complaintService
        .getLimitedComplaintByComplaintStatusCreatedByMe(
          this.maxItems,
          complaintStatus
        )
        .subscribe(
          complaint => {
            let resultparse = complaint as KgComplaintModel[];
            this.kgcomplaintList = resultparse.map(x => {
              return new KgComplaintModel(x);
            });
          },
          err => {
            this.kgcomplaintList = null;
            this.errorText = "Complaint list is empty";
            this.logs.error(
              "Get complaint by status createdbyme dashboard",
              err
            );
          }
        );
    }
  }

  getComplaintList() {
    if (
      this.authenticationService.hasAccessRoles([
        PeopleRoles.Administrator,
        PeopleRoles.Chairperson,
        PeopleRoles.Committee,
        PeopleRoles.Clerk,
        PeopleRoles.Manager
      ])
    ) {
      this.subscriptionGetComplaint = this.complaintService
        .getLimitedComplaint(this.maxItems, "ActionDate")
        .subscribe(
          complaint => {
            let resultparse = complaint as KgComplaintModel[];
            this.kgcomplaintList = resultparse.map(x => {
              return new KgComplaintModel(x);
            });
          },
          err => {
            this.kgcomplaintList = null;
            this.logs.error("Get maximum 5 complaint for dashboard", err);
          }
        );
    } else {
      this.subscriptionGetComplaint = this.complaintService
        .getLimitedComplaintCreatedByMe(this.maxItems)
        .subscribe(
          complaint => {
            let resultparse = complaint as KgComplaintModel[];
            this.kgcomplaintList = resultparse.map(x => {
              return new KgComplaintModel(x);
            });
          },
          err => {
            this.kgcomplaintList = null;
            this.logs.error("Get maximum 5 complaint for dashboard", err);
          }
        );
    }
  }

  DisplaySecondaryTitle(item: KgComplaintModel) {
    return `${item.CreateByPeopleName} - [${
      ComplaintStatuses[item.ComplaintStatus]
    }]`;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscriptionGetComplaint) {
      this.subscriptionGetComplaint.unsubscribe();
    }
  }
}
