import { KgPeopleModel } from "../kg-people/index";
import { KgCommunitiesModel } from "../kg-communities/index";

export class KgPeopleCommunitiesModel extends KgPeopleModel {
  CommunitiesCollection: KgCommunitiesModel[];

  constructor(cpModel?: KgPeopleCommunitiesModel) {
    super(cpModel);
    Object.assign(this, cpModel);
  }
}
