export class KgArray {
  static SortAsc(a: any, b: any, ignoreCase?: boolean): number {
    if (ignoreCase) {
      if (a.toUpperCase() == b.toUpperCase()) {
        return 0;
      }
      return a.toUpperCase() > b.toUpperCase() ? 1 : -1;
    } else {
      if (a == b) {
        return 0;
      }
      return a > b ? 1 : -1;
    }
  }

  static SortDesc(a: any, b: any, ignoreCase?: boolean): number {
    if (ignoreCase) {
      if (a.toUpperCase() == b.toUpperCase()) {
        return 0;
      }
      return a.toUpperCase() < b.toUpperCase() ? 1 : -1;
    } else {
      if (a == b) {
        return 0;
      }
      return a < b ? 1 : -1;
    }
  }
}
