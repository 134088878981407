import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { MDCSnackbarCloseEventDetail } from "@material/snackbar";
// import { MDCSnackbarData } from '@material/snackbar/foundation';

export interface MDCSnackbarData {
  timeoutMs?: number;
  closeOnEscape?: boolean;
  labelText: string;
  actionButtonText: string;
  onOpening?: () => void;
  onOpened?: () => void;
  onClosing?: (reason: MDCSnackbarCloseEventDetail) => void;
  onClosed?: (reason: MDCSnackbarCloseEventDetail) => void;
}

@Injectable()
export class KgSnackbarService {
  instanceId = Math.random();
  constructor() {}

  // Observable string sources
  private snackbarSubject = new Subject<MDCSnackbarData>();

  // Observable string streams
  snackbar$ = this.snackbarSubject.asObservable();

  // Service message commands
  show(data: MDCSnackbarData) {
    this.snackbarSubject.next(data);
  }
}
