import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PeopleRoles } from "../../model/kg-core";
import { environment } from "../../../environments/environment";
import { KgPeopleModel } from "src/app/model/kg-people";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";

export interface TokenPayloadModel {
  sub: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  jti: string;
  iat: number;
  CommunitiesId: string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string[];
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}

export interface SignInResponse {
  PrimaryToken: string;
  AccessToken: string;
}

@Injectable()
export class KgAuthenticationService {
  public myprofile: KgPeopleModel;

  primaryToken: string;
  accessToken: string;

  primaryTokenPayload: TokenPayloadModel;
  accessTokenPayload: TokenPayloadModel;
  private authenticationSubject = new Subject<boolean>();
  authentication$ = this.authenticationSubject.asObservable();

  constructor(private httpClient: HttpClient, private router: Router) {
    this.readSessionFromStore();
  }

  readSessionFromStore() {
    this.primaryToken = localStorage.getItem("PrimaryToken");
    this.accessToken = localStorage.getItem("AccessToken");
    this.myprofile = localStorage.getItem("myprofile")
      ? JSON.parse(localStorage.getItem("myprofile"))
      : null;
    if (this.primaryToken && this.accessToken) {
      this.primaryTokenPayload = this.decodeToken(this.primaryToken);
      this.accessTokenPayload = this.decodeToken(this.accessToken);
    }
  }

  decodeToken(token: string) {
    return JSON.parse(window.atob(token.split(".")[1]));
  }

  // Service message commands
  announceUserAuthenticated(isAuthenticated: boolean) {
    this.authenticationSubject.next(isAuthenticated);
  }

  emailSignIn(email: string, password: string) {
    let body = { Email: email, Password: password, RememberMe: false };
    return this.httpClient.post<any>(
      `${environment.ApiHost}api/Account/email`,
      body
    );
  }

  mobileAuthentication(mobileNumber: string, authenticationCode: string) {
    let body = {
      MobileNumber: mobileNumber,
      AuthenticationCode: authenticationCode
    };
    return this.httpClient.post<any>(
      `${environment.ApiHost}api/Account/phone`,
      body
    );
  }

  authenticationCodeRequest(mobileNumber: string) {
    let body = JSON.stringify(mobileNumber);
    return this.httpClient.post<any>(
      `${environment.ApiHost}api/Account/requestcode`,
      body,
      { headers: new HttpHeaders({ "Content-Type": "application/json" }) }
    );
  }

  renewToken() {
    return this.httpClient.get(`${environment.ApiHost}api/account/renew`, {
      headers: this.getPrimaryTokenHeader()
    });
  }

  storeSession(singInResponse: SignInResponse) {
    localStorage.setItem("PrimaryToken", singInResponse.PrimaryToken);
    localStorage.setItem("AccessToken", singInResponse.AccessToken);

    this.readSessionFromStore();
  }

  storeRenewedToken(renewedToken: string) {
    localStorage.setItem("AccessToken", renewedToken);
    this.readSessionFromStore();
  }

  storeProfile(peopleProfile: KgPeopleModel) {
    localStorage.setItem("myprofile", JSON.stringify(peopleProfile));
    this.myprofile = localStorage.getItem("myprofile")
      ? JSON.parse(localStorage.getItem("myprofile"))
      : null;
    this.readSessionFromStore();
  }

  get hasSession(): boolean {
    if (
      this.primaryTokenPayload &&
      !this.isExpired(this.primaryTokenPayload.exp)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasAccess(): boolean {
    if (
      this.primaryTokenPayload &&
      !this.isExpired(this.accessTokenPayload.exp)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isExpired(exp: number): boolean {
    if (this.primaryTokenPayload) {
      let now = new Date();
      let expire = new Date(exp * 1000);
      return now > expire;
    }
  }

  hasAccessRole(role: PeopleRoles): boolean {
    if (
      Array.isArray(
        this.accessTokenPayload[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ]
      )
    ) {
      let accessRoles = this.accessTokenPayload[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ].map(x => {
        return +x;
      });

      return accessRoles.some(i => {
        return i == role;
      });
    } else {
      return (
        +this.accessTokenPayload[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ] == role
      );
    }
  }

  hasAccessRoles(roles: PeopleRoles[]) {
    if (
      Array.isArray(
        this.accessTokenPayload[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ]
      )
    ) {
      let accessRoles = this.accessTokenPayload[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ].map(x => {
        return +x;
      });

      return roles.some(element => {
        return accessRoles.some(x => x == element);
      });
    } else {
      return (
        roles.find(x => {
          return (
            x ==
            +this.accessTokenPayload[
              "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ]
          );
        }) != null
      );
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("PrimaryToken");
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("myprofile");
    localStorage.removeItem("MyCommunitiesDetail");
    this.primaryTokenPayload = null;
    this.accessTokenPayload = null;
    this.primaryToken = null;
    this.accessToken = null;
    this.myprofile = null;
    this.authenticationSubject.next(false);
  }

  private getPrimaryTokenHeader() {
    if (this.primaryToken) {
      let headers = new HttpHeaders({
        Authorization: `Bearer ${this.primaryToken}`
      });
      return headers;
    }
  }

  public getAccessTokenHeader() {
    if (this.accessToken) {
      let headers = new HttpHeaders({
        Authorization: `Bearer ${this.accessToken}`
      });
      return headers;
    }
  }

  public getSkipAuthorizationHeader() {
    return new HttpHeaders({ SkipAuthorization: "true" });
  }
}
