import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/Subject";

@Injectable()
export class KgProgressBarService {
  instanceId = Math.random();
  constructor(private router: Router) {}

  // Observable string sources
  private progressbarSubject = new Subject<boolean>();

  // Observable string streams
  progressbar$ = this.progressbarSubject.asObservable();

  // Service message commands
  toggleProgressBar(showProgressBar: boolean) {
    this.progressbarSubject.next(showProgressBar);
  }

  // Service message commands
  start() {
    this.progressbarSubject.next(true);
  }

  // Service message commands
  stop() {
    this.progressbarSubject.next(false);
  }
}
