import { Injectable } from "@angular/core";
import { KgPeopleModel } from "../../model/kg-people/kg-people.model";

@Injectable()
export class KgPeopleRouterDataService {
  instanceId = Math.random();
  data: KgPeopleModel;
  clear() {
    this.data = null;
  }
}
