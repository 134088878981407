import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { MDCLinearProgress } from "@material/linear-progress";

@Component({
  selector: "kg-component-materials-linear-progress",
  templateUrl: "./kg-component-materials-linear-progress.component.html",
  styleUrls: ["./kg-component-materials-linear-progress.component.scss"]
})
export class KgComponentMaterialsLinearProgressComponent
  implements OnInit, AfterViewInit {
  mdcProgress: MDCLinearProgress;
  @Input() kgid: string;
  @Input() kgprogresspercent = 0;
  @Input() kgdeterminate = false;

  progressValue = 0;

  // visible = true;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (!this.kgid) {
      console.log("error happened");
      console.error("kgid is undefined");
    }
    this.getMdcProgressInstence();
    // this.visible = false;
    this.mdcProgress.close();
  }

  start() {
    // this.visible = true;
    this.getMdcProgressInstence();
    this.mdcProgress.open();

    // this.mdcProgress.progress = 0.5;
  }

  stop() {
    // this.visible = false;
    this.getMdcProgressInstence();
    this.mdcProgress.close();
    // this.mdcProgress.progress = 0;
  }

  getMdcProgressInstence() {
    if (!this.mdcProgress) {
      // console.log(`#linearProgress-${this.kgid}`);
      let el = document.querySelector(`#linearProgress-${this.kgid}`);
      this.mdcProgress = new MDCLinearProgress(el);
      this.mdcProgress.determinate = this.kgdeterminate;
      this.mdcProgress.progress = this.kgprogresspercent;
      // this.mdcProgress.destroy();
    } else {
      this.mdcProgress.determinate = this.kgdeterminate;
    }
  }

  set progress(valuePercentage: number) {
    // this.visible = true;
    this.getMdcProgressInstence();

    this.progressValue = valuePercentage;
    this.mdcProgress.progress = valuePercentage;
    if (valuePercentage == 1) {
      setTimeout(x => {
        // this.visible = false;
        this.mdcProgress.close();
      }, 2000);
    }
  }

  get progress() {
    return this.progressValue;
  }
}
