import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  KgTabType,
  KgComponentMaterialsTabItemModel
} from "./kg-component-materials-tab.model";
import { MDCTabBar } from "@material/tab-bar";

@Component({
  selector: "kg-component-materials-tab",
  templateUrl: "./kg-component-materials-tab.component.html",
  styleUrls: ["./kg-component-materials-tab.component.scss"]
})
export class KgComponentMaterialsTabComponent implements OnInit, AfterViewInit {
  // @Input() data: KgComponentMaterialsTabModel;
  @Input() tabType: KgTabType;
  @Input() kginputdata: KgComponentMaterialsTabItemModel[];
  // @ViewChild('sb') sbel:ElementRef
  // @ViewChild('backwardScroll') backwardScroll:ElementRef
  // @ViewChild('forwardScroll') forwardScroll:ElementRef
  type: any = KgTabType;

  // mdcTabBarScroller: MDCTabBar;
  mdcTabBar: MDCTabBar;
  @ViewChild("tabbar") tabbar: ElementRef;
  selectedTabIndex: number = 0;
  constructor() {}

  ngOnInit() {
    console.log(this.kginputdata);
  }

  ngAfterViewInit(): void {
    this.mdcTabBar = new MDCTabBar(this.tabbar.nativeElement);
    // this.mdcTabBar.scrollIntoView(0);
    // this.mdcTabBar.activateTab(0);
    // this.kginputdata.forEach((x, i) => {
    //   i > 0 ? this.mdcTabBar.adapter deactivateTabAtIndex(i) : null;
    // })
    // this.mdcTabBar.useAutomaticActivation = true;;
    // let notifyChange: any
    // this.mdcTabBar.setActiveTabIndex_(0, notifyChange)

    // // const tabBarEl = document.querySelector('#my-scrollable-tab-bar');
    // // const scrollerEl = document.querySelector('#my-tab-bar-scroller');

    // // this.mdcTabBarScroller = new MDCTabBarScroller(scrollerEl, undefined, tabBarEl);
  }

  onTabClicked(
    e: MouseEvent,
    item: KgComponentMaterialsTabItemModel,
    index: number
  ) {
    // this.mdcTabBar.activateTab(index);
    this.selectedTabIndex = index;
    item.onTabItemClick.emit(item);
  }
}
