import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Provider,
  forwardRef,
  Output,
  EventEmitter
} from "@angular/core";
import {
  KgComponentMaterialsDatepickerDialogComponent,
  DatePickerConfig,
  DateTimePickerModes,
  DEFAULT_DATEPICKER_CONFIG
} from "../../../../modules/libs/kg-component-materials/kg-component-materials-datepicker/kg-component-materials-datepicker-dialog/kg-component-materials-datepicker-dialog.component";
import {
  FormGroup,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator
} from "@angular/forms";
import { isValid, format } from "date-fns";
const noop = () => {};

export const TEXT_DATE_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KgComponentMaterialsDatepickerComponent),
  multi: true
};

export const MY_VALIDATOR_DATEPICKER: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => KgComponentMaterialsDatepickerComponent),
  multi: true
};

@Component({
  selector: "kg-component-materials-datepicker",
  templateUrl: "./kg-component-materials-datepicker.component.html",
  styleUrls: ["./kg-component-materials-datepicker.component.scss"],
  providers: [TEXT_DATE_INPUT_VALUE_ACCESSOR, MY_VALIDATOR_DATEPICKER]
})
export class KgComponentMaterialsDatepickerComponent
  implements OnInit, ControlValueAccessor, Validator {
  control: AbstractControl;

  private thisControlValue: Date;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private onValidatorChange: () => void = noop;
  private _onChange: () => void;
  prefilled = false;
  kgreadonly = false;
  dateFormatted: string;
  @Input() kgid: string;
  @Input() kglabel: string;
  @ViewChild("pickerdialog")
  dateTimePickerDialog: KgComponentMaterialsDatepickerDialogComponent;
  datepickergroup: FormGroup;
  @Input() config: DatePickerConfig;
  @Input() kgalertmessage;
  @Input() kgrequired;
  private selectedDate: Date[] = [];
  @Output() onSelectedDateRange: EventEmitter<Date[]> = new EventEmitter<
    Date[]
  >();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.config = Object.assign(DEFAULT_DATEPICKER_CONFIG, this.config);
    this.datepickergroup = this.fb.group({
      date: ["", [this.isValidDateTimeString(this.config.mode)]]
    });

    this.datepickergroup.controls["date"].valueChanges.subscribe(x => {
      let d = this.datepickergroup.controls["date"].valid
        ? new Date(x)
        : this.thisControlValue;
      this.value = d;
    });

    if (this.config.selectDateRange) {
      this.kgreadonly = true;
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    //Keep a reference copy of this AbstractControl for status change detection
    if (!this.control) {
      this.control = c;
      this.config = Object.assign(DEFAULT_DATEPICKER_CONFIG, this.config);
      this.subscribeToControlStatusChange();
    }

    return null;
  }

  subscribeToControlStatusChange() {
    if (this.control != null) {
      this.control.statusChanges.subscribe(x => {
        if (this.control.errors) {
          this.datepickergroup.get("date").markAsDirty();
          this.datepickergroup.get("date").markAsTouched();
          this.datepickergroup.get("date").setErrors(this.control.errors);
        } else {
          this.datepickergroup.get("date").setErrors(null);
        }
      });
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  isValidDateTimeString(mode: DateTimePickerModes): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      //if is select date range the no need validation because user cannot type in the date
      if (this.config.selectDateRange) {
        return null;
      } else {
        //THIS LINE MUST EXIT IN YOUR CUSTOMIZED VALIDATOR TO CHECK EMAIL EXIST THROUGH WEB API
        if (control.value == null || control.value.length === 0) {
          return null; // don't validate empty values to allow optional controls
        }

        let v = control.value as string;
        let tempValue =
          mode == DateTimePickerModes.Time ? `1st Jan 1970 ${v}` : v;
        let validDateString = isValid(new Date(tempValue));
        let valid = validDateString ? null : { invalid: true };
        return valid;
      }
    };
  }

  get value(): Date {
    return this.thisControlValue;
  }

  set value(v: Date) {
    if (v !== this.thisControlValue) {
      this.thisControlValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.thisControlValue) {
      this.thisControlValue = value;

      if (this.config.selectDateRange == true) {
        if (value != null && value.length == 2) {
          this.datepickergroup.controls["date"].setValue(
            format(value[0], "DD/MM/YY") + " - " + format(value[1], "DD/MM/YY")
          );
        }
      } else {
        let testDate = new Date(value);
        if (testDate.getUTCDate() != new Date(0).getUTCDate()) {
          this.datepickergroup.controls["date"].setValue(
            format(value, this.getFormatByMode(this.config.mode))
          );
        } else {
          // console.log(testDate);
        }
      }
      if (value != null && value != new Date(0)) {
        this.prefilled = true;
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  onShowDatePicker(forceShow: boolean) {
    if (!this.value || this.value == new Date(0) || forceShow) {
      this.dateTimePickerDialog.show();

      if (this.config.selectDateRange) {
        this.datepickergroup.controls["date"].setValue("");
      }
    }
  }

  onFocusOut(event: Event) {
    console.log("lost focus");
    let control = this.datepickergroup.controls["date"];
    if (control.valid && control.value != "") {
      let parseDateString = Date.parse(control.value);
      if (isNaN(parseDateString)) {
        console.error(
          "User keyed in date string format cannot be parsed",
          control.value,
          parseDateString
        );
      } else {
        control.setValue(
          format(parseDateString, this.getFormatByMode(this.config.mode))
        );
      }
    }
  }

  doSubmit(selected: Date[]) {
    this.selectedDate = selected;

    this.onSelectedDateRange.emit(this.selectedDate);

    if (this.config.selectDateRange) {
      this.datepickergroup.controls["date"].setValue(
        format(selected[0], "DD/MM/YY") +
          " - " +
          format(selected[1], "DD/MM/YY")
      );
    } else {
      this.datepickergroup.controls["date"].setValue(
        format(selected[0], this.getFormatByMode(this.config.mode))
      );
    }
    this.selectedDate.length = 0;
  }

  getFormatByMode(mode: DateTimePickerModes) {
    let format = this.config.format[DateTimePickerModes[this.config.mode]];
    return format;
  }

  doCancel(isCancel: boolean) {}

  getMdcIconByMod() {
    return this.config.mode == DateTimePickerModes.Time
      ? "access_time"
      : "event";
  }
}
