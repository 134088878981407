import {
  Directive,
  ViewContainerRef,
  OnInit,
  Input,
  ElementRef,
  SimpleChanges,
  Renderer2
} from "@angular/core";
import { Colors } from "kg-lib-models";
import { OnChanges } from "@angular/core";

@Directive({
  selector: "[datalist-color]"
})
export class KgComponentMaterialsDataListColorDirective
  implements OnInit, OnChanges {
  @Input() color: string | Colors;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setColor(this.color);
  }

  setColor(color: string | Colors) {
    if (color) {
      this.renderer.setStyle(
        this.el.nativeElement,
        "color",
        typeof this.color == "string"
          ? this.color
          : Colors[this.color as Colors]
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop == "color") {
        this.setColor(changes[prop].currentValue);
      }
    }
  }
}
