import { Component, OnInit } from "@angular/core";
import { KgLoggingService } from "src/app/framework/logging/kg-logging.service";
import { CategoryTypes, IKgLog } from "src/app/framework/model/kg-log.model";
import { Observable } from "rxjs/Observable";
import { KGUaService } from "../../../framework/services/ua.service";
import {
  TokenPayloadModel,
  KgAuthenticationService
} from "../../../services/kg-authentication/kg-authentication.service";
import { format, parse } from "date-fns";

@Component({
  moduleId: module.id,
  selector: "kg-debugmode-component",
  templateUrl: "debugmode.component.html",
  styleUrls: ["debugmode.component.scss"]
})
export class KgDebugModeComponent implements OnInit {
  allLogs$: Observable<IKgLog[]>;
  page = 1;
  pageSize = 10;
  CategoryTypes = CategoryTypes;
  uaData: string;
  dateParse = parse;
  primaryToken: string;
  primaryTokenPayload: TokenPayloadModel;
  accessToken: string;
  accessTokenPayload: TokenPayloadModel;

  constructor(
    private loggingService: KgLoggingService,
    private ua: KGUaService,
    private authenticationService: KgAuthenticationService
  ) {
    this.allLogs$ = this.loggingService.getLogByPage(this.pageSize, this.page);

    this.getUaData();
  }
  ngOnInit(): void {
    this.primaryToken = `Bearer ${this.authenticationService.primaryToken}`;
    this.primaryTokenPayload = this.authenticationService.primaryTokenPayload;
    this.accessToken = `Bearer ${this.authenticationService.accessToken}`;
    this.accessTokenPayload = this.authenticationService.accessTokenPayload;
  }

  nextPage() {
    this.page++;
    this.allLogs$ = this.loggingService.getLogByPage(this.pageSize, this.page);
  }

  previousPage() {
    this.page--;
    this.allLogs$ = this.loggingService.getLogByPage(this.pageSize, this.page);
  }

  getUaData() {
    this.uaData = JSON.stringify(this.ua.UA, null, 2);
  }
}
