import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgTitleComponent } from "./kg-title/kg-title.component";
import { KgComponentMaterialsModule } from "../kg-component-materials";

@NgModule({
  imports: [CommonModule, KgComponentMaterialsModule],
  declarations: [KgTitleComponent],
  exports: [KgTitleComponent]
})
export class KgTitleModule {}
