import { Component, OnInit, Input } from "@angular/core";
import { KgPeopleModel } from "src/app/model/kg-people";
import { KgComponentMaterialsDataListSettingModel } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { PeopleRoles } from "src/app/model/kg-core";

@Component({
  selector: "app-kg-people-categories-dashboard",
  templateUrl: "./kg-people-categories-dashboard.component.html",
  styleUrls: ["./kg-people-categories-dashboard.component.scss"]
})
export class KgPeopleCategoriesDashboardComponent implements OnInit {
  @Input() kgcategories: string;
  @Input() kgpeopleList: KgPeopleModel[];
  @Input() kgpeopleSetting: KgComponentMaterialsDataListSettingModel<
    KgPeopleModel
  >;

  errorText: string;
  Title: string;

  constructor() {
    if (this.kgpeopleList == null) {
      this.errorText = "List is empty";
    }
  }

  DisplayPeopleFullName(item: KgPeopleModel) {
    return item.FirstName + " " + item.LastName;
  }

  getRoles(item: KgPeopleModel) {
    let currentRoles: string = "";
    item.Roles.forEach(element => {
      currentRoles += PeopleRoles[element] + ", ";
    });
    currentRoles = currentRoles.slice(0, -2);
    return currentRoles;
  }

  ngOnInit() {
    this.Title = this.kgcategories;
  }
}
