import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { KgArray } from "src/app/framework/utilities/kgarray";

export class KgEnumKeyPair {
  key: any;
  item: any;
}
@Injectable()
export class KgEnumHandler {
  getEnumKeyItemPairs(enumObject: any, sort = true): KgEnumKeyPair[] {
    let keys = Object.keys(enumObject);
    keys = keys.slice(0, keys.length / 2);

    return sort
      ? keys
          .map(x => {
            return { key: x, item: enumObject[x] };
          })
          .sort((a, b) => {
            return KgArray.SortAsc(a.item, b.item, true);
          })
      : keys.map(x => {
          return { key: x, item: enumObject[x] };
        });
  }

  getObjectKeyItemParis(obj: any, sort = true): KgEnumKeyPair[] {
    let keys = Object.keys(obj);
    return sort
      ? keys
          .map(x => {
            return { key: x, item: obj[x] };
          })
          .sort((a, b) => {
            return KgArray.SortAsc(a.item, b.item, true);
          })
      : keys.map(x => {
          return { key: x, item: obj[x] };
        });
  }

  getSelectableRolesByRoleType(enumObject: any, role: string, sort = true) {
    let selectableRoles = environment.ResourceTyped.SelectableRolesByRoleType[
      role
    ] as Array<number>;

    return sort
      ? selectableRoles
          .map(x => {
            return { key: x, item: enumObject[x] };
          })
          .sort((a, b) => {
            return KgArray.SortAsc(a.item, b.item);
          })
      : selectableRoles.map(x => {
          return { key: x, item: enumObject[x] };
        });
  }
}
