import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { KgComponentMaterialsIconToggleItemModel } from "./kg-component-materials-icontoggle.model";

@Component({
  selector: "kg-component-materials-icontoggle",
  templateUrl: "./kg-component-materials-icontoggle.component.html",
  styleUrls: ["./kg-component-materials-icontoggle.component.scss"]
})
export class KgComponentMaterialsIconToggleComponent
  implements OnInit, OnChanges {
  @Input() kgid: string;
  @Input() kglabel: string;
  @Output() kgclick = new EventEmitter<MouseEvent>();
  @Input() kgdisabled = false;
  @Input() toggleOn: KgComponentMaterialsIconToggleItemModel;
  @Input() toggleOff: KgComponentMaterialsIconToggleItemModel;

  constructor(private elem: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit() {}

  clicked(event: MouseEvent) {
    console.log("kgclicked");
    console.log(this.toggleOn.content.toString());
    this.kgclick.emit(event);
  }
}
