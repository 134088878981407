import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { environment } from "../environments/environment";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import {
  MDCSnackbar,
  MDCSnackbarFoundation,
  MDCSnackbarCloseEvent
} from "@material/snackbar";
import {
  KgSnackbarService,
  MDCSnackbarData
} from "src/app/services/kg-snackbar/kg-snackbar.service";
import { KgServiceWorkerService } from "./services/kg-service-worker/kg-service-worker.service";
// import {  } from "@material/snackbar";

@Component({
  moduleId: module.id,
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  showDrawer = false;
  @ViewChild("snackbar") snackbarElement: ElementRef;
  mdcSnackbar: MDCSnackbar;
  latestSnackData: MDCSnackbarData;

  onSnackbarClosed = reason => {
    if (this.latestSnackData.onClosed) {
      this.latestSnackData.onClosed(reason);
    } else {
      console.log("this.latestSnackData.onClosed is null, does nothing");
    }
  };

  onSnackbarClosing = reason => {
    if (this.latestSnackData.onClosing) {
      this.latestSnackData.onClosing(reason);
    } else {
      console.log("this.latestSnackData.onClosing is null, does nothing");
    }
  };

  onSnackbarOpened = reason => {
    if (this.latestSnackData.onOpened) {
      this.latestSnackData.onOpened();
    } else {
      console.log("this.latestSnackData.onOpened is null, does nothing");
    }
  };

  onSnackbarOpening = reason => {
    if (this.latestSnackData.onOpening) {
      this.latestSnackData.onOpening();
    } else {
      console.log("this.latestSnackData.onOpening is null, does nothing");
    }
  };

  constructor(
    private kgSwService: KgServiceWorkerService,
    private authenticationService: KgAuthenticationService,
    private snackbarService: KgSnackbarService
  ) {
    console.log("CurrentBuildEnvironment:" + environment.environmentType);
    console.log("CurrentBuildEnvironmentApiHost:" + environment.ApiHost);
  }

  ngOnInit() {
    this.initSnackbar();

    if (this.authenticationService.hasSession) {
      this.showDrawer = true;
      this.authenticationService.announceUserAuthenticated(true);
      console.log("Have valid session, announce user authenticated");
    }

    this.listenSnackbarService();

    //Initialize service worker
    environment.production
      ? this.initServiceWorker(true).then(() =>
          console.log("completed init sw promise")
        )
      : console.log("skip sw init because is debug environment");
  }

  ngOnDestroy() {
    this.mdcSnackbar.unlisten<MDCSnackbarCloseEvent>(
      MDCSnackbarFoundation.strings.CLOSED_EVENT,
      this.onSnackbarClosed
    );

    this.mdcSnackbar.unlisten<MDCSnackbarCloseEvent>(
      MDCSnackbarFoundation.strings.CLOSING_EVENT,
      this.onSnackbarClosing
    );

    this.mdcSnackbar.unlisten(
      MDCSnackbarFoundation.strings.OPENED_EVENT,
      this.onSnackbarOpened
    );

    this.mdcSnackbar.unlisten(
      MDCSnackbarFoundation.strings.OPENING_EVENT,
      this.onSnackbarOpening
    );
  }
  listenSnackbarService() {
    this.authenticationService.authentication$.subscribe(x => {
      this.showDrawer = x ? true : false;
    });

    this.snackbarService.snackbar$.subscribe(snackData => {
      this.latestSnackData = snackData;
      snackData.timeoutMs
        ? (this.mdcSnackbar.timeoutMs = snackData.timeoutMs)
        : null;
      snackData.actionButtonText
        ? (this.mdcSnackbar.actionButtonText = snackData.actionButtonText)
        : null;
      snackData.labelText
        ? (this.mdcSnackbar.labelText = snackData.labelText)
        : null;
      snackData.closeOnEscape
        ? (this.mdcSnackbar.closeOnEscape = snackData.closeOnEscape)
        : null;

      this.mdcSnackbar.listen<MDCSnackbarCloseEvent>(
        MDCSnackbarFoundation.strings.CLOSED_EVENT,
        this.onSnackbarClosed
      );

      this.mdcSnackbar.listen<MDCSnackbarCloseEvent>(
        MDCSnackbarFoundation.strings.CLOSING_EVENT,
        this.onSnackbarClosing
      );

      this.mdcSnackbar.listen(
        MDCSnackbarFoundation.strings.OPENED_EVENT,
        this.onSnackbarOpened
      );

      this.mdcSnackbar.listen(
        MDCSnackbarFoundation.strings.OPENING_EVENT,
        this.onSnackbarOpening
      );

      this.mdcSnackbar.open();
    });
  }

  async initServiceWorker(forceRegisterSw: boolean) {
    this.kgSwService.startMonitorServiceWorkerUpdate();

    this.kgSwService.onAvailable.subscribe(x => {
      this.kgSwService.activateNewVersion();
    });

    this.kgSwService.onActivated.subscribe(x => {
      console.log("New Version Activated Require RELOAD");
      this.showNewUpdateFound();
    });

    if (forceRegisterSw) {
      if ("serviceWorker" in navigator) {
        try {
          let registration = await navigator.serviceWorker.getRegistration();
          if (!registration) {
            registration = await navigator.serviceWorker.register(
              "/ngsw-worker.js"
            );
          } else {
            console.log("sw registration exist skip register()", registration);
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      console.log("SKIP FORCE SW REGISTRATION");
    }
  }

  initSnackbar() {
    this.mdcSnackbar = new MDCSnackbar(this.snackbarElement.nativeElement);
    // this.mdcSnackbar.dismissesOnAction = true;
  }

  showNewUpdateFound() {
    let data: MDCSnackbarData = {
      labelText: "New update found",
      actionButtonText: "Update Now",
      timeoutMs: 10000,
      onClosed: () => {
        document.location.reload();
      }
    };

    this.snackbarService.show(data);
  }
}
