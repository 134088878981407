import { Injectable } from "@angular/core";
import { HttpRequest, HttpClient } from "@angular/common/http";

import {
  KgAnnouncementModel,
  KgAnnouncementResponseModel
} from "../../model/kg-announcement/kg-announcement.model";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { KgFileItemInfoModel } from "../../model/kg-filemanager/kg-filemanager.model";

import { environment } from "../../../environments/environment";
import { Observable, throwError as _throw } from "rxjs";
import { KgAttachmentModel } from "src/app/model/kg-attachment/kg-attachment";
import { catchError } from "rxjs/operators/catchError";
import { map } from "rxjs/operators/map";
import { KgReviver } from "../../framework/model/kg-reviver";

@Injectable()
export class KgAnnouncementService {
  constructor(
    private authenticationService: KgAuthenticationService,
    private httpClient: HttpClient
  ) {}

  getAnnouncementsTotalCount() {
    return this.httpClient
      .get<number>(environment.ApiHost + "api/announcements/totalcount", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAnnouncementsTagTotalCount(tagValue: string) {
    return this.httpClient.get<number>(
      environment.ApiHost + `api/announcements/tagtotalcount/${tagValue}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getAnnouncementsSearchStringTotalCount(
    searchString: string,
    searchCategory: string
  ) {
    return this.httpClient.get<number>(
      environment.ApiHost +
        `api/announcements/searchstringtotalcount/${searchCategory}/${searchString}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getAll(page: number) {
    const req = new HttpRequest(
      "GET",
      `${environment.ApiHost}/api/announcements/pages/${page}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
    return this.httpClient.request<KgAnnouncementModel[]>(req);
  }

  getAnnouncementsByPage(
    page: number
  ): Observable<KgAnnouncementResponseModel[]> {
    return this.httpClient
      .get<KgAnnouncementResponseModel[]>(
        `${environment.ApiHost}api/announcements/pages/10/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray(x, KgAnnouncementResponseModel)),
        catchError(error => {
          return _throw(error);
        })
      );

    // .catch(this.handleError);
  }

  getTagByPage(
    page: number,
    tagValue: string
  ): Observable<KgAnnouncementResponseModel[]> {
    return this.httpClient
      .get<KgAnnouncementResponseModel[]>(
        `${environment.ApiHost}api/announcements/tag/${tagValue}/10/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );

    // .catch(this.handleError);
  }

  getLimitedAnnouncements(
    maxNumber: number
  ): Observable<KgAnnouncementResponseModel[]> {
    return this.httpClient
      .get<KgAnnouncementResponseModel[]>(
        `${environment.ApiHost}api/announcements/pages/${maxNumber}/1`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getById(id: string) {
    return this.httpClient
      .get<KgAnnouncementResponseModel>(
        environment.ApiHost + "api/announcements/id/" + id,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getByTag(tagName: string) {
    return this.httpClient
      .get<KgAnnouncementResponseModel>(
        environment.ApiHost + "api/announcements/tag/" + tagName,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getByPeriod(startDate: Date, endDate: Date) {
    return this.httpClient
      .get<KgAnnouncementResponseModel>(
        environment.ApiHost +
          "api/announcements/month/" +
          startDate +
          "/" +
          endDate,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addNew(announcement: KgAnnouncementModel) {
    return this.httpClient
      .post<string>(
        environment.ApiHost + "api/announcements/addnew",
        announcement,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  edit(announcement: KgAnnouncementModel) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/announcements/editannouncement",
        announcement,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  delete(announcement: KgAnnouncementModel) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/announcements/deleteannouncement",
        announcement,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAllTag() {
    return this.httpClient
      .get<string[]>(environment.ApiHost + "api/announcements/alltag", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  uploadAnnouncement(fileInfo: File) {
    let formData = new FormData();
    formData.append("file", fileInfo);
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/announcements/uploadannouncement`,
      formData,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<KgFileItemInfoModel>(req).pipe(
      catchError(error => {
        return _throw(error);
      })
    );
  }

  addNewAttachment(announcementId: string, attachment: KgAttachmentModel) {
    let formData = new FormData();
    formData.append("file", attachment.fileBlob);
    const req = new HttpRequest(
      "POST",
      `${
        environment.ApiHost
      }api/announcements/${announcementId}/addattachment/${
        attachment.fileName
      }`,
      formData,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<any>(req).pipe(
      catchError(error => {
        return _throw(error);
      })
    );
  }

  deleteAttachment(announcementId: string, attachment: KgAttachmentModel) {
    let formData = new FormData();
    formData.append("file", attachment.fileBlob);
    const req = new HttpRequest(
      "POST",
      `${
        environment.ApiHost
      }api/announcements/${announcementId}/deleteattachment/${
        attachment.fileName
      }`,
      formData,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<any>(req).pipe(
      catchError(error => {
        return _throw(error);
      })
    );
  }

  getSearchStringByPage(
    page: number,
    searchCategory: string,
    searchString: string
  ): Observable<KgAnnouncementResponseModel[]> {
    return this.httpClient
      .get<KgAnnouncementResponseModel[]>(
        `${
          environment.ApiHost
        }api/announcements/search/${searchCategory}/${searchString}/10/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getSasToken(tokenType: number) {
    return this.httpClient.get<string>(
      `${environment.ApiHost}api/announcements/token/${tokenType}`,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
  }

  // OnSetAnnouncementContent(source) {
  //   let myHeaders = new Headers();
  //   let content: string = "";
  //   myHeaders.append('Content-Type', 'txt');
  //   this.http.get(
  //     source, {
  //       headers: myHeaders,
  //       responseType: ResponseContentType.Text
  //     })
  //     .subscribe(
  //     (response) => {
  //       var headers = response.headers;
  //       var filename = response.url.split('/').pop().split('#')[0].split('?')[0].split('.txt')[0];
  //       this.announcementContent = response['_body'];
  //     }
  //     )
  // }

  private extractData2(
    announcements: KgAnnouncementModel[]
  ): KgAnnouncementModel[] {
    if (announcements != null) {
      return announcements.map(x => {
        return new KgAnnouncementModel(x);
      });
    }
  }
}
