import { KgPeopleModel } from "../../model/kg-people";
import { Validators } from "@angular/forms";
import { KgFormGroupBase } from "../../model/kg-core/kg-formgroupbase";
import { KgPeopleService } from "../../services/kg-people";
import { KgValidators } from "../../modules/core/kg-validators";

export class KgPeopleFormGroup extends KgFormGroupBase<KgPeopleModel> {
  constructor(
    people: KgPeopleModel,
    peopleService: KgPeopleService,
    isEdit = false
  ) {
    let asyncCustomValidators = !isEdit
      ? {
          Email: [KgValidators.asyncEmailExist(peopleService)],
          MobileNumber: [KgValidators.asyncMobileNumberExist(peopleService)]
        }
      : null;

    super(
      people,
      {
        FirstName: [Validators.required, Validators.max(100)],
        LastName: [Validators.required],
        Email: [Validators.required, Validators.email],
        MobileNumber: [Validators.required],
        Nationality: [Validators.required],
        Password: [Validators.required, Validators.minLength(8)]
        // DateOfBirth: [KgPeopleFromGroupValidators.IsInPast()]
      },
      asyncCustomValidators
    );
  }
}
