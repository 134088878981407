import { Component, OnInit, Input } from "@angular/core";
import { IKgMaterialIcons } from "../../kg-component-materials/kg-component-materials-icons/kg-component-materials-icons.component";
export enum KgTitleModes {
  ModuleHeader = 0,
  CardHeader = 1,
  DataListHeader = 2
}

@Component({
  selector: "kg-title",
  templateUrl: "./kg-title.component.html",
  styleUrls: ["./kg-title.component.scss"]
})
export class KgTitleComponent implements OnInit {
  @Input() kglabel: string;
  @Input() kgsublabel: string;
  @Input() kgicon: IKgMaterialIcons;
  @Input() kgtitlemode: KgTitleModes;

  constructor() {}

  ngOnInit() {}
}
