export enum CommunitiesTypes {
  Condo = 0,
  Appartment = 1,
  Commercial = 2,
  Gated = 3
}

export enum CommunitiesStatuses {
  Active = 0,
  Deactivated = 1,
  Banned = 2,
  Removed = 3
}

export enum ActionType {
  Add = 0,
  Update = 1,
  Delete = 2
}

export enum ManagementsType {
  Individual = 0,
  SdnBhd = 1,
  Bhd = 2
}

export enum ManagementsStatus {
  Active = 0,
  Deactivated = 1,
  Banned = 2,
  Removed = 3
}

export enum PeopleRoles {
  Administrator = 0,
  Chairperson = 1,
  Committee = 2,
  Manager = 3,
  Resident = 4,
  Tenant = 5,
  Cleaner = 6,
  SecurityGuard = 7,
  Maintainer = 8,
  Community = 9,
  Clerk = 10,
  Owner = 11,
  MasterTenant = 12
}

export enum Salutation {
  Mr = 0,
  Mrs = 1,
  Ms = 2,
  Dr = 3,
  Dato = 4
}

export enum Gender {
  Male = 0,
  Female = 1
}

export enum IdentificationTypes {
  MyKad = 0,
  Passport = 1,
  DrivingLicence = 2,
  Others
}

export enum PeopleStatuses {
  New = 0,
  Submitted = 1,
  Approved = 2,
  Activated = 3,
  Barred = 4,
  Removed = 5
}

export enum AccessCardStatuses {
  Activated = 0,
  Deactivated = 1
}

export enum ModelStates {
  Pristine = 0,
  New = 1,
  Edited = 2,
  Deleted = 3
}
