import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import {
  startOfMonth,
  getMonth,
  getDay,
  parse,
  addDays,
  format,
  addMonths,
  setYear,
  startOfDay,
  isAfter,
  endOfDay,
  isBefore,
  setMonth,
  setDate,
  getDate,
  setHours,
  setMinutes,
  addHours
} from "date-fns";

export enum PickerStates {
  Summary = 0,
  // Seconds =0,
  Minutes = 1,
  Hours = 2,
  Dates = 3,
  Years = 4
}

export enum DateTimePickerModes {
  Time = 0,
  Dates = 1,
  Year = 2,
  DateTime = 3,
  YearDate = 4,
  Full = 5
}
export enum DatePickerSelectionMode {
  AllowPast = 0,
  AllowFuture = 1,
  AllowAll = 2
}
export class DatePickerConfig {
  mode?: DateTimePickerModes;
  selectDateRange: boolean;
  selectionMode: DatePickerSelectionMode;
  format?: {
    Full: string;
    Dates: string;
    Time: string;
    Year: string;
    DateTime: string;
    YearDate: string;
  };
}

export const DEFAULT_DATEPICKER_CONFIG = {
  mode: DateTimePickerModes.Full,
  selectDateRange: false,
  selectionMode: DatePickerSelectionMode.AllowAll,
  format: {
    Full: "D MMM YYYY hh:mm A",
    Dates: "YYYY-MM-DD",
    Time: "hh:mm A",
    Year: "YYYY",
    DateTime: "D MMM YYYY hh:mm A",
    YearDate: "D MMM YYYY"
  }
};

@Component({
  selector: "kg-component-materials-datepicker-dialog",
  templateUrl: "./kg-component-materials-datepicker-dialog.component.html",
  styleUrls: ["./kg-component-materials-datepicker-dialog.component.scss"]
})
export class KgComponentMaterialsDatepickerDialogComponent implements OnInit {
  @Input() config: DatePickerConfig = DEFAULT_DATEPICKER_CONFIG;
  // @Input() activateDateRangePicker = false;

  // dateFormatted = "";
  DAYNAMES = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  monthCalendar: Date[] = [];
  yearSelectionList: number[];
  monthCursor: Date = startOfMonth(new Date());
  pickedDate: Date = new Date();
  pickerState = PickerStates.Years;
  pickerStartState = this.pickerState;
  isAm = false;
  selectedDate: Date[] = [];
  startDate: string = "Start date -";
  endDate: string = "- End date";

  showDatePicker = false;
  @Output() onSubmit = new EventEmitter<Date[]>();
  @Output() onCancel = new EventEmitter<boolean>();

  constructor() {
    this.generateMonthCalendar(this.monthCursor);
    let now = new Date().getFullYear();
    this.generateYearList(now - (now % 10));
  }

  generateYearList(startYear: number) {
    this.yearSelectionList = [];
    for (let y = startYear; y < startYear + 10; y++) {
      this.yearSelectionList.push(y);
    }
  }

  generateMonthCalendar(monthCursor: Date) {
    this.monthCalendar = [];
    let startOfMonthCursor = startOfMonth(monthCursor);
    let endOfMonth = startOfMonth(monthCursor);
    let calMonth = getMonth(startOfMonthCursor);

    var offsetBegin = getDay(startOfMonthCursor);
    for (var x = 0; x < 42; x++) {
      if (
        x < getDay(startOfMonthCursor) ||
        calMonth != getMonth(startOfMonthCursor)
      ) {
        this.monthCalendar[x] = null;
      } else {
        this.monthCalendar[x] = parse(startOfMonthCursor);
        startOfMonthCursor = addDays(startOfMonthCursor, 1);
      }
    }
  }

  ngOnInit() {
    this.setPickerStateByMode(this.config.mode);
    this.resetSelectedDate();
  }

  resetSelectedDate() {
    this.selectedDate.length = 0;
    if (
      this.config.selectionMode == DatePickerSelectionMode.AllowAll &&
      !this.config.selectDateRange
    ) {
      this.selectedDate.push(new Date());
    }
  }

  setPickerStateByMode(mode: DateTimePickerModes) {
    switch (mode) {
      case DateTimePickerModes.Full:
      case DateTimePickerModes.Year:
      case DateTimePickerModes.YearDate:
        this.pickerState = PickerStates.Years;
        break;
      case DateTimePickerModes.Dates:
      case DateTimePickerModes.DateTime:
        this.pickerState = PickerStates.Dates;
        break;
      case DateTimePickerModes.Time:
        this.pickerState = PickerStates.Hours;
        break;
    }
    this.pickerStartState = this.pickerState;
  }

  getBoxDate2(x: number, y: number) {
    let index = x * 7 + y;
    let it = this.monthCalendar[index];
    return it ? format(it, "D") : "";
  }

  next10Years() {
    this.generateYearList(this.yearSelectionList[0] + 10);
  }

  previous10Years() {
    this.generateYearList(this.yearSelectionList[0] - 10);
  }

  nextMonth() {
    this.monthCursor = addMonths(this.monthCursor, 1);
    this.generateMonthCalendar(this.monthCursor);
  }

  previousMonth() {
    this.monthCursor = addMonths(this.monthCursor, -1);
    this.generateMonthCalendar(this.monthCursor);
  }

  onSelectedYear(year: number) {
    this.pickedDate = setYear(this.pickedDate, year);
    this.monthCursor = setYear(this.monthCursor, year);
    this.goToNextPicker();
  }

  onSelecteDate(row: number, col: number) {
    let x = this.monthCalendar[row * 7 + col];

    switch (this.config.selectionMode) {
      case DatePickerSelectionMode.AllowPast:
        // if (x.startOf('day').isAfter(moment().endOf("day")) || (this.config.selectDateRange && this.selectedDate[0] && x.endOf('day').isBefore(this.selectedDate[0]))) {
        if (
          isAfter(startOfDay(x), endOfDay(new Date())) ||
          (this.config.selectDateRange &&
            this.selectedDate[0] &&
            isBefore(endOfDay(x), this.selectedDate[0]))
        ) {
          return;
        }

        break;
      case DatePickerSelectionMode.AllowFuture:
        // if (x.endOf('day').isBefore(moment()) || (this.config.selectDateRange && this.selectedDate[0] && x.endOf('day').isBefore(this.selectedDate[0]))) {
        if (
          isBefore(endOfDay(x), new Date()) ||
          (this.config.selectDateRange &&
            this.selectedDate[0] &&
            isBefore(endOfDay(x), this.selectedDate[0]))
        ) {
          return;
        }

        break;
      case DatePickerSelectionMode.AllowAll:
        // if ((this.config.selectDateRange && this.selectedDate[0] && x.endOf('day').isBefore(this.selectedDate[0]))) {
        if (
          this.config.selectDateRange &&
          this.selectedDate[0] &&
          isBefore(endOfDay(x), this.selectedDate[0])
        ) {
          return;
        }
        break;
    }

    // this.selectedMoment.year(x.year());
    //  this.selectedMoment.month(x.month());
    // this.selectedMoment.date(x.date());
    this.pickedDate = setMonth(this.pickedDate, getMonth(x));
    this.pickedDate = setDate(this.pickedDate, getDate(x));

    let clonedDate = parse(x);

    if (this.config.selectDateRange) {
      this.selectedDate.push(clonedDate);
      if (this.selectedDate.length == 1) {
        this.startDate = format(this.pickedDate, "Do MMM YYYY") + " - ";
      }

      if (this.selectedDate.length == 2) {
        this.endDate = "- " + format(this.pickedDate, "Do MMM YYYY");
      }
    }

    this.goToNextPicker();
  }

  onSelectHour(hour: number) {
    this.pickedDate = setHours(this.pickedDate, this.isAm ? hour : hour + 12);
    this.pickedDate = setMinutes(this.pickedDate, 0);
    this.goToNextPicker();
  }

  goToNextPicker() {
    console.log(
      "before " + this.pickerState + " " + PickerStates[this.pickerState]
    );
    switch (this.config.mode) {
      case DateTimePickerModes.Full:
        this.pickerState =
          this.pickerState != PickerStates.Minutes
            ? this.pickerState - 1
            : PickerStates.Summary;
        break;
      case DateTimePickerModes.YearDate:
      case DateTimePickerModes.Dates:
        if (!this.config.selectDateRange || this.selectedDate.length > 1) {
          this.pickerState =
            this.pickerState != PickerStates.Dates
              ? this.pickerState - 1
              : PickerStates.Summary;
        }
        break;
      case DateTimePickerModes.DateTime:
      case DateTimePickerModes.Time:
        this.pickerState =
          this.pickerState != PickerStates.Minutes
            ? this.pickerState - 1
            : PickerStates.Summary;
        break;
      case DateTimePickerModes.Year:
        this.pickerState =
          this.pickerState != PickerStates.Years
            ? this.pickerState - 1
            : PickerStates.Summary;
        break;
    }

    console.log(
      "after " + this.pickerState + " " + PickerStates[this.pickerState]
    );
    this.emitPickedDateTime();
  }

  emitPickedDateTime() {
    if (this.pickerState == PickerStates.Summary) {
      if (!this.config.selectDateRange) {
        this.selectedDate[0] = this.pickedDate;
      }

      this.onSubmit.emit(this.selectedDate);
      this.hide();
      this.resetSelectedDate();
    }
  }

  goToPreviousPicker() {
    console.log(
      "before " + this.pickerState + " " + PickerStates[this.pickerState]
    );
    switch (this.config.mode) {
      case DateTimePickerModes.Full:
        this.pickerState++; // = this.pickerState != PickerStates.Minutes ? this.pickerState +1 : PickerStates.Summary;
        break;
      case DateTimePickerModes.YearDate:
      case DateTimePickerModes.Dates:
        this.pickerState =
          this.pickerState == PickerStates.Summary
            ? PickerStates.Dates
            : this.pickerState + 1;
        break;
      case DateTimePickerModes.DateTime:
      case DateTimePickerModes.Time:
        this.pickerState =
          this.pickerState == PickerStates.Summary
            ? PickerStates.Minutes
            : this.pickerState + 1;
        break;
      case DateTimePickerModes.Year:
        this.pickerState =
          this.pickerState == PickerStates.Summary
            ? PickerStates.Years
            : this.pickerState + 1;
        break;
    }
    console.log(
      "after " + this.pickerState + " " + PickerStates[this.pickerState]
    );
  }

  onSelectMinute(minute: number) {
    // this.selectedMoment.set('minute', minute);
    this.pickedDate = setMinutes(this.pickedDate, minute);

    this.goToNextPicker();
  }

  toggleAmPm(e: MouseEvent) {
    if (this.isAm) {
      this.isAm = false;
      this.pickedDate = addHours(this.pickedDate, 12);
    } else {
      this.isAm = true;
      this.pickedDate = addHours(this.pickedDate, -12);
    }
  }

  show() {
    this.showDatePicker = true;
    this.startDate = "Start date -";
    this.endDate = "- End date";

    console.log(this.pickerState);
  }

  hide() {
    this.showDatePicker = false;
    this.setPickerStateByMode(this.config.mode);
    this.resetSelectedDate();
  }

  shouldShowBack() {
    return this.pickerState < this.pickerStartState ? true : false;
  }
}
