import {
  FormControl,
  AbstractControl,
  ValidatorFn,
  AsyncValidatorFn
} from "@angular/forms";

export class KgFormControlsHelper {
  public static GetFormControls(model: any): { [key: string]: any } {
    let controls = {};
    Object.keys(model).forEach(x => {
      controls[x] = [model[x]];
    });

    return controls;
  }

  public static GetFormControlsWithValidators(
    model: any,
    validators: { [key: string]: ValidatorFn[] },
    asyncValidators?: { [key: string]: AsyncValidatorFn[] }
  ): { [key: string]: AbstractControl } {
    // console.log("validators=====", validators);
    let controls: { [key: string]: AbstractControl } = {};
    Object.keys(model).forEach(fieldName => {
      controls[fieldName] = new FormControl(model[fieldName]);
      if (validators[fieldName]) {
        controls[fieldName].setValidators(validators[fieldName]);
      }

      if (asyncValidators && asyncValidators[fieldName]) {
        controls[fieldName].setAsyncValidators(asyncValidators[fieldName]);
      }
    });

    return controls;
  }
}
