import { Injectable, OnDestroy } from "@angular/core";
import { Subject, Observable, Subscription, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Injectable()
export class KgInfiniteScrollService implements OnDestroy {
  private infiniteScroll$ = new Subject<any>();
  private infiniteScrollObservable = this.infiniteScroll$.asObservable();
  // private scrollEventListener = (x) => this.detectAtBottom(null);
  private infiniteScrollSubscription: Subscription;
  private scrollSubscription: Subscription;
  private scrollObservable: Observable<Event>;
  private started = false;
  constructor() {
    console.log("ininit infinite");
  }

  ngOnDestroy(): void {
    console.log("infinitescorll destroyed");
    this.stop();
  }

  /**
   * this is Observable Subscription() function
   * @param next
   * @param error
   * @param complete
   */
  start(
    next?: (value: any) => void,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    if (!this.started) {
      this.started = true;
      this.infiniteScrollSubscription = this.infiniteScrollObservable.subscribe(
        next,
        error,
        complete
      );
      this.scrollObservable = fromEvent(document, "scroll");
      this.scrollSubscription = this.scrollObservable
        .pipe(
          //delay 500ms before emit value wihout another source emission.
          debounceTime(500)
        )
        .subscribe(x => {
          this.detectAtBottom(null);
        });
    }
  }

  stop() {
    this.scrollSubscription ? this.scrollSubscription.unsubscribe() : null;
    this.infiniteScrollSubscription
      ? this.infiniteScrollSubscription.unsubscribe()
      : null;
    this.scrollObservable = null;
    this.started = false;
  }

  private detectAtBottom(a: any): any {
    let scrollPosition =
      document.body.scrollHeight - window.pageYOffset - window.innerHeight;
    console.log(
      `${scrollPosition} = ${document.body.scrollHeight} - ${
        window.pageYOffset
      } - ${window.innerHeight}`
    );

    if (scrollPosition < 1 && document.body.scrollHeight > window.innerHeight) {
      this.infiniteScroll$.next(true);
    }
  }
}
