import { KgBaseModel } from "../../model/kg-core/kg-base.model";
import { KgFileItemInfoModel } from "src/app/model/kg-filemanager/kg-filemanager.model";
import { KgPeopleRespondentModel } from "src/app/model/kg-people/kg-people-respondent.model";

export enum ComplaintSearchCategory {
  // Header = 0,
  description = 0,
  creator = 1
}

export enum Priorities {
  Low = 0,
  Medium = 1,
  High = 2
}

export enum ComplaintStatuses {
  New = 0,
  InProgress = 1,
  Done = 2,
  Rejected = 3,
  Pending = 4
}

export enum SortBy {
  ActionDate = 0,
  CreateDate = 1,
  Priority = 2,
  ComplaintStatus = 3
}

export class KgComplaintModel extends KgBaseModel {
  ActionTypeTitle: string;
  Title: string;
  Descriptions: string;
  CreateByPeopleName: string;
  ComplaintResponses: ComplaintResponse[];
  ExpectedCompletionDateTime: Date;
  Priority: Priorities;
  PriorityTitle: string;
  ComplaintStatus: ComplaintStatuses;
  ComplaintStatusTitle: string;
  Sorting: SortBy;
  ComplaintTag: string[];
  ComplaintPhotos: KgFileItemInfoModel[];

  constructor(complaintModel?: KgComplaintModel) {
    super();
    if (complaintModel != null) {
      Object.assign(this, complaintModel, {
        CreateDate: new Date(complaintModel.CreateDate),
        ActionDate: new Date(complaintModel.ActionDate),
        ComplaintResponses: complaintModel.ComplaintResponses
          ? complaintModel.ComplaintResponses.map(x => {
              return new ComplaintResponse(x);
            })
          : [],
        ExpectedCompletionDateTime: new Date(
          complaintModel.ExpectedCompletionDateTime
        ),
        ComplaintTag: complaintModel.ComplaintTag
          ? complaintModel.ComplaintTag
          : [],
        ComplaintPhotos: complaintModel.ComplaintPhotos
          ? complaintModel.ComplaintPhotos
          : []
      });
    } else {
      Object.assign(this, {
        Title: "",
        Descriptions: "",
        ComplaintResponses: [],
        ExpectedCompletionDateTime: new Date(0),
        Priority: -1,
        ComplaintStatus: 0,
        Sorting: 0,
        ComplaintTag: [],
        ComplaintPhotos: []
      });
    }
  }
}

export class ComplaintResponse {
  ResponseDateTime: Date;
  ResponseByPeopleId: string;
  ResponseByPeopleName: string;
  Description: string;

  constructor(response?: ComplaintResponse) {
    if (response != null) {
      Object.assign(this, response, {
        ResponseDateTime: new Date(response.ResponseDateTime)
      });
    } else {
      Object.assign(this, {
        ResponseDateTime: new Date(),
        ResponseByPeopleId: "",
        ResponseByPeopleName: "",
        Description: ""
      });
    }
  }
}

export class PeopleComplaintRespondent extends ComplaintResponse {
  PeopleInfo: KgPeopleRespondentModel;
}
