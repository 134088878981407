import { Component, OnInit, Input } from "@angular/core";
import { KgComponentMaterialsRadioModel } from "./kg-component-materials-radio.model";
@Component({
  selector: "kg-component-materials-radio",
  templateUrl: "./kg-component-materials-radio.component.html",
  styleUrls: ["./kg-component-materials-radio.component.scss"]
})
export class KgComponentMaterialsRadioComponent implements OnInit {
  @Input() data: KgComponentMaterialsRadioModel;
  constructor() {}

  ngOnInit() {}
}
