import { Injectable } from "@angular/core";
import { KgPeopleModel } from "../../model/kg-people/kg-people.model";
import { environment } from "../../../environments/environment";
import { Observable, throwError as _throw } from "rxjs";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import {
  PushNotificationToken,
  KgUnitResponse,
  KgAccessCardModel
} from "src/app/model/kg-people";
import { KgPeopleCommunitiesModel } from "src/app/model/kg-joint_model";
import { PeopleStatuses } from "src/app/model/kg-core";
import { KgPeopleUnitsResponseModel } from "src/app/model/kg-units/kg-peopleunitsreponse.model";
import { KgReviver } from "src/app/framework/model/kg-reviver";
import { catchError } from "rxjs/operators/catchError";
import { map } from "rxjs/operators/map";
import { KgPeopleRespondentModel } from "../../model/kg-people/kg-people-respondent.model";

@Injectable()
export class KgPeopleService {
  random = Math.random();
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  getAll(): Observable<KgPeopleModel | KgPeopleModel[]> {
    return this.httpClient
      .get<KgPeopleModel[]>(`${environment.ApiHost}api/peoples/all`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(x => KgReviver.ReviveArray<KgPeopleModel>(x, KgPeopleModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getTotalCount() {
    return this.httpClient
      .get<number>(`${environment.ApiHost}api/peoples/totalcount`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
    // .map((response: Response) => response.json())
  }

  getPeopleByPage(page: number): Observable<KgPeopleModel[]> {
    return this.httpClient
      .get<KgPeopleModel[]>(
        `${environment.ApiHost}api/peoples/pages/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x =>
          KgReviver.ReviveArray<KgPeopleModel>(x, KgPeopleUnitsResponseModel)
        ),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  emailAddressExist(emailAddress: string): Observable<boolean> {
    return this.httpClient
      .get<boolean>(
        `${environment.ApiHost}api/peoples/emailexist/${emailAddress}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  mobileNumberExist(mobileNumber: string): Observable<boolean> {
    return this.httpClient
      .get<boolean>(
        `${environment.ApiHost}api/peoples/mobileexist/${mobileNumber}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getPeopleUnitsByPage(page: number): Observable<KgPeopleUnitsResponseModel[]> {
    return this.httpClient
      .get<KgPeopleUnitsResponseModel[]>(
        `${environment.ApiHost}api/peoples/peopleunits/pages/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x =>
          KgReviver.ReviveArray<KgPeopleUnitsResponseModel>(
            x,
            KgPeopleUnitsResponseModel
          )
        ),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getPeopleRoleTotalCount(selectedRole: string) {
    return this.httpClient
      .get<number>(
        `${
          environment.ApiHost
        }api/peoples/peopleroletotalcount/${selectedRole}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getPeopleRoleByPage(
    page: number,
    selectedRole: string
  ): Observable<KgPeopleUnitsResponseModel[]> {
    return this.httpClient
      .get<KgPeopleUnitsResponseModel[]>(
        `${environment.ApiHost}api/peoples/filter/${selectedRole}/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x =>
          KgReviver.ReviveArray<KgPeopleUnitsResponseModel>(
            x,
            KgPeopleUnitsResponseModel
          )
        ),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getPeopleStatusTotalCount(selectedStatus: string) {
    return this.httpClient
      .get<number>(
        `${
          environment.ApiHost
        }api/peoples/peoplestatustotalcount/${selectedStatus}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getPeopleStatusByPage(
    page: number,
    selectedStatus: string
  ): Observable<KgPeopleModel[]> {
    return this.httpClient
      .get<KgPeopleModel[]>(
        `${
          environment.ApiHost
        }api/peoples/filter/peoplestatus/${selectedStatus}/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray<KgPeopleModel>(x, KgPeopleModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getByMyUnits(): Observable<KgUnitResponse[]> {
    return this.httpClient
      .get<KgUnitResponse[]>(`${environment.ApiHost}api/peoples/bymyunits`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(x => KgReviver.ReviveArray<KgUnitResponse>(x, KgUnitResponse)),
        catchError((error: HttpErrorResponse) => {
          return _throw(error);
        })
      );
  }

  getCommunitiesByPeopleId(
    peopleid: string
  ): Observable<KgPeopleCommunitiesModel[]> {
    return this.httpClient
      .get<KgPeopleCommunitiesModel[]>(
        `${environment.ApiHost}api/peoples/communities/${peopleid}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x =>
          KgReviver.ReviveArray<KgPeopleCommunitiesModel>(
            x,
            KgPeopleCommunitiesModel
          )
        ),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getById(id: string): Observable<KgPeopleModel> {
    return this.httpClient
      .get<KgPeopleModel[]>(`${environment.ApiHost}api/peoples/${id}`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(x => KgReviver.ReviveArray<KgPeopleModel>(x, KgPeopleModel)[0]),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getMyProfile() {
    return this.httpClient
      .get<KgPeopleModel>(`${environment.ApiHost}api/peoples/myprofile`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(x => KgReviver.Revive<KgPeopleModel>(x, KgPeopleModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getByIds(ids: string[]) {
    return this.httpClient
      .post<KgPeopleRespondentModel[]>(
        `${environment.ApiHost}api/peoples/peopleids`,
        ids,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x =>
          KgReviver.ReviveArray<KgPeopleRespondentModel>(
            x,
            KgPeopleRespondentModel
          )
        ),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addNew(newPeople: KgPeopleModel): Observable<KgPeopleModel> {
    return this.httpClient
      .post<KgPeopleModel>(
        `${environment.ApiHost}api/peoples/addnew`,
        newPeople,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.Revive<KgPeopleModel>(x, KgPeopleModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getPeoplesSearchStringTotalCount(
    searchString: string,
    searchCategory: string
  ) {
    return this.httpClient
      .get<number>(
        environment.ApiHost +
          `api/peoples/searchstringtotalcount/${searchCategory}/${searchString}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getSearchStringByPage(
    page: number,
    searchCategory: string,
    searchString: string
  ): Observable<KgPeopleUnitsResponseModel[] | KgUnitResponse[]> {
    return this.httpClient
      .get<KgUnitResponse[] | KgPeopleUnitsResponseModel[]>(
        `${
          environment.ApiHost
        }api/peoples/search/${searchCategory}/${searchString}/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => {
          if (searchCategory != "unitno") {
            return KgReviver.ReviveArray<KgPeopleUnitsResponseModel>(
              x as KgPeopleUnitsResponseModel[],
              KgPeopleUnitsResponseModel
            );
          } else {
            return KgReviver.ReviveArray<KgUnitResponse>(
              x as KgUnitResponse[],
              KgUnitResponse
            );
          }
        }),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  editPeople(currentPeople: KgPeopleModel) {
    return this.httpClient
      .post(`${environment.ApiHost}api/peoples/editpeople`, currentPeople, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map((response: Response) => response),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAccessCardByPeopleId(peopleid: string): Observable<KgAccessCardModel[]> {
    return this.httpClient
      .get<KgAccessCardModel[]>(
        `${environment.ApiHost}api/peoples/accesscard/${peopleid}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x =>
          KgReviver.ReviveArray<KgAccessCardModel>(x, KgAccessCardModel)
        ),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  uploadProfilePhoto(
    peopleId: string,
    uuid: string,
    isprimary: boolean,
    profilePhotosCropped: FormData
  ) {
    const req = new HttpRequest(
      "POST",
      `${
        environment.ApiHost
      }api/peoples/uploadprofile/${peopleId}/${uuid}/${isprimary}`,
      profilePhotosCropped,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<any>(req).pipe(
      catchError(error => {
        return _throw(error);
      })
    );
  }

  deleteProfilePhoto(fileNames: string[], peopleId: string, photoUuid: string) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/peoples/deletephoto/${peopleId}/${photoUuid}`,
      fileNames,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<any>(req).pipe(
      catchError(error => {
        return _throw(error);
      })
    );
  }

  removePerson(peopleId: string) {
    return this.httpClient.post(
      `${environment.ApiHost}api/peoples/remove/${peopleId}`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
  }

  setPrimaryPhoto(peopleId: string, photoUuid: string) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/peoples/primaryphoto/${peopleId}/${photoUuid}`,
      null,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
    return this.httpClient.request<any>(req);
  }

  setPepleStatus(peopleId: string, status: PeopleStatuses) {
    return this.httpClient.post(
      `${environment.ApiHost}api/peoples/setstatus/${peopleId}/${status}`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.httpClient.post(
      `${environment.ApiHost}api/peoples/changepassword`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        params: {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      }
    );
  }

  saveNotificationToken(token: PushNotificationToken) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/peoples/notificationtoken/add`,
      token,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        responseType: "text"
      }
    );

    return this.httpClient.request<any>(req);
  }

  deleteNotificationToken(token: PushNotificationToken) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/peoples/notificationtoken/delete`,
      token,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<any>(req);
  }

  isPeopleDetailsReady(people: KgPeopleModel | KgPeopleUnitsResponseModel) {
    console.log(
      "isready",
      this.hasAssignedRoles(people),
      this.hasAssignedUnits(people)
    );
    return this.hasAssignedRoles(people) && this.hasAssignedUnits(people);
  }

  hasAssignedRoles(people: KgPeopleModel | KgPeopleUnitsResponseModel) {
    return people.Roles && people.Roles.length > 0;
  }

  hasAssignedUnits(people: KgPeopleModel | KgPeopleUnitsResponseModel) {
    if (people instanceof KgPeopleUnitsResponseModel) {
      return people.PeopleUnits && people.PeopleUnits.length > 0;
    }
    return false;
  }

  // private extractData(collection: KgPeopleModel[]): KgPeopleModel[] {
  //   if (collection != null) {
  //     return collection.map(x => {
  //       let newp = new KgPeopleModel(x);
  //       return newp;
  //     })
  //   }
  // }
}
