import { Validators } from "@angular/forms";
import { KgAccessCardModel } from "../../model/kg-people";
import { KgFormGroupBase } from "../kg-core/kg-formgroupbase";
export class KgAccessCardFormGroup extends KgFormGroupBase<KgAccessCardModel> {
  constructor(accessCard: KgAccessCardModel) {
    super(accessCard, {
      AccessCardId: [Validators.required],
      AccessCardSerialNumber: [Validators.required],
      AccessCardType: [Validators.required]
    });
  }
}
