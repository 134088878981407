import { KgBaseModel } from "../kg-core/kg-base.model";
import { KgUnitsConfig } from "./kg-unit-config";

export class KgUnitsConfigResponse extends KgBaseModel {
  UnitsConfig: KgUnitsConfig;

  constructor(unitConfigResponse?: KgUnitsConfigResponse) {
    super();
    if (unitConfigResponse != null) {
      Object.assign(this, unitConfigResponse);
    } else {
      Object.assign(this, {
        UnitsConfig: {
          Blocks: [],
          Floors: [],
          Units: [],
          AllUnitList: []
        }
      });
    }
  }
}
