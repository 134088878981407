import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgForgetPasswordComponent } from "./kg-forget-password/kg-forget-password.component";
import { KgAccountsRoutingModule } from "./kg-accounts-routing.module";
import { KgChangePasswordComponent } from "./kg-change-password/kg-change-password.component";
import { SharedModule } from "../shared/shared.module";
import { KgComponentMaterialsModule } from "../libs/kg-component-materials";
import { KgAccountsService } from "../../services/kg-accounts/kg-accounts.service";
import { KgResetPasswordComponent } from "./kg-reset-password/kg-reset-password.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    KgComponentMaterialsModule,
    KgAccountsRoutingModule
  ],
  providers: [KgAccountsService],
  declarations: [
    KgForgetPasswordComponent,
    KgChangePasswordComponent,
    KgResetPasswordComponent
  ],
  exports: [KgChangePasswordComponent]
})
export class KgAccountsModule {}
