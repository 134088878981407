import { Component, OnInit, Input } from "@angular/core";
import {
  KgVisitorModel,
  VisitorStatuses
} from "src/app/model/kg-visitors/kg-visitor.model";
import { KgComponentMaterialsDataListSettingModel } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { KgVisitorService } from "src/app/services/kg-visitor/kg-visitor.service";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { KgLoggingService } from "src/app/framework/logging/kg-logging.service";
import { Subscription } from "rxjs/Subscription";
import { HttpResponse } from "@angular/common/http";
import { IKgMaterialIcons } from "src/app/modules/libs/kg-component-materials/kg-component-materials-icons/kg-component-materials-icons.component";
import { Colors } from "kg-lib-models";
import { format } from "date-fns";

@Component({
  selector: "app-kg-visitor-dashboard",
  templateUrl: "./kg-visitor-dashboard.component.html",
  styleUrls: ["./kg-visitor-dashboard.component.scss"]
})
export class KgVisitorDashboardComponent implements OnInit {
  maxItems: number = 5;
  @Input() kgvisitorList: KgVisitorModel[];
  @Input() kgvisitorSetting: KgComponentMaterialsDataListSettingModel<
    KgVisitorModel
  >;

  errorText: string;
  subscriptionGetVisitors: Subscription;
  VisitorStatusEnum = VisitorStatuses;

  constructor(
    private visitorService: KgVisitorService,
    private authenticationService: KgAuthenticationService,
    private logs: KgLoggingService
  ) {
    if (this.kgvisitorList == null || this.kgvisitorList.length == 0) {
      this.errorText = "Visitor list is empty";
    }
  }

  ngOnInit() {}

  getByStatus(visitorStatus: VisitorStatuses) {
    this.subscriptionGetVisitors = this.visitorService
      .GetLimitedVisitorsByVisitorStatus(this.maxItems, visitorStatus)
      .subscribe(
        visitors => {
          if (visitors instanceof HttpResponse) {
            if (visitors.status == 204) {
              this.kgvisitorList = null;
              this.errorText = "Visitor list is empty";
            } else {
              this.kgvisitorList = visitors.body.map(x => {
                return new KgVisitorModel(x);
              });
            }
          }
        },
        err => {
          this.kgvisitorList = null;
          this.errorText = "Visitor list is empty";
          this.logs.error("Get visitor by status dashboard", err);
        }
      );
  }

  getVisitorList() {
    this.subscriptionGetVisitors = this.visitorService
      .GetLimitedVisitors(this.maxItems)
      .subscribe(
        visitors => {
          if (visitors instanceof HttpResponse) {
            if (visitors.status == 204) {
              this.kgvisitorList = null;
            } else {
              this.kgvisitorList = visitors.body.map(x => {
                return Object.assign(x, {
                  FullName: `${x.FirstName}, ${x.LastName}`,
                  VisitDateTimeFormatted: `Visit on : ${format(
                    x.VisitDateTime,
                    "Do MMM YYYY hh:mm A"
                  )}`
                });
              });
            }
          }
        },
        err => {
          this.kgvisitorList = null;
          this.logs.error("Get visitor list for dashboard", err);
        }
      );
  }

  getVisitorIconsSetting(visitStatus: VisitorStatuses): IKgMaterialIcons {
    switch (visitStatus) {
      case VisitorStatuses.New:
      case VisitorStatuses.Approved:
        return { Icon: "fiber_new", Color: Colors.Green300 };
      case VisitorStatuses.Visited:
        return { Icon: "done", Color: Colors.Blue500 };
      case VisitorStatuses.Visiting:
        return { Icon: "move_to_inbox", Color: Colors.Blue100 };
      case VisitorStatuses.Rejected:
      case VisitorStatuses.Removed:
        return { Icon: "block", Color: Colors.Red500 };
    }
    return { Icon: "plus", Color: Colors.Grey500 };
  }
}
