import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { firebase } from "@firebase/app";
import * as types from "@firebase/messaging-types";
import "@firebase/messaging";
import { KgPeopleService } from "src/app/services/kg-people";
import { KGUaService } from "src/app/framework/services/ua.service";

// var firebase = require("firebase/app");
// require("firebase/auth");
// require("firebase/database");
@Injectable()
export class KgFirebaseMessagingService {
  instanceId = Math.random();
  messaging: types.FirebaseMessaging;
  clientTokenId: string = "No token at the moment - Please give permission";
  messagesLog: any[] = [];

  // Observable string sources
  private fcmMessageSubject = new Subject<any>();

  // Observable string streams
  fcmMessageObservable = this.fcmMessageSubject.asObservable();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private peopleService: KgPeopleService,
    private uaService: KGUaService
  ) {
    this.route.data.subscribe(x => {
      if (typeof firebase === "undefined")
        throw new Error(
          "hosting/init-error: Firebase SDK not detected. You must include it before /__/firebase/init.js"
        );
      firebase.apps.length == 0
        ? firebase.initializeApp(environment.firebase)
        : console.log("app already init, skipped firebase app init ");
      this.messaging = firebase.messaging();

      this.messaging.onTokenRefresh(async () => {
        try {
          let refreshedToken = await this.messaging.getToken();
          if (refreshedToken) {
            console.log("Token refreshed.");
          }
        } catch (error) {
          console.error(error);
        }
      });

      this.messaging.onMessage(payload => {
        console.log("Message received. ", payload);
        this.messagesLog.push(payload);
        this.announceMessageReceived(payload);
      });
    });
  }

  announceMessageReceived(message: any) {
    this.fcmMessageSubject.next(message);
  }

  async getToken() {
    try {
      let token = await this.messaging.getToken();
      return token;
    } catch (error) {
      console.error(error);
      console.error(
        "No Instance ID token available. Request permission to generate one."
      );
      return null;
    }
  }

  async requestPermission(): Promise<boolean> {
    console.log("Requesting permission...");
    try {
      await this.messaging.requestPermission();
      console.log("Notification permission granted.");
      return true;
    } catch (error) {
      console.error("Unable to get permission to notify:", error);
      return false;
    }
  }

  async deleteToken(): Promise<boolean> {
    try {
      let token = await this.getToken();
      token
        ? this.messaging.deleteToken(token)
        : console.log("Token does not exist, skip deletion");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async deleteServerToken() {
    return this.peopleService.deleteNotificationToken({
      DateAdded: new Date(),
      IsActive: true,
      Token: await this.getToken(),
      UserAgent: this.uaService.UA.ua
    });
  }

  async saveTokenToServer() {
    return this.peopleService.saveNotificationToken({
      DateAdded: new Date(),
      IsActive: true,
      Token: await this.getToken(),
      UserAgent: this.uaService.UA.ua
    });
  }
}
