import { Injectable, Output, NgZone } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { interval, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class KgServiceWorkerService {
  onAvailable = new Subject<boolean>();
  onActivated = new Subject<boolean>();

  constructor(private updates: SwUpdate, private _zone: NgZone) {
    console.log("KgServiceWorkerService running.....");
    updates.available.subscribe(event => {
      console.log("current version is", event.current);
      console.log("available version is", event.available);
      this.onAvailable.next(true);
      this.activateNewVersion();
    });

    updates.activated.subscribe(event => {
      console.log("old version was", event.previous);
      console.log("new version is", event.current);
    });
  }

  async activateNewVersion() {
    await this.updates.activateUpdate();
    this.onActivated.next(true);
    console.log("activated new service worker version");
  }

  startMonitorServiceWorkerUpdate() {
    this._zone.runOutsideAngular(fn => {
      interval(60000).subscribe(async () => {
        console.log("5sec check sw");
        this._zone.run(() => this.updates.checkForUpdate());
        // await this.updates.checkForUpdate();
        console.log("checkForUpdate()");
      });
    });
  }
}
