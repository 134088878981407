import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { KgForgetPasswordComponent } from "./kg-forget-password/kg-forget-password.component";
import { KgChangePasswordComponent } from "./kg-change-password/kg-change-password.component";
import { KgResetPasswordComponent } from "./kg-reset-password/kg-reset-password.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: "", component: KgForgetPasswordComponent },
      { path: "changepassword", component: KgChangePasswordComponent },
      { path: "forgotpassword", component: KgForgetPasswordComponent },
      { path: "resetpassword", component: KgResetPasswordComponent }
    ])
  ],
  exports: [RouterModule]
})
export class KgAccountsRoutingModule {}
