import { Injectable } from "@angular/core";

import {
  KgComplaintModel,
  ComplaintResponse,
  Priorities,
  ComplaintStatuses
} from "../../model/kg-complaint/kg-complaint";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { catchError, map } from "rxjs/operators";
import { KgReviver } from "../../framework/model/kg-reviver";
import { _throw } from "../../../../node_modules/rxjs/observable/throw";

@Injectable()
export class KgComplaintService {
  complaintStatusEnum = ComplaintStatuses;

  constructor(
    private authenticationService: KgAuthenticationService,
    private httpClient: HttpClient
  ) {}

  getAll() {
    return this.httpClient
      .get(environment.ApiHost + "api/complaint", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAllComplaintCount() {
    return this.httpClient
      .get<number>(environment.ApiHost + "api/complaint/allcomplaintcount", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  GetComplaintByPage(sortMethod: string, page: number) {
    return this.httpClient
      .get<KgComplaintModel[]>(
        environment.ApiHost +
          "api/complaint/sortby/" +
          sortMethod +
          "/pages/8/" +
          page,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray(x, KgComplaintModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getComplaintCreatedByMeCount() {
    return this.httpClient
      .get<number>(
        environment.ApiHost + "api/complaint/complaintcreatedbymecount",
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  GetComplaintCreatedByMeByPage(sortMethod: string, page: number) {
    return this.httpClient
      .get<KgComplaintModel[]>(
        environment.ApiHost +
          "api/complaint/createdbyme/sortby/" +
          sortMethod +
          "/pages/8/" +
          page,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray(x, KgComplaintModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getLimitedComplaint(maxNumber: number, sortMethod: string) {
    return this.httpClient
      .get(
        environment.ApiHost +
          "api/complaint/dashboard/max/" +
          maxNumber +
          "/sort/" +
          sortMethod,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getLimitedComplaintCreatedByMe(maxNumber: number) {
    return this.httpClient
      .get(
        environment.ApiHost +
          "api/complaint/dashboard/max/" +
          maxNumber +
          "/createdbyme",
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getLimitedComplaintByComplaintStatus(
    maxNumber: number,
    complaintStatus: ComplaintStatuses
  ) {
    return this.httpClient
      .get(
        environment.ApiHost +
          "api/complaint/dashboard/max/" +
          maxNumber +
          "/complaintstatus/" +
          this.complaintStatusEnum[complaintStatus],
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getLimitedComplaintByComplaintStatusCreatedByMe(
    maxNumber: number,
    complaintStatus: ComplaintStatuses
  ) {
    return this.httpClient
      .get(
        environment.ApiHost +
          "api/complaint/dashboard/max/" +
          maxNumber +
          "/createdbyme/complaintstatus/" +
          this.complaintStatusEnum[complaintStatus],
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getComplaintDone() {
    return this.httpClient
      .get(environment.ApiHost + "api/complaint/done", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAllDoneComplaintCount() {
    return this.httpClient
      .get<number>(
        environment.ApiHost + "api/complaint/alldonecomplaintcount",
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  GetAllDoneComplaintByPage(sortMethod: string, page: number) {
    return this.httpClient
      .get<KgComplaintModel[]>(
        environment.ApiHost +
          "api/complaint/alldonecomplaint/pages/8/" +
          page +
          "/sort/" +
          sortMethod,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray(x, KgComplaintModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAllTag() {
    return this.httpClient
      .get<string[]>(environment.ApiHost + "api/complaint/alltag", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  // getComplaintByTag(tagname: string) {
  //   return this.httpClient.get(environment.ApiHost + 'api/complaint/tag/' + tagname, { headers: this.authenticationService.getAccessTokenHeader() })
  //     // .map((response: Response) => response.json())
  //     .catch(error => { return _throw(error) });
  // }

  GetComplaintByTagCount(tagname: string) {
    return this.httpClient
      .get<number>(
        environment.ApiHost + "api/complaint/allcomplaintbytagcount/" + tagname,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  GetComplaintByTagByPage(tagname: string, page: number, sortMethod: string) {
    return this.httpClient
      .get<KgComplaintModel[]>(
        environment.ApiHost +
          "api/complaint/allcomplaintbytag/" +
          tagname +
          "/pages/8/" +
          page +
          "/sort/" +
          sortMethod,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray(x, KgComplaintModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getComplaintById(id: string) {
    return this.httpClient
      .get(environment.ApiHost + "api/complaint/" + id, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getComplaintCreatedByMe() {
    return this.httpClient
      .get(environment.ApiHost + "api/complaint/createdbyme/", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getComplaintSearchStringTotalCount(
    searchString: string,
    searchCategory: string
  ) {
    return this.httpClient.get<number>(
      environment.ApiHost +
        `api/complaint/searchstringtotalcount/${searchCategory}/${searchString}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getSearchStringByPage(
    page: number,
    searchCategory: string,
    searchString: string,
    sortMethod: string
  ) {
    return this.httpClient
      .get<KgComplaintModel[]>(
        `${
          environment.ApiHost
        }api/complaint/search/${searchCategory}/${searchString}/8/${page}/sort/${sortMethod}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        map(x => KgReviver.ReviveArray(x, KgComplaintModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addNew(complaintModel: KgComplaintModel) {
    return this.httpClient
      .post(environment.ApiHost + "api/complaint/addnew", complaintModel, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addComplaintResponse(
    complaintResponse: ComplaintResponse,
    complaintId: string
  ) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/complaint/response/" + complaintId,
        complaintResponse,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  updateComplaintPriority(complaintPriority: Priorities, complaintId: string) {
    return this.httpClient
      .post(
        environment.ApiHost +
          "api/complaint/response/" +
          complaintId +
          "/priority",
        complaintPriority,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  updateComplaintStatus(
    complaintStatus: ComplaintStatuses,
    complaintId: string
  ) {
    return this.httpClient
      .post(
        environment.ApiHost +
          "api/complaint/response/" +
          complaintId +
          "/status",
        complaintStatus,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  updateComplaintTag(complaintTag: string[], complaintId: string) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/complaint/response/" + complaintId + "/tag",
        complaintTag,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  updateExpectedCompletionDateTime(
    ExpectedCompletionDateTime: Date,
    complaintId: string
  ) {
    return this.httpClient
      .post(
        environment.ApiHost +
          "api/complaint/response/" +
          complaintId +
          "/expectedcompletiondatetime",
        ExpectedCompletionDateTime,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  uploadComplaintPhotos(
    fileInfoList: Blob[],
    complaintId: string,
    filenames: string[]
  ) {
    let formData = new FormData();
    for (let x = 0; x < fileInfoList.length; x++) {
      formData.append("files", fileInfoList[x], filenames[x]);
    }

    return this.httpClient
      .post(
        `${environment.ApiHost}/api/complaint/uploadcomplaintphotos/` +
          complaintId,
        formData,
        {
          reportProgress: true,
          headers: this.authenticationService.getAccessTokenHeader(),
          responseType: "text"
        }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }
}
