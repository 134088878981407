import { EventEmitter } from "@angular/core";

export class KgComponentMaterialsCardModel {
  onClick = new EventEmitter<boolean>();
  buttons: KgComponentMaterialsCardButtonModel[];
  item: KgComponentMaterialsCardItemModel;
  constructor(item: KgComponentMaterialsCardItemModel) {
    this.item = item;
  }
}

export class KgComponentMaterialsCardButtonModel {
  label: string;
  callBackEvent: EventEmitter<boolean>;
}

export class KgComponentMaterialsCardItemModel {
  value: any;
  label: string;

  constructor(value: any, label: string) {
    this.value = value;
    this.label = label;
  }
}
