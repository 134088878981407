export class ClipboardHelper {
  static copy(targetElement: HTMLElement) {
    if (targetElement.hasAttribute("contenteditable")) {
      targetElement.focus();
    }

    var range = document.createRange();
    range.selectNodeContents(targetElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");

    setTimeout(() => {
      targetElement.contentEditable = "false";
      window.getSelection().removeAllRanges();
    }, 1000);
  }
}
