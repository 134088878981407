import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  ContentChild,
  TemplateRef
} from "@angular/core";
import { KgComponentMaterialsDialogButtonModel } from "../../../../modules/libs/kg-component-materials";
import { MDCDialog } from "@material/dialog";

@Component({
  selector: "kg-component-materials-dialog",
  templateUrl: "./kg-component-materials-dialog.component.html",
  styleUrls: ["./kg-component-materials-dialog.component.scss"]
})
export class KgComponentMaterialsDialogComponent
  implements OnInit, AfterViewInit {
  mdcDialog: MDCDialog;
  @Input() label: string;
  @Input() body: string;
  @Input() buttons: KgComponentMaterialsDialogButtonModel[];
  @Input() kgid: string;
  @ContentChild("DialogBodyTemplate") dialogBodyTemplate: TemplateRef<any>;
  @ContentChild("DialogHeaderTemplate") dialogHeaderTemplate: TemplateRef<any>;
  @ContentChild("DialogFooterTemplate") dialogFooterTemplate: TemplateRef<any>;

  @ViewChild("kgmdcdialog") mykgdialog: ElementRef;

  ngAfterViewInit(): void {
    this.mdcDialog = new MDCDialog(this.mykgdialog.nativeElement);

    this.mykgdialog.nativeElement.addEventListener("MDCDialog:cancel", y => {
      console.log("####################dialog is canceled");
    });
  }

  ngOnInit(): void {}

  show() {
    this.mdcDialog.open();
  }

  close() {
    this.mdcDialog.close();
  }

  emit(b: EventEmitter<boolean>) {
    b.emit(true);
  }
}
// export class KgComponentMaterialsDialogComponent implements OnInit, AfterViewInit {

//   @Input() kglabel: string;
//   @Input() kgbody: string;
//   @Input() buttons: KgComponentMaterialsDialogButtonModel[];
//   @Input() kgtarget: EventTarget;

//   dialogbox: MDCDialog;
//   kgopen = false;
//   constructor() {

//   }
//   get xy() {
//     console.log("get:");

//     return this.kgopen;
//   }

//   @Input()
//   set xy(value: any) {
//     console.log("set:" + value);
//     this.kgopen = value;
//     if (this.kgopen) {
//       kgopen
//       this.dialogbox.show()
//     }

//   }

//   ngOnInit() {
//   }

//   ngAfterViewInit(): void {
//     this.dialogbox = new MDCDialog(document.querySelector('#my-mdc-dialog'));

//     this.dialogbox.listen('MDCDialog:accept', function () {
//       console.log('accepted');
//     })

//     this.dialogbox.listen('MDCDialog:cancel', function () {
//       console.log('canceled');
//     })

//     document.getElementsByClassName('mdc-dialog__backdrop')[0].addEventListener('click', function (evt) {
//       console.log("black clicked");
//       this.dialogbox.close();
//     })
//     // document.querySelector('#default-dialog-activation').addEventListener('click', function (evt) {
//     //   dialog.lastFocusedTarget = evt.target;
//     //   dialog.show();
//     // })
//   }
// }
