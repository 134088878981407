import { KgBaseModel } from "../../model/kg-core/kg-base.model";
import { KgPeopleModel } from "src/app/model/kg-people";
import { KgUnitModel } from "src/app/model/kg-units/kg-unit.model";

export class KgPeopleUnitsResponseModel extends KgPeopleModel {
  PeopleUnits: KgUnitModel[];

  constructor(peopleUnits?: KgPeopleUnitsResponseModel) {
    super(peopleUnits);
    if (peopleUnits != null) {
      Object.assign(this, {
        PeopleUnits: peopleUnits.PeopleUnits
          ? peopleUnits.PeopleUnits.map(x => new KgUnitModel(x))
          : []
      });
    }
  }
}
