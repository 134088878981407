import { ModelStates } from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";

export enum KgOwnershipTypes {
  Owner = 0,
  MasterTenant = 1,
  Resident = 2,
  Tenant = 3
}

export class KgUnitPeopleModel extends KgBaseModel {
  PeopleId: string;
  OwnershipType: KgOwnershipTypes;

  constructor(unitPeople?: KgUnitPeopleModel) {
    super();
    if (unitPeople != null) {
      Object.assign(this, unitPeople);
    } else {
      Object.assign(this, {
        PeopleId: "",
        OwnershipType: KgOwnershipTypes.Owner
      });
    }
  }
}

export class KgUnitModel extends KgBaseModel {
  UnitType: number;
  UnitNumber: string;
  Block: string;
  Level: string;
  Number: string;
  Peoples: KgUnitPeopleModel[];

  constructor(unitModel?: KgUnitModel) {
    super();
    if (unitModel != null) {
      Object.assign(this, unitModel);
    } else {
      Object.assign(this, {
        UnitType: 0,
        UnitNumber: "",
        Block: "",
        Level: "",
        Number: "",
        Peoples: []
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return
    return Object.assign({}, this, {
      // convert fields that need converting
    });
  }
}

export class KgUnitModelExtended extends KgUnitModel {
  constructor(unit?: KgUnitModel, isSelected?: boolean) {
    super();
    Object.assign(this, unit, {
      ItemState: ModelStates.Pristine,
      IsSelected: isSelected ? isSelected : false
    });
  }

  get getKgUnitModel(): KgUnitModel {
    let clone = Object.assign({}, this);
    delete clone.ItemState;
    delete clone.IsSelected;
    return clone as KgUnitModel;
  }

  ItemState: ModelStates;
  IsSelected: boolean;
}
