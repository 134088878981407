import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { KgDrawerNavigationModel } from "./kg-drawer-navigation.model";
import { PeopleRoles } from "../../model/kg-core/kg-core.model";
import { MDCDrawer } from "@material/drawer";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { LocationHelper } from "src/app/framework/helper/kg-location.helper";
import { Location } from "@angular/common";
import { KgElementRef } from "src/app/framework/model/kg-elementref";
import { Router, NavigationEnd } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { KgDrawerService } from "../../services/kg-drawer/kg-drawer.service";

@Component({
  moduleId: module.id,
  selector: "kg-component-drawer",
  templateUrl: "kg-component-drawer.component.html",
  styleUrls: ["kg-component-drawer.component.scss"]
})
export class KgComponentDrawerComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject();
  temporaryDrawer: MDCDrawer;
  shouldShowDrawer = true;
  navigationList: KgDrawerNavigationModel[] = [];
  @ViewChild("mdcTemporaryDrawer") mdcTempDrawer: KgElementRef<HTMLElement>;
  currentRoute: KgDrawerNavigationModel = null;

  drawerCloseEventFunc: () => void = () => {
    if (this._location.path(true).indexOf("#drawershow") > -1) {
      // LocationHelper.removeHash("#drawershow");
      this._location.back();
    }
  };

  drawerOpenEventFunc: () => void = () => {
    console.log("drawerOpenEventFunc");
    LocationHelper.addHash("drawershow");
    // let x = new drawer.MDCTemporaryDrawer(null);
  };

  constructor(
    public authenticationService: KgAuthenticationService,
    private _location: Location,
    private router: Router,
    private drawerService: KgDrawerService
  ) {
    this.authenticationService.authentication$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(userAuthenticated => {
        this.shouldShowDrawer = userAuthenticated;
        this.configDrawerNavigation();
      });

    this.router.events.subscribe(x => {
      document.title = this.router.url;
      // console.log(ctext('routerEvent'), ccc.Blue300, x)
      if (x instanceof NavigationEnd) {
        if (x.url.indexOf("#drawershow") > -1) {
          this.temporaryDrawer.open = true;
        } else {
          this.temporaryDrawer.open = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.listenToDrawerEvent();
    // this._location.subscribe(x => {
    //   this.temporaryDrawer.open = x.type == "hashchange" && LocationHelper.hasHash("drawershow");
    // });

    this.configDrawerNavigation();
    this.temporaryDrawer = new MDCDrawer(this.mdcTempDrawer.nativeElement);

    document.body.addEventListener(
      "MDCDrawer:opened",
      this.drawerOpenEventFunc
    );
    document.body.addEventListener(
      "MDCDrawer:closed",
      this.drawerCloseEventFunc
    );

    // this.temporaryDrawer.listen('MDCDrawer:opened', this.drawerOpenEventFunc)
    // this.temporaryDrawer.listen('MDCDrawer:closed', this.drawerCloseEventFunc)
  }

  listenToDrawerEvent() {
    this.drawerService.drawer$.subscribe(showDrawer => {
      let item = document.querySelector("#icon-with-text-demo") as HTMLElement;
      console.log(item);
      // (item.children.item(0) as HTMLElement).focus();
      this.temporaryDrawer.open = showDrawer;
    });
  }

  configDrawerNavigation() {
    if (this.authenticationService.hasSession) {
      this.shouldShowDrawer = true;

      this.navigationList = this.getNavigationList().filter(x => {
        if (x.RequiredRoles.length == 0) {
          return true;
        } else {
          return x.RequiredRoles.some(r => {
            return this.authenticationService.hasAccessRole(r);
          });
        }
      });
    } else {
      this.shouldShowDrawer = false;
      this.navigationList.push(
        new KgDrawerNavigationModel("Signin", "/signin", "account_box")
      );
    }
  }

  getNavigationList(): KgDrawerNavigationModel[] {
    return [
      {
        Title: "Dashboard",
        RouterLink: "/dashboard",
        MaterialIconId: "dashboard",
        RequiredRoles: [
          PeopleRoles.Administrator,
          PeopleRoles.Chairperson,
          PeopleRoles.Committee,
          PeopleRoles.Manager,
          PeopleRoles.Clerk,
          PeopleRoles.Owner,
          PeopleRoles.Resident,
          PeopleRoles.MasterTenant,
          PeopleRoles.Tenant
        ],
        CssClass: "mdc-list-item"
      },
      {
        Title: "Announcement",
        RouterLink: "/announcements",
        MaterialIconId: "rss_feed",
        RequiredRoles: [
          PeopleRoles.Administrator,
          PeopleRoles.Chairperson,
          PeopleRoles.Committee,
          PeopleRoles.Manager,
          PeopleRoles.Clerk,
          PeopleRoles.Owner,
          PeopleRoles.Resident,
          PeopleRoles.MasterTenant,
          PeopleRoles.Tenant
        ],
        CssClass: "mdc-list-item"
      },
      {
        Title: "Complaint",
        RouterLink: "/complaint",
        MaterialIconId: "forum",
        RequiredRoles: [
          PeopleRoles.Administrator,
          PeopleRoles.Chairperson,
          PeopleRoles.Committee,
          PeopleRoles.Manager,
          PeopleRoles.Clerk
        ],
        CssClass: "mdc-list-item"
      },
      {
        Title: "My Complaint",
        RouterLink: "/complaint/createdbyme",
        MaterialIconId: "forum",
        RequiredRoles: [
          PeopleRoles.Owner,
          PeopleRoles.Resident,
          PeopleRoles.Tenant,
          PeopleRoles.MasterTenant
        ],
        CssClass: "mdc-list-item"
      },
      {
        Title: "People",
        RouterLink: "/peoples",
        MaterialIconId: "recent_actors",
        RequiredRoles: [
          PeopleRoles.Administrator,
          PeopleRoles.Chairperson,
          PeopleRoles.Committee,
          PeopleRoles.Manager,
          PeopleRoles.Clerk
        ],
        CssClass: "mdc-list-item"
      },
      {
        Title: "Managements",
        RouterLink: "/managements",
        MaterialIconId: "work",
        RequiredRoles: [
          PeopleRoles.Administrator,
          PeopleRoles.Chairperson,
          PeopleRoles.Committee
        ],
        CssClass: "mdc-list-item"
      },
      {
        Title: "My Units",
        RouterLink: "/units/myunits",
        MaterialIconId: "recent_actors",
        RequiredRoles: [PeopleRoles.Owner, PeopleRoles.MasterTenant],
        CssClass: "mdc-list-item"
      },
      // {
      //   Title: "File Manager",
      //   RouterLink: "/filemanager",
      //   MaterialIconId: "view_list",
      //   RequiredRoles: [PeopleRoles.Administrator],
      //   CssClass: "mdc-list-item"
      // },
      {
        Title: "Visitors",
        RouterLink: "/visitors",
        MaterialIconId: "assignment_ind",
        RequiredRoles: [],
        CssClass: "mdc-list-item"
      },
      {
        Title: "Security",
        RouterLink: "/security",
        MaterialIconId: "security",
        RequiredRoles: [PeopleRoles.Administrator, PeopleRoles.SecurityGuard],
        CssClass: "mdc-list-item"
      },
      {
        Title: "Configuration",
        RouterLink: "/communities/configuration",
        MaterialIconId: "settings",
        RequiredRoles: [PeopleRoles.Clerk, PeopleRoles.Manager],
        CssClass: "mdc-list-item"
      }
    ];
  }

  toggleDrawer() {
    // this.temporaryDrawer.open = this.temporaryDrawer.open ? false : true;
    if (this._location.path(true).indexOf("#drawershow") == -1) {
      LocationHelper.addHash("#drawershow");
    }
  }

  routeToLink(e: MouseEvent, item: KgDrawerNavigationModel) {
    e.preventDefault();
    this.router.navigate([item.RouterLink], { replaceUrl: true });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
  }
}
