import { Pipe, PipeTransform } from "@angular/core";
import { ProfilePhotosModel, PhotoSizeTypes } from "../../../model/kg-people";

@Pipe({
  name: "kgphotofind"
})
export class KgProfilePhotoFindPipe implements PipeTransform {
  transform(
    photos: ProfilePhotosModel[],
    size: PhotoSizeTypes,
    isPrimary: boolean = true
  ): any {
    return photos.find(x => {
      return x.IsPrimary == isPrimary && x.PhotoSizeType == size;
    });
  }
}
