import { KgBaseModel } from "../../model/kg-core/kg-base.model";

export class KgFileItemInfoModel extends KgBaseModel {
  FileName: string;
  FileUri: string;
  FileStorageUri: {
    PrimaryUri: string;
    SecondaryUri: string;
  };
  ContentType: string;
  Length: number;

  constructor(fileItemInfoModel?: KgFileItemInfoModel) {
    super(fileItemInfoModel);
    if (fileItemInfoModel != null) {
      Object.assign(this, fileItemInfoModel, {
        CreateDate: new Date(fileItemInfoModel.CreateDate),
        ActionDate: new Date(fileItemInfoModel.ActionDate)
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return
    return Object.assign({}, this, {
      // convert fields that need converting
      CreateDate: this.CreateDate.toISOString(),
      ActionDate: this.ActionDate.toISOString()
    });
  }
}

[];
