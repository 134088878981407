import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { environment } from "environments/environment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { KgSnackbarService } from "../../../services/kg-snackbar/kg-snackbar.service";
import { KgPeopleService } from "../../../services/kg-people";
import { IKgApiUpdateResult } from "../../../framework/http/ikg-update-result";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { KgProgressBarService } from "../../../services/kg-progress-bar/kg-progress-bar.service";

@Component({
  selector: "kg-change-password",
  templateUrl: "./kg-change-password.component.html",
  styleUrls: ["./kg-change-password.component.scss"]
})
export class KgChangePasswordComponent implements OnInit {
  validationMessages = environment.ResourceTyped.ValidationMessages.Peoples;
  changePasswordFormGroup: FormGroup;
  constructor(
    private fb: FormBuilder,
    private snackbarService: KgSnackbarService,
    private peopleService: KgPeopleService,
    private progressBar: KgProgressBarService,
    private _location: Location,
    private router: Router
  ) {
    this.changePasswordFormGroup = fb.group({
      OldPassword: ["", [Validators.required, Validators.minLength(8)]],
      NewPassword1: ["", [Validators.required, Validators.minLength(8)]],
      NewPassword2: ["", [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit() {}

  onSubmit(e: MouseEvent) {
    if (
      this.changePasswordFormGroup.controls["NewPassword1"].value ==
      this.changePasswordFormGroup.controls["NewPassword2"].value
    ) {
      this.progressBar.start();
      this.peopleService
        .changePassword(
          this.changePasswordFormGroup.controls["OldPassword"].value,
          this.changePasswordFormGroup.controls["NewPassword1"].value
        )
        .subscribe(
          (x: IKgApiUpdateResult) => {
            console.log(x);
            x.ModifiedCount > 0
              ? this.snackbarService.show({
                  actionButtonText: "Dismiss",
                  labelText: "Password change successful",
                  onClosed: () => {
                    this.back();
                  },
                  timeoutMs: 10000
                })
              : null;
          },
          (error: HttpErrorResponse) => {
            error.status == 404
              ? this.snackbarService.show({
                  actionButtonText: "Dismiss",
                  labelText: "Wrong old password, please try again",
                  timeoutMs: 5000
                })
              : this.snackbarService.show({
                  actionButtonText: "Retry",
                  labelText: "System error",
                  timeoutMs: 5000
                });
            this.progressBar.stop();
          },
          () => {
            this.progressBar.stop();
          }
        );
    } else {
      this.snackbarService.show({
        actionButtonText: "Dismiss",
        labelText: "New password does not match",
        timeoutMs: 5000
      });
    }
  }

  onCancel(e: MouseEvent) {
    this.back();
  }

  back() {
    this._location.back();
  }
}
