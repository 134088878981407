import { ActionType } from "../kg-core";

export class KgBaseModel {
  Id: string;
  CreateById: string;
  CreateDate: Date;
  ActionById: string;
  ActionDate: Date;
  ActionType: ActionType;
  CommunitiesId: string;

  constructor(obj?: any) {
    if (obj != null) {
      Object.assign(obj, {
        CreateDate: new Date(obj.CreateDate),
        ActionDate: new Date(obj.ActionDate)
      });
    } else {
      Object.assign(this, {
        Id: "",
        CreateById: "",
        CreateDate: new Date(0),
        ActionById: "",
        ActionDate: new Date(0),
        ActionType: 0,
        CommunitiesId: ""
      });
    }
  }
}
