import {
  Directive,
  Input,
  SimpleChanges,
  ElementRef,
  Renderer2,
  HostListener
} from "@angular/core";
// import { Moment } from 'moment';
import { OnChanges } from "@angular/core";
import {
  DatePickerConfig,
  DatePickerSelectionMode
} from "src/app/modules/libs/kg-component-materials/kg-component-materials-datepicker/kg-component-materials-datepicker-dialog/kg-component-materials-datepicker-dialog.component";
import {
  endOfDay,
  startOfDay,
  getDayOfYear,
  isAfter,
  isBefore
} from "date-fns";
// import * as moment from 'moment';

@Directive({
  selector: "[KGDateDirective]"
})
export class KgComponentMaterialsDatepickerDatesDirective implements OnChanges {
  @Input() selectedStartDate: Date;
  @Input() row: number;
  @Input() col: number;
  @Input() monthCalendar: Date[];
  @Input() datePickerConfig: DatePickerConfig;

  constructor(public el: ElementRef, private renderer: Renderer2) {}
  @HostListener("mouseenter") onMouseEnter() {
    switch (this.datePickerConfig.selectionMode) {
      case DatePickerSelectionMode.AllowPast:
        let checkDate = this.monthCalendar[this.row * 7 + this.col];
        if (this.selectedStartDate) {
          // if (checkDate.endOf('day').isAfter(this.selectedStartDate.startOf("day")) && checkDate.startOf('day').isBefore(moment())) {
          if (
            isAfter(endOfDay(checkDate), startOfDay(this.selectedStartDate)) &&
            isBefore(startOfDay(checkDate), new Date())
          ) {
            this.highlight(true);
          }
        } else {
          // if ((checkDate.startOf('day').isBefore(moment()))) {
          if (isBefore(startOfDay(checkDate), new Date())) {
            this.highlight(true);
          }
        }

        break;
      case DatePickerSelectionMode.AllowFuture:
        if (this.selectedStartDate) {
          // if (checkDate.endOf('day').isAfter(this.selectedStartDate.startOf("day"))) {
          if (
            isAfter(endOfDay(checkDate), startOfDay(this.selectedStartDate))
          ) {
            this.highlight(true);
          }
        } else {
          // if ((checkDate.endOf('day').isAfter(moment()))) {
          if (isAfter(endOfDay(checkDate), new Date())) {
            this.highlight(true);
          }
        }

        break;
      case DatePickerSelectionMode.AllowAll:
        if (this.selectedStartDate) {
          // if (checkDate.endOf('day').isAfter(this.selectedStartDate.startOf("day"))) {
          if (
            isAfter(endOfDay(checkDate), startOfDay(this.selectedStartDate))
          ) {
            this.highlight(true);
          }
        } else {
          this.highlight(true);
        }

        break;
    }
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.highlight(false);
  }

  private highlight(hover: boolean) {
    // background-color: var(--mdc-theme-primary);
    // border-radius: 50%;
    if (hover) {
      (this.el.nativeElement as HTMLElement).style.backgroundColor =
        "var(--mdc-theme-primary)";
      (this.el.nativeElement as HTMLElement).style.borderRadius = "50%";
    } else {
      (this.el.nativeElement as HTMLElement).style.backgroundColor = "white";
      (this.el.nativeElement as HTMLElement).style.borderRadius = "0";
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let checkDate = this.monthCalendar[this.row * 7 + this.col];

    // if ((checkDate) || (this.selectedStartDate && checkDate)) {
    if (checkDate) {
      switch (this.datePickerConfig.selectionMode) {
        case DatePickerSelectionMode.AllowPast:
          // if ((checkDate.startOf('day').isAfter(moment())) ||
          //   (this.selectedStartDate && checkDate && checkDate.endOf('day').isBefore(this.selectedStartDate))) {
          //   (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
          if (
            isAfter(startOfDay(checkDate), new Date()) ||
            (this.selectedStartDate &&
              checkDate &&
              isBefore(endOfDay(checkDate), this.selectedStartDate))
          ) {
            (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
          } else {
            (this.el.nativeElement as HTMLElement).style.opacity = "1";
          }
          break;
        case DatePickerSelectionMode.AllowFuture:
          // if (checkDate.endOf('day').isBefore(moment()) ||
          //   (this.selectedStartDate && checkDate && checkDate.endOf('day').isBefore(this.selectedStartDate))) {
          //   (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
          if (
            isBefore(endOfDay(checkDate), new Date()) ||
            (this.selectedStartDate &&
              checkDate &&
              isBefore(endOfDay(checkDate), this.selectedStartDate))
          ) {
            (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
          } else {
            (this.el.nativeElement as HTMLElement).style.opacity = "1";
          }
          break;
        case DatePickerSelectionMode.AllowAll:
          // if (((this.selectedStartDate && checkDate && checkDate.endOf('day').isBefore(this.selectedStartDate)))) {
          //   (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
          if (
            this.selectedStartDate &&
            checkDate &&
            isBefore(endOfDay(checkDate), this.selectedStartDate)
          ) {
            (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
          } else {
            (this.el.nativeElement as HTMLElement).style.opacity = "1";
          }
          break;
      }
    } else {
      (this.el.nativeElement as HTMLElement).style.opacity = "1";
    }

    // if(checkDate.endOf('day').isBefore(this.selectedStartDate))
    // {
    //   (this.el.nativeElement as HTMLElement).style.opacity = "0.2";
    // }
    // } else {
    //   (this.el.nativeElement as HTMLElement).style.opacity = "1";
    // }

    if (
      this.datePickerConfig.selectionMode == DatePickerSelectionMode.AllowAll &&
      this.selectedStartDate &&
      checkDate &&
      getDayOfYear(this.selectedStartDate) == getDayOfYear(checkDate)
    ) {
      (this.el.nativeElement as HTMLElement).style.backgroundColor =
        "var(--mdc-theme-primary, #fff)";
      (this.el.nativeElement as HTMLElement).style.borderRadius = "50%";
    }
  }
}
