import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgSpinnerComponent } from "./kg-spinner/kg-spinner.component";

@NgModule({
  imports: [CommonModule],
  declarations: [KgSpinnerComponent],
  exports: [KgSpinnerComponent]
})
export class KgSpinnerModule {}
