import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { KgAccountsService } from "../../../services/kg-accounts/kg-accounts.service";
import { KgSnackbarService } from "../../../services/kg-snackbar/kg-snackbar.service";
import { Location } from "@angular/common";
import { environment } from "environments/environment";
import { KgProgressBarService } from "../../../services/kg-progress-bar/kg-progress-bar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IKgApiUpdateResult } from "../../../framework/http/ikg-update-result";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap, map } from "rxjs/operators";

@Component({
  selector: "app-kg-reset-password",
  templateUrl: "./kg-reset-password.component.html",
  styleUrls: ["./kg-reset-password.component.scss"]
})
export class KgResetPasswordComponent implements OnInit {
  resetPasswordFormGroup: FormGroup;
  validationMessages = environment.ResourceTyped.ValidationMessages.Peoples;
  resetToken$: Observable<string>;
  constructor(
    private accountsService: KgAccountsService,
    private snackbarService: KgSnackbarService,
    private _location: Location,
    private fb: FormBuilder,
    private progressBar: KgProgressBarService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.resetPasswordFormGroup = fb.group({
      NewPassword1: ["", [Validators.minLength(8)]],
      NewPassword2: ["", [Validators.minLength(8)]]
    });
  }

  ngOnInit() {
    this.resetToken$ = this.route.queryParamMap.pipe(
      map(params => {
        console.log(params);
        return params.get("token");
      })
    );
  }

  onCancel(e: MouseEvent) {
    this._location.back();
  }

  onSubmit(e: MouseEvent) {
    if (
      this.resetPasswordFormGroup.controls["NewPassword1"].value ==
      this.resetPasswordFormGroup.controls["NewPassword2"].value
    ) {
      this.progressBar.start();

      this.resetToken$
        .pipe(
          switchMap(token => {
            return this.accountsService.resetPassword(
              this.resetPasswordFormGroup.controls["NewPassword1"].value,
              token
            );
          })
        )
        .subscribe(
          (x: IKgApiUpdateResult) => {
            console.log(x);
            x.ModifiedCount > 0
              ? this.snackbarService.show({
                  actionButtonText: "Sign in",
                  labelText: "Password change successful",
                  onClosed: () => {
                    this.router.navigate(["/signin"]);
                  },
                  timeoutMs: 10000
                })
              : null;
          },
          (error: HttpErrorResponse) => {
            switch (error.status) {
              case 404:
                this.snackbarService.show({
                  actionButtonText: "Dismiss",
                  labelText: "Wrong old password, please try again",
                  onClosed: () => {},
                  timeoutMs: 5000
                });
                break;
              case 401: {
                this.snackbarService.show({
                  actionButtonText: "Resend",
                  labelText:
                    "Reset session has expired, goto forgot password and resend email",

                  onClosed: () => {
                    this.router.navigate(["/accounts/forgotpassword"]);
                  },
                  timeoutMs: 5000
                });
              }
            }

            this.progressBar.stop();
          },
          () => {
            this.progressBar.stop();
          }
        );
    } else {
      this.snackbarService.show({
        actionButtonText: "Dismiss",
        labelText: "New password does not match",
        onClosed: () => {},
        timeoutMs: 5000
      });
    }
  }

  back() {
    this._location.back();
  }
}
