import { Component, OnInit } from "@angular/core";
import { KgPeopleModel } from "src/app/model/kg-people";
import { environment } from "environments/environment";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-kg-profile",
  templateUrl: "./kg-profile.component.html",
  styleUrls: ["./kg-profile.component.scss"]
})
export class KgProfileComponent implements OnInit {
  peopleProfile: KgPeopleModel = this.authenticationService.myprofile;
  isDebug = !environment.production;

  constructor(
    public authenticationService: KgAuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {}

  onChangePassword(e: MouseEvent) {
    this.router.navigate(["/accounts/changepassword"]);
  }
}
