import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ElementRef
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { KgPeopleRouterDataService } from "../kg-people-routerdata.service";
import {
  KgPeopleModel,
  ProfilePhotosModel,
  PhotoSizeTypes
} from "../../../model/kg-people/kg-people.model";
import { KgAccessCardModel } from "../../../model/kg-people/kg-accesscard.model";
import {
  Salutation,
  Gender,
  IdentificationTypes,
  AccessCardStatuses,
  PeopleRoles,
  PeopleStatuses
} from "../../../model/kg-core";
import { KgEnumHandler, KgEnumKeyPair } from "../../../utility/kg-enum-handler";
import { KgCommunitiesService } from "../../../services/kg-communities";
import { KgPeopleService } from "../../../services/kg-people/kg-people.service";
import { KgCommunitiesModel } from "../../../model";
import { CommunitiesConfiguration } from "../../../model/kg-communities/kg-communities-configuration";
import { KgComponentMaterialsDialog2Component } from "../../../modules/libs/kg-component-materials/kg-component-materials-dialog2";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import {
  KgProfileManagerComponent,
  KgProfileManagerComponentOutput,
  KgPhotoProperties,
  PhotoBlobStatuses
} from "../../../modules/kg-filemanager/kg-profile-manager/kg-profile-manager.component";
import { environment } from "../../../../environments/environment";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";
import { KgPeopleFormGroup } from "../../../model/kg-people/kg-people.formgroup";
// import { MDCIconToggle } from "@material/icon-toggle";
import { Subscription } from "rxjs/Subscription";
import { KgCommunityConfigService } from "src/app/services/kg-communities/kg-community-config.service";
import {
  KgOwnershipTypes,
  KgUnitModelExtended
} from "src/app/model/kg-units/kg-unit.model";
import { KgUnitService } from "src/app/services/kg-unit/kg-unit.service";
import { HttpResponse } from "@angular/common/http";
import { ModelStates } from "src/app/model/kg-core/kg-core.model";
import { NewPeopleMode } from "src/app/model/kg-people";
import { CountriesHelper } from "src/app/framework/helper/countries.helper";
import { OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators/takeUntil";
import { switchMap } from "rxjs/operators/switchMap";
import { concat } from "rxjs/observable/concat";
import { ScrollToService } from "@nicky-lenaers/ngx-scroll-to";
import { Subject } from "rxjs";
import { MDCCheckbox } from "@material/checkbox";
import { MDCCheckboxEmitModel } from "../../libs/kg-component-materials";
import { KgArray } from "src/app/framework/utilities/kgarray";
@Component({
  selector: "app-kg-people-editform",
  templateUrl: "./kg-people-editform.component.html",
  styleUrls: ["./kg-people-editform.component.scss"]
})
export class KgPeopleEditFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$ = new Subject();
  // IsCreateNew = false;
  peopleFormControl: KgPeopleFormGroup;
  salutationSelectionList: KgEnumKeyPair[] = [];
  genderSelectionList: KgEnumKeyPair[] = [];
  identificationTypeSelectionList: KgEnumKeyPair[] = [];
  accessCardTypeSelectionList: any = [];
  accessCardStatusSelectionList: KgEnumKeyPair[] = [];
  rolesAvailable: Array<{ key: number; item: string; checked: boolean }> = [];
  subscriptionSubmit: Subscription;
  subscriptionGetAccessCard: Subscription;
  subscriptionGetAccessCardType: Subscription;
  peopleModel: KgPeopleModel;
  peopleDataMapping: KgPeopleModel;
  newAccessCard: KgAccessCardModel;
  communitiesConfiguration: CommunitiesConfiguration;
  administrator: boolean = false;
  selectableCommunitiesInput: KgCommunitiesModel[];
  uploadProfileDialogOutput = new KgProfileManagerComponentOutput();
  formStep = 1;
  firstStepperOffsetTop = 0;
  firstStepperOffsetHeight = 0;
  @ViewChild("MyUploadProfileDialog")
  myUploadProfileDialog: KgComponentMaterialsDialog2Component;
  myUploadProfileComponent = KgProfileManagerComponent;
  validationMessages = environment.ResourceTyped.ValidationMessages.Peoples;
  @ViewChild("SelectCommunityDialog")
  selectCommunityDialog: KgComponentMaterialsDialog2Component;
  @ViewChildren("steppers") steppers: QueryList<ElementRef>;
  allCommunitiesData: KgCommunitiesModel[];
  allSelectedPhotos: KgPhotoProperties[][] = [];
  @ViewChildren("deletetoggle") deleteTogglers: QueryList<ElementRef>;
  @ViewChildren("favtoggle") favTogglers: QueryList<ElementRef>;
  @ViewChildren("photocell") photocells: QueryList<ElementRef>;
  // favTogglersMdc: MDCIconToggle[];
  peopleUnitList: KgUnitModelExtended[] = [];
  newPeopleMode: NewPeopleMode = NewPeopleMode.NewTenant;
  editPeopleId: string;
  nationalitySelectionList: KgEnumKeyPair[] = [];
  constructor(
    private scrollToService: ScrollToService,
    private communitiesService: KgCommunitiesService,
    private routeDataExchange: KgPeopleRouterDataService,
    private router: Router,
    private route: ActivatedRoute,
    private enumUtility: KgEnumHandler,
    private kgPeopleService: KgPeopleService,
    private authenticationService: KgAuthenticationService,
    public _DomSanitizationService: DomSanitizer,
    private progressBarService: KgProgressBarService,
    private communityConfig: KgCommunityConfigService,
    private unitService: KgUnitService
  ) {
    this.peopleDataMapping = new KgPeopleModel();
    this.peopleDataMapping.Roles = [];
    this.peopleModel = new KgPeopleModel();
    this.newAccessCard = new KgAccessCardModel();

    this.uploadProfileDialogOutput.onCancel.subscribe(cancel =>
      this.onCancelUpload(cancel)
    );
    this.uploadProfileDialogOutput.onDone.subscribe(photoblobsizes =>
      this.onDoneCroppedPhoto(photoblobsizes)
    );
  }

  ngOnInit() {
    this.route.data.subscribe(x => {
      this.newPeopleMode =
        x["newPeopleMode"] != null ? x["newPeopleMode"] : null;
      this.initFormGroupModel(this.newPeopleMode);
      if (this.authenticationService.hasAccessRole(PeopleRoles.Administrator)) {
        this.administrator = true;
      }
    });

    this.scrollToService.scrollTo({ target: "top-anchor" });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.routeDataExchange.data = null;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  initSelectableAccessCardTypes() {
    if (this.canAddAccessCard()) {
      let communitiesId = this.peopleFormControl.controls["CommunitiesId"]
        .value; // : this.peopleModel.CommunitiesId;
      this.subscriptionGetAccessCardType = this.communitiesService
        .getCommunitiesConfiguration(communitiesId)
        .subscribe(x => {
          if (x != null) {
            // this.communitiesConfiguration = new CommunitiesConfiguration();
            this.communitiesConfiguration = x;
          } else {
            this.communitiesConfiguration = {
              AccessCardTypes: ["Error Please configure community"]
            };
          }
        });
    }
  }

  checkPeopleRoles(): string {
    let currentPeopleRole;

    if (
      this.authenticationService.hasAccessRole(PeopleRoles.Chairperson) &&
      this.authenticationService.hasAccessRole(PeopleRoles.Owner)
    ) {
      currentPeopleRole = "ChairpersonAndOwner";
    } else if (
      this.authenticationService.hasAccessRole(PeopleRoles.Administrator)
    ) {
      currentPeopleRole = "Administrator";
    } else if (
      this.authenticationService.hasAccessRole(PeopleRoles.Chairperson)
    ) {
      currentPeopleRole = "Chairperson";
    } else if (this.authenticationService.hasAccessRole(PeopleRoles.Manager)) {
      currentPeopleRole = "Management";
    } else if (this.authenticationService.hasAccessRole(PeopleRoles.Clerk)) {
      currentPeopleRole = "Clerk";
    } else if (this.authenticationService.hasAccessRole(PeopleRoles.Owner)) {
      currentPeopleRole = "Owner";
    }

    return currentPeopleRole;
  }

  initFormGroupModel(mode: NewPeopleMode) {
    if (mode != NewPeopleMode.EditExisting) {
      let newPeople = new KgPeopleModel();
      newPeople.Nationality = "MY";
      newPeople.MobileNumberCountryCode = "MY";
      newPeople.ContactNumberCountryCode = "MY";
      this.peopleFormControl = new KgPeopleFormGroup(
        newPeople,
        this.kgPeopleService
      );
      this.peopleFormControl.controls["CommunitiesId"].setValue(
        this.authenticationService.myprofile.CommunitiesId
      );
      this.initSelectableAccessCardTypes();
      this.initSelectables();

      if (!this.hasFullPeopleCreationAccess()) {
        this.getUnitListByPeopleId(this.authenticationService.myprofile.Id);
      }
    } else {
      this.progressBarService.toggleProgressBar(true);

      this.route.paramMap
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(routeParams => {
          this.editPeopleId = routeParams.get("id");
          if (this.routeDataExchange.data) {
            this.peopleModel = this.routeDataExchange.data;
            this.extractPeopleModelProfileToGenerateBloblList(
              this.peopleModel.ProfilePhotos
            );
            this.peopleFormControl = new KgPeopleFormGroup(
              this.peopleModel,
              this.kgPeopleService,
              true
            );
            this.peopleFormControl.removeControl("Password");
            this.initSelectableAccessCardTypes();
            this.progressBarService.toggleProgressBar(false);
            this.initSelectables(this.peopleModel.Roles);
          } else {
            this.kgPeopleService.getById(this.editPeopleId).subscribe(
              foundPerson => {
                if (foundPerson != null) {
                  this.peopleModel = new KgPeopleModel(foundPerson);
                  this.peopleFormControl = new KgPeopleFormGroup(
                    this.peopleModel,
                    this.kgPeopleService,
                    true
                  );
                  this.peopleFormControl.removeControl("Password");
                  this.initSelectableAccessCardTypes();
                  this.extractPeopleModelProfileToGenerateBloblList(
                    this.peopleModel.ProfilePhotos
                  );
                  this.progressBarService.toggleProgressBar(false);

                  // this.rolesAvailable = this.rolesAvailable.map(x => {
                  //   return { key: x.key, item: x.item, checked: this.peopleModel.Roles.includes(x.key as PeopleRoles) ? true : false }
                  // })
                  this.initSelectables(this.peopleModel.Roles);

                  console.log(
                    this.peopleFormControl.controls["AccessCards"].value
                  );
                }
              },
              error => {
                console.log("cant get peoplebyid", error);
              }
            );
          }

          this.hasFullPeopleCreationAccess()
            ? this.getUnitListByPeopleId(this.editPeopleId)
            : this.getUnitListByPeopleId(
                this.authenticationService.myprofile.Id
              );
        });
    }
  }

  getUnitListByPeopleId(peopleId: string) {
    this.unitService.getPeopleUnitByPeopleId(peopleId).subscribe(x => {
      if (x instanceof HttpResponse) {
        console.log("look here");
        console.log(x);
        this.peopleUnitList = x.body.map(y => {
          let selectableUnits = new KgUnitModelExtended(y);
          return selectableUnits;
        });
        console.log(this.peopleUnitList);
      }
    });
  }

  extractPeopleModelProfileToGenerateBloblList(photos: ProfilePhotosModel[]) {
    let uniqued = [];

    photos.forEach(x => {
      if (uniqued.indexOf(x.PhotoUuid) == -1) {
        uniqued.push(x.PhotoUuid);
      }
    });

    uniqued.forEach(u => {
      let blobList: KgPhotoProperties[] = [];
      photos
        .filter(x => {
          return x.PhotoUuid == u;
        })
        .forEach(x => {
          blobList.push({
            size: x.PhotoSizeType,
            blob: null,
            type: null,
            name: x.FileName,
            blobUrl: x.FileUri,
            photouuid: x.PhotoUuid,
            isprimary: x.IsPrimary,
            status: PhotoBlobStatuses.Existing
          });
        });
      this.allSelectedPhotos.push(blobList);
    });

    console.log(this.allSelectedPhotos);
  }

  initSelectables(checkRoles?: Array<PeopleRoles>) {
    this.salutationSelectionList = this.enumUtility.getEnumKeyItemPairs(
      Salutation
    );
    this.genderSelectionList = this.enumUtility.getEnumKeyItemPairs(Gender);
    this.identificationTypeSelectionList = this.enumUtility.getEnumKeyItemPairs(
      IdentificationTypes
    );
    this.rolesAvailable = this.getRolesAppointmentRights(checkRoles);
    this.accessCardStatusSelectionList = this.enumUtility.getEnumKeyItemPairs(
      AccessCardStatuses
    );
    this.nationalitySelectionList = CountriesHelper.COUNTRIESCODES.map(x => {
      return { key: x.CountryCode, item: x.Name };
    });
  }

  getRolesAppointmentRights(checkRoles?: Array<PeopleRoles>) {
    let roleAppointmentList = [];
    this.authenticationService.myprofile.Roles.forEach(x => {
      environment.ResourceTyped.SelectableRolesByRoleType[
        PeopleRoles[x]
      ].forEach(y => {
        roleAppointmentList.findIndex(f => {
          return f == y;
        }) == -1
          ? roleAppointmentList.push(y)
          : null;
      });
    });

    let r = roleAppointmentList.map(x => {
      return {
        key: x,
        item: PeopleRoles[x],
        checked: checkRoles && checkRoles.includes(x)
      };
    });

    console.log("roles rights is:", r);
    return r;
  }

  toggleRolesSwitch(
    event: MouseEvent,
    checkedItem: { key: number; item: string }
  ) {
    // (event.target as HTMLInputElement).checked = !(event.target as HTMLInputElement).checked;
    if ((event.target as HTMLInputElement).checked) {
      this.peopleFormControl.controls["Roles"].value.push(checkedItem.key);
    } else {
      let rolesReference = this.peopleFormControl.controls["Roles"].value;
      rolesReference.splice(rolesReference.indexOf(checkedItem.key), 1);
    }
  }

  onSubmit(event: MouseEvent) {
    if (this.peopleFormControl.valid) {
      this.progressBarService.start();

      let peopleFormValues = this.peopleFormControl.value as KgPeopleModel;

      this.addRolesByUnitOwnershipTypes(peopleFormValues, this.peopleUnitList);

      if (this.newPeopleMode != NewPeopleMode.EditExisting) {
        this.subscriptionSubmit = this.kgPeopleService
          .addNew(peopleFormValues)
          .pipe(
            switchMap((addedPeople: KgPeopleModel, index: number) => {
              let postUnitsObservables = this.generatePostUnitsObervables(
                addedPeople
              );
              let postProfilePhotosObservables = this.generatePostProfilePhotosObservables(
                addedPeople
              );
              let setSubmittedObservable = this.kgPeopleService.setPepleStatus(
                addedPeople.Id,
                PeopleStatuses.Submitted
              );
              let observablesSeries = concat(
                ...postUnitsObservables,
                ...postProfilePhotosObservables,
                setSubmittedObservable
              );

              return observablesSeries;
            })
          )
          .subscribe(
            event => {
              console.log(
                `${
                  NewPeopleMode[this.newPeopleMode]
                } people observablesSerie event`,
                event
              );
            },
            error => {
              console.log(
                `${NewPeopleMode[this.newPeopleMode]} people observablesSerie`,
                error
              );
              this.subscriptionSubmit
                ? this.subscriptionSubmit.unsubscribe()
                : null;
              this.progressBarService.stop();
            },
            () => {
              this.subscriptionSubmit
                ? this.subscriptionSubmit.unsubscribe()
                : null;
              this.progressBarService.stop();
              console.log(
                `${
                  NewPeopleMode[this.newPeopleMode]
                } people observablesSerie done`
              );
              this.navigateBack();
            }
          );
      } else {
        this.subscriptionSubmit = this.kgPeopleService
          .editPeople(peopleFormValues)
          .subscribe(response => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error("Error in forms submission" + response.status);
            } else {
              let unitListObservables = this.generatePostUnitsObervables(
                peopleFormValues
              );
              let photoListObservables = this.generatePostProfilePhotosObservables(
                peopleFormValues
              );
              let observablesSeries = concat(
                ...unitListObservables,
                ...photoListObservables
              );

              let subscription = observablesSeries.subscribe(
                event => {
                  console.log("edit people observablesSeries event : ", event);
                },
                error => {
                  console.error(
                    "edit people observablesSeries error : ",
                    error
                  );
                  subscription ? subscription.unsubscribe() : null;
                  this.progressBarService.stop();
                },
                () => {
                  subscription ? subscription.unsubscribe() : null;
                  this.progressBarService.stop();
                  console.log("edit people observablesSerie done");
                  this.navigateBack();
                }
              );
            }
          });
      }
    }
  }

  addRolesByUnitOwnershipTypes(
    person: KgPeopleModel,
    peopleUnitList: KgUnitModelExtended[]
  ) {
    //if cannot add roles then let UnitOwnershipTypes determine the roles
    person.Roles = !this.canAddRoles() ? [] : person.Roles;

    let allOwnershipTypes = peopleUnitList
      .map(f => {
        return f.Peoples.find(r => {
          return r.PeopleId == person.Id;
        });
      })
      .filter(y => {
        return y != null;
      })
      .map(m => {
        return m.OwnershipType;
      });

    allOwnershipTypes.forEach(ot => {
      switch (ot) {
        case KgOwnershipTypes.Owner:
          person.Roles.indexOf(PeopleRoles.Owner) == -1
            ? person.Roles.push(PeopleRoles.Owner)
            : null;
          break;
        case KgOwnershipTypes.MasterTenant:
          person.Roles.indexOf(PeopleRoles.MasterTenant) == -1
            ? person.Roles.push(PeopleRoles.MasterTenant)
            : null;
          break;
        case KgOwnershipTypes.Resident:
          person.Roles.indexOf(PeopleRoles.Resident) == -1
            ? person.Roles.push(PeopleRoles.Resident)
            : null;
          break;
        case KgOwnershipTypes.Tenant:
          person.Roles.indexOf(PeopleRoles.Tenant) == -1
            ? person.Roles.push(PeopleRoles.Tenant)
            : null;
          break;
      }
    });
  }

  getRolesOwnershipType(role: PeopleRoles) {
    switch (role) {
      case PeopleRoles.Owner:
        return KgOwnershipTypes.Owner;
      case PeopleRoles.MasterTenant:
        return KgOwnershipTypes.MasterTenant;
      case PeopleRoles.Resident:
        return KgOwnershipTypes.Resident;
      case PeopleRoles.Tenant:
        return KgOwnershipTypes.Tenant;
    }
  }

  onCancel(e: MouseEvent) {
    this.navigateBack();
  }

  navigateBack() {
    if (
      this.authenticationService.hasAccessRoles([
        PeopleRoles.Owner,
        PeopleRoles.MasterTenant
      ])
    ) {
      this.router.navigate(["/units/myunits"]);
    } else {
      this.router.navigate(["/peoples"]);
    }
  }

  changecommunity(e: MouseEvent) {
    this.selectableCommunitiesInput[0].CommunitiesName = new Date().toTimeString();
  }

  ShowDialog(e: MouseEvent) {
    this.selectCommunityDialog.show();
  }

  onSelectedCommunity(selectedItem: KgCommunitiesModel) {
    this.peopleFormControl.controls["CommunitiesId"].setValue(selectedItem.Id);
    this.initSelectableAccessCardTypes();
  }

  onCancelClickedCommunity(e: MouseEvent) {
    console.log(`cancel being clicked, do nothing ${e.clientX}, ${e.clientY}`);
  }

  showuploaddialog() {
    this.myUploadProfileDialog.show();
  }

  showinconsole() {
    console.log(this.peopleFormControl.controls["FirstName"]);

    let p = new KgPeopleModel(this.peopleFormControl.value);
    console.log(p);
  }

  peopleModelHasRole(role) {
    let istrue = this.peopleModel.Roles
      ? this.peopleModel.Roles.indexOf(role.key) != -1
      : false;
    return istrue;
  }

  getCommunityNameFromId(id: string) {
    if (id != "" && this.selectableCommunitiesInput) {
      return id != ""
        ? this.selectableCommunitiesInput.find(x => {
            return x.Id == id;
          }).CommunitiesName
        : "Click to choose Community";
    }
  }

  onDoneCroppedPhoto(result: KgPhotoProperties[]): any {
    this.myUploadProfileDialog.close();
    if (this.allSelectedPhotos.length == 0) {
      result.forEach(x => {
        x.isprimary = true;
      });
    }
    this.allSelectedPhotos.push(result);
  }

  getCropped128Photo(extractThis: KgPhotoProperties[]) {
    return extractThis.find(x => {
      return x.size == PhotoSizeTypes.Icon128px;
    });
  }

  getPhoto(extractThis: KgPhotoProperties[], size: PhotoSizeTypes) {
    return extractThis.find(x => {
      return x.size == size;
    });
  }

  getProfilePhoto128Uri(photoItem: ProfilePhotosModel) {
    return photoItem.PhotoSizeType == PhotoSizeTypes.Icon128px;
  }
  testit() {}

  hasPrimaryProfilePhoto(profilePhotoList: KgPhotoProperties[][]) {
    return profilePhotoList.find(photosGroup => {
      return photosGroup.find(photo => {
        return photo.isprimary ? true : false;
      })
        ? true
        : false;
    })
      ? true
      : false;
  }

  SetPrimaryProfilePhoto(selectedPhotoBlobs: KgPhotoProperties[][]) {
    //always set first generated profile list blob to be primary
    this.allSelectedPhotos[0].forEach(x => {
      x.isprimary = true;
    });
  }

  generatePostUnitsObervables(person: KgPeopleModel) {
    let postUnitsObservables = [];
    if (
      this.authenticationService.hasAccessRoles([
        PeopleRoles.Administrator,
        PeopleRoles.Chairperson,
        PeopleRoles.Manager,
        PeopleRoles.Clerk
      ])
    ) {
      console.log(this.peopleUnitList);
      this.progressBarService.start();

      let newList = this.peopleUnitList.filter(x => {
        return x.ItemState == ModelStates.New;
      });
      let editedList = this.peopleUnitList.filter(x => {
        return x.ItemState == ModelStates.Edited;
      });
      let deleteList = this.peopleUnitList.filter(x => {
        return x.ItemState == ModelStates.Deleted;
      });

      if (newList.length > 0) {
        newList.forEach(u => {
          let foundPersonModel = this.findNewOrExistingPersonInUnit(u, person);
          foundPersonModel.PeopleId = person.Id;
        });

        postUnitsObservables.push(this.unitService.addNewUnits(newList));
      }

      if (editedList.length > 0) {
        editedList.forEach(x => {
          let foundUnitPeople = x.Peoples.find(y => {
            return y.PeopleId == person.Id;
          });
          postUnitsObservables.push(
            this.unitService.changeOwnershipType(
              foundUnitPeople.PeopleId,
              x.UnitNumber,
              foundUnitPeople.OwnershipType
            )
          );
        });
      }

      if (deleteList.length > 0) {
        deleteList.forEach(x => {
          let foundUnitPeople = x.Peoples.find(y => {
            return y.PeopleId == person.Id;
          });
          postUnitsObservables.push(
            this.unitService.removeUnitPeople(
              foundUnitPeople.PeopleId,
              x.UnitNumber
            )
          );
        });
      }
    } else {
      this.peopleUnitList.forEach(u => {
        if (u.ItemState != ModelStates.Pristine) {
          let foundPersonModel = this.findNewOrExistingPersonInUnit(u, person); // this.newPeopleMode == NewPeopleMode.CreateNew ? u.Peoples.find(x => { return x.PeopleId == "" }) : u.Peoples.find(x => { return x.PeopleId == person.Id });
          foundPersonModel.PeopleId = person.Id;

          switch (u.ItemState) {
            case ModelStates.New:
              postUnitsObservables.push(
                this.unitService.addNewUnitPeople(
                  foundPersonModel,
                  u.UnitNumber
                )
              );
              break;
            case ModelStates.Edited:
              postUnitsObservables.push(
                this.unitService.changeOwnershipType(
                  foundPersonModel.PeopleId,
                  u.UnitNumber,
                  foundPersonModel.OwnershipType
                )
              );
              break;
            case ModelStates.Deleted:
              postUnitsObservables.push(
                this.unitService.removeUnitPeople(
                  foundPersonModel.PeopleId,
                  u.UnitNumber
                )
              );
              break;
            default:
              console.log("do nothing for Pristine State");
          }
        }
      });
    }
    return postUnitsObservables;
  }

  findNewOrExistingPersonInUnit(
    peopleUnit: KgUnitModelExtended,
    findPerson: KgPeopleModel
  ) {
    switch (this.newPeopleMode) {
      case NewPeopleMode.CreateNew:
      case NewPeopleMode.NewMasterTenant:
      case NewPeopleMode.NewResident:
      case NewPeopleMode.NewTenant:
        return peopleUnit.Peoples.find(x => {
          return x.PeopleId == "";
        });
      case NewPeopleMode.EditExisting:
        return peopleUnit.Peoples.find(x => {
          return x.PeopleId == findPerson.Id;
        });
    }
  }

  generatePostProfilePhotosObservables(person: KgPeopleModel) {
    if (this.allSelectedPhotos.length > 0) {
      if (!this.hasPrimaryProfilePhoto(this.allSelectedPhotos)) {
        this.SetPrimaryProfilePhoto(this.allSelectedPhotos);
      }

      let newOnlyPhotoGroups = this.allSelectedPhotos.filter(photoSizeGroup => {
        if (photoSizeGroup[0].status == PhotoBlobStatuses.New) {
          return true;
        } else {
          return false;
        }
      });

      let deleteExistingPhotoGroups = this.allSelectedPhotos.filter(
        photoSizeGroup => {
          if (photoSizeGroup[0].status == PhotoBlobStatuses.DeletedExisting) {
            return true;
          } else {
            return false;
          }
        }
      );

      let photoUploadListObservable = newOnlyPhotoGroups.map(photoSizeGroup => {
        let photosFormData = new FormData();
        let photoUuid: string;
        photoSizeGroup.forEach(size => {
          if (size.blob) {
            photosFormData.append("photos", size.blob, size.name);
            photoUuid = size.photouuid;
          }
        });
        return this.kgPeopleService.uploadProfilePhoto(
          person.Id,
          photoUuid,
          photoSizeGroup[0].isprimary,
          photosFormData
        );
      });

      let photoDeleteListObservable = deleteExistingPhotoGroups.map(
        photoSizeGroup => {
          let photosFormData = new FormData();
          let photoUuid: string;

          let fileNamesToDelete = photoSizeGroup.map(x => {
            return x.name;
          });

          return this.kgPeopleService.deleteProfilePhoto(
            fileNamesToDelete,
            person.Id,
            photoSizeGroup[0].photouuid
          );
        }
      );

      let foundPrimaryPhotos = this.allSelectedPhotos.find(x => {
        let found = x.find(y => {
          return y.isprimary;
        });
        return found ? true : false;
      });

      foundPrimaryPhotos = foundPrimaryPhotos
        ? foundPrimaryPhotos
        : this.allSelectedPhotos[0];
      let setPrimaryObservable = this.kgPeopleService.setPrimaryPhoto(
        person.Id,
        foundPrimaryPhotos[0].photouuid
      );

      let profilePhotoUploadObserables = [
        ...photoUploadListObservable,
        ...photoDeleteListObservable,
        setPrimaryObservable
      ];

      return profilePhotoUploadObserables;
    } else {
      console.log("no profile photo to process");
      return [];
    }
  }

  onCancelUpload(arg0: any): any {
    this.myUploadProfileDialog.close();
  }

  uploadProfilePhoto(e: MouseEvent) {
    this.myUploadProfileDialog.show();
  }

  toggleDeletePhoto(
    e: MouseEvent,
    items: KgPhotoProperties[],
    index: number,
    isNew: boolean
  ) {
    items.forEach(x => {
      x.status =
        x.status == PhotoBlobStatuses.New
          ? PhotoBlobStatuses.DeletedNew
          : PhotoBlobStatuses.DeletedExisting;
    });

    let el = this.photocells.find((x, i) => {
      return i == index;
    }).nativeElement as Element;

    let fav = this.favTogglers.find((x, i) => {
      return i == index;
    }).nativeElement as Element;

    let del = this.deleteTogglers.find((x, i) => {
      return i == index;
    }).nativeElement as Element;

    el.classList.add("cell_deleted");

    // let d = new MDCIconToggle(del);
    // d.disabled = true;
    // d.destroy();
    // let f = new MDCIconToggle(fav);
    // f.disabled = true;
    // f.destroy();

    console.log(this.allSelectedPhotos);
  }

  toggleMakePrimaryPhoto(
    e: MouseEvent,
    item: KgPhotoProperties[],
    index: number,
    isNew: boolean
  ) {
    // this.favTogglers.forEach((x, i) => {
    //   let t = new MDCIconToggle(x.nativeElement);
    //   t.on = i == index ? true : false;
    //   t.destroy();
    // });

    this.allSelectedPhotos.forEach(x => {
      x.forEach(y => {
        y.isprimary = false;
      });
    });

    item.forEach(x => {
      x.isprimary = true;
    });

    console.log(this.allSelectedPhotos);
  }

  shoudToggleFav(items: KgPhotoProperties[]) {
    return items[0].isprimary ? "favorite" : "favorite_border";
  }

  getStepperPrimarySize() {
    if (this.firstStepperOffsetTop == 0) {
      console.log("steppers", this.steppers);
      this.firstStepperOffsetTop = (this.steppers.first
        .nativeElement as HTMLElement).offsetTop;
      this.firstStepperOffsetHeight = (this.steppers.toArray()[2]
        .nativeElement as HTMLElement).offsetHeight;
      console.log(
        "firstStepperOffsetTop/firstStepperOffsetHeight",
        this.firstStepperOffsetTop,
        this.firstStepperOffsetHeight
      );
    }
  }

  showStepperItem(e: MouseEvent, step: number) {
    if (
      (e.target as HTMLElement).classList.contains(
        "_mdc-stepper__step__title"
      ) ||
      (e.target as HTMLElement).classList.contains(
        "_mdc-stepper__step__label"
      ) ||
      (e.target as HTMLElement).innerText == "CONTINUE"
    ) {
      if (this.formStep == step) {
        this.formStep = 0;
      } else {
        this.formStep = step > 4 && !this.canAddAccessCard() ? 7 : step;
      }

      let scrollToY = this.firstStepperOffsetTop + 57 * (step - 2);
      window.scrollTo(0, scrollToY);
    }

    if ((e.target as HTMLElement).classList.contains("mdc-button")) {
      e.stopPropagation();
      let scrollToY = this.firstStepperOffsetTop + 57 * (step - 2);
      window.scrollTo(0, scrollToY);
    }
  }

  isActiveStep(step: number) {
    return step == this.formStep ? "_mdc-stepper__step--active" : null;
  }

  canAddAccessCard() {
    let canAdd = this.authenticationService.hasAccessRoles([
      PeopleRoles.Administrator,
      PeopleRoles.Manager,
      PeopleRoles.Clerk,
      PeopleRoles.Chairperson
    ]);
    return canAdd;
  }

  canAddRoles() {
    return this.authenticationService.hasAccessRoles([
      PeopleRoles.Administrator,
      PeopleRoles.Manager,
      PeopleRoles.Clerk,
      PeopleRoles.Chairperson
    ]);
  }

  hasFullPeopleCreationAccess() {
    return this.authenticationService.hasAccessRoles([
      PeopleRoles.Administrator,
      PeopleRoles.Manager,
      PeopleRoles.Clerk,
      PeopleRoles.Chairperson
    ]);
  }

  roleClicked(emit: MDCCheckboxEmitModel) {
    let roles = this.peopleFormControl.controls["Roles"].value as Array<Number>;
    emit.mdc.checked
      ? roles.push(+emit.key)
      : (roles = roles.filter(x => {
          return x != +emit.key;
        }));

    console.log("pushed result: ", roles);

    this.peopleFormControl.controls["Roles"].setValue(
      roles.sort((a, b) => {
        return KgArray.SortAsc(a, b);
      })
    );
    console.log(
      "completed roles in/out: ",
      this.peopleFormControl.controls["Roles"].value
    );
  }
}
