import { CommunitiesConfiguration } from "./kg-communities-configuration";
import {
  CommunitiesTypes,
  CommunitiesStatuses
} from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";

export class KgCommunitiesModel extends KgBaseModel {
  CommunitiesType: CommunitiesTypes;
  CommunitiesName: string;
  Description: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Postcode: string;
  City: string;
  State: string;
  Country: string;
  CommunitiesStatus: CommunitiesStatuses;
  CommunitiesConfig: CommunitiesConfiguration;

  constructor(communitiesModel?: KgCommunitiesModel) {
    super(communitiesModel);
    if (communitiesModel != null) {
      Object.assign(this, communitiesModel);
    } else {
      Object.assign(this, {
        CommunitiesType: 0,
        CommunitiesName: "",
        Description: "",
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        Postcode: "",
        City: "",
        State: "",
        Country: "",
        CommunitiesStatus: 0,
        CommunitiesConfig: new CommunitiesConfiguration()
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return
    return Object.assign({}, this, {
      // convert fields that need converting
      CreateDate: this.CreateDate.toISOString(),
      ActionDate: this.ActionDate.toISOString()
    });
  }
}
