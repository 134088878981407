import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { KgSnackbarService } from "../../../services/kg-snackbar/kg-snackbar.service";
import { KgPeopleService } from "../../../services/kg-people";
import { KgProgressBarService } from "../../../services/kg-progress-bar/kg-progress-bar.service";
import { KgAccountsService } from "../../../services/kg-accounts/kg-accounts.service";

@Component({
  selector: "app-kg-forget-password",
  templateUrl: "./kg-forget-password.component.html",
  styleUrls: ["./kg-forget-password.component.scss"]
})
export class KgForgetPasswordComponent implements OnInit {
  resetPasswordFormGroup: FormGroup;
  validationMessages = environment.ResourceTyped.ValidationMessages.Peoples;
  constructor(
    private fb: FormBuilder,
    private snackbarService: KgSnackbarService,
    private peopleService: KgPeopleService,
    private accountsService: KgAccountsService,
    private progressBar: KgProgressBarService,
    private _location: Location,
    private router: Router
  ) {
    this.resetPasswordFormGroup = this.fb.group({
      Email: ["", [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {}

  onCancel(e: MouseEvent) {
    this._location.back();
  }

  onSubmit(e: MouseEvent) {
    this.progressBar.start();
    this.accountsService
      .sendResetPasswordEmail(
        this.resetPasswordFormGroup.controls["Email"].value
      )
      .subscribe(
        x => {
          console.log(x);
        },
        (error: ErrorEvent) => {
          this.snackbarService.show({
            labelText: "Failed to send reset password email, please try again",
            actionButtonText: "Dismiss",
            timeoutMs: 5000
          });
          this.progressBar.stop();
        },
        () => {
          this.progressBar.stop();
          this.snackbarService.show({
            labelText:
              "Reset email has been, you'll receive an email from us in your Inbox",
            actionButtonText: "Dismiss",

            timeoutMs: 5000,
            onClosed: () => {}
          });
        }
      );
  }
}
