import { Injectable } from "@angular/core";
import * as UAParser from "ua-parser-js";

@Injectable()
export class KGUaService {
  UA: IUAParser.IResult;
  constructor() {
    this.UA = this.Parse(window.navigator.userAgent);
    // console.log("parsed useragent", this.UA);
  }

  Parse(uaString: string) {
    let uap = new UAParser(uaString);
    return uap.getResult();
  }

  hasAnyBrowser(browserNames: string[]) {
    return (
      browserNames.findIndex(x => {
        return x == this.UA.browser.name;
      }) > -1
    );
  }

  isMobile() {
    return this.UA.device.type == "mobile";
  }

  isTablet() {
    return this.UA.device.type == "tablet";
  }
}
