import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class KgDrawerService {
  // Observable string sources
  private drawerSubject = new Subject<boolean>();

  // Observable string streams
  drawer$ = this.drawerSubject.asObservable();

  constructor(private _location: Location) {}

  // Service message commands
  showDrawer() {
    console.log("show drawer next");
    this.drawerSubject.next(true);
  }

  hideDrawer() {
    this.drawerSubject.next(false);
  }
}
