import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
  Type,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  ComponentRef
} from "@angular/core";
import { MDCDialog } from "@material/dialog";
import { KgComponentMaterialsCard2Directive } from "./kg-component-materials-card2.directive";
import { IBaseDynamicComponent } from "src/app/framework/model/IBaseDynamicComponent";

@Component({
  selector: "kg-component-materials-card2",
  templateUrl: "./kg-component-materials-card2.component.html",
  styleUrls: ["./kg-component-materials-card2.component.scss"]
})
export class KgComponentMaterialsCard2Component
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  mdcDialog: MDCDialog;
  @Input() kgid: string;
  @Input() kgtitle: string;

  @Input() renderComponent: Type<any>;
  @Input() kginput: any;
  @Input() kgoutput: any;
  // kginjector: Injector;
  @ViewChild(KgComponentMaterialsCard2Directive)
  renderer: KgComponentMaterialsCard2Directive;
  componentRef: ComponentRef<IBaseDynamicComponent<any, any>>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.renderComponent ? this.loadComponent() : null;
  }

  ngAfterViewInit() {
    // this.mdcDialog = new MDCDialog(document.querySelector(`#mdc-card2-${this.kgid}`));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef != null && this.componentRef != undefined) {
      console.log(changes);
      for (let propName in changes) {
        if (propName == "kginput") {
          let chng = changes[propName].currentValue;
          console.log(chng);
          this.componentRef.instance.componentInputData = chng;
        }
        if (propName == "kgoutput") {
          this.componentRef.instance.componentOutput =
            changes[propName].currentValue;
        }
      }
    }
  }

  ngOnDestroy() {}

  loadComponent() {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.renderComponent
    );
    let viewContainerRef = this.renderer.viewContainerRef;

    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(
      componentFactory,
      null,
      this._viewContainerRef.parentInjector
    );
    this.componentRef.instance.componentInputData = this.kginput;
    this.componentRef.instance.componentOutput = this.kgoutput;
    // this.componentRef.instance.parentInjector = this._viewContainerRef.parentInjector;
  }

  show() {
    this.mdcDialog.open();
  }
}
