import { Component, OnInit, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { PeopleRoles } from "../../model/kg-core";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";
import { KgPeopleService } from "src/app/services/kg-people";
import { KgPeopleModel } from "src/app/model/kg-people";
import { KgComponentMaterialsTabItemModel } from "src/app/modules/libs/kg-component-materials/kg-component-materials-tab/kg-component-materials-tab.model";
import { KgFirebaseMessagingService } from "src/app/services/kg-firebase/kg-firebase-messaging.service";
import { HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Subscription, Observable, throwError as _throw } from "rxjs";
import {
  KgAuthenticationService,
  SignInResponse
} from "src/app/services/kg-authentication/kg-authentication.service";
import { KGUaService } from "src/app/framework/services/ua.service";
import { switchMap, concat, filter, catchError } from "rxjs/operators";
import { KgSnackbarService } from "../../services/kg-snackbar/kg-snackbar.service";
import { KgValidators } from "../../modules/core/kg-validators";
import { KgCommunitiesService } from "../../services/kg-communities";
import { KgCommunitiesModel } from "../../model";

@Component({
  moduleId: module.id,
  selector: "kg-login",
  templateUrl: "kg-login.component.html",
  styleUrls: ["kg-login.component.scss"]
})

// single intend for showing table based on supplied tableSetting and tableData
// it uses Material Design Lite instead of material-component-web
// because table is not available in material-component-web
export class KgLoginComponent implements OnInit {
  model: any = {};
  returnUrl: string;
  subscription: Subscription;
  isExpiredAt: Date;
  mobileNumberSignInForm: FormGroup;
  emailSignInForm: FormGroup;
  requestedCode = false;
  peopleProfile: KgPeopleModel;
  isDebug = !environment.production;
  disableRequestButton = false;
  validationMessages = environment.ResourceTyped.ValidationMessages.Signin;
  doTabClick = new EventEmitter<KgComponentMaterialsTabItemModel>();
  isMobileSignIn = true;
  loginTabSetting: KgComponentMaterialsTabItemModel[] = [
    {
      label: "Use Mobile",
      path: "#signinMobile",
      onTabItemClick: this.doTabClick
    },
    {
      label: "Use Email",
      path: "#signinEmail",
      onTabItemClick: this.doTabClick
    }
  ];
  isSuccesss = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private kgAuthenticationService: KgAuthenticationService,
    private peopleService: KgPeopleService,
    private communitiesService: KgCommunitiesService,
    private fb: FormBuilder,
    private progressBar: KgProgressBarService,
    private messagingService: KgFirebaseMessagingService,
    private uaService: KGUaService,
    private snackbarService: KgSnackbarService
  ) {
    this.subscription = this.kgAuthenticationService.authentication$.subscribe(
      () => {
        console.log("Received authenticated announcement");
      }
    );

    this.doTabClick.subscribe(x => {
      switch (x.label) {
        case "Use Email":
          this.isMobileSignIn = false;
          break;
        case "Use Mobile":
          this.isMobileSignIn = true;
          break;
      }
    });
    console.log(environment.production);
  }

  getPeopleRolesName(i: number) {
    return PeopleRoles[i];
  }

  ngOnInit() {
    this.route.data.subscribe(x => {
      if (x["signout"]) {
        this.logout();
      } else if (this.kgAuthenticationService.hasSession) {
        this.loginSucessNavigation();
      }

      this.initForm();
    });
  }

  initForm() {
    if (this.kgAuthenticationService.hasSession) {
      // this.peopleSession = this.kgAuthenticationService.currentSession;
      this.isExpiredAt = null;
    } else {
      // this.kgAuthenticationService.isExpired() ? this.isExpiredAt = new Date(this.kgAuthenticationService.currentSession.peopleToken.Expiry * 1000) : null;
    }

    this.mobileNumberSignInForm = this.fb.group({
      MobileNumber: ["", KgValidators.isValidMalaysiaMobileNumber()],
      MobileNumberCountryCode: ["MY"],
      AuthenticationCode: [""]
    });

    this.emailSignInForm = this.fb.group({
      Email: [environment.ResourceTyped.admin.email, Validators.required],
      Password: [environment.ResourceTyped.admin.password, Validators.required]
    });
  }

  logout() {
    this.initForm();
    this.messagingService.getToken().then(x => {
      this.peopleService
        .deleteNotificationToken({
          DateAdded: new Date(),
          IsActive: true,
          Token: x,
          UserAgent: this.uaService.UA.ua
        })
        .subscribe(
          async () => {
            await this.messagingService.deleteToken();
            localStorage.setItem("fcmtokensaved", "0");
          },
          () => {
            this.kgAuthenticationService.logout();
          },
          () => {
            this.kgAuthenticationService.logout();
          }
        );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.isSuccesss = false;
  }

  onSignIn() {
    this.isMobileSignIn ? this.mobileSignIn() : this.emailSignIn();
  }

  signIn(signInMethod: Observable<any>) {
    this.progressBar.start();
    signInMethod
      .pipe(
        filter(event => event.PrimaryToken != null),
        switchMap((response: SignInResponse) => {
          if (response && response.PrimaryToken) {
            this.kgAuthenticationService.storeSession(response);
            return this.peopleService.getMyProfile();
          }
        }),
        concat(this.communitiesService.getMyCommunitiesDetails()),
        catchError(error => {
          return _throw("Email Authentication failed", error);
        })
      )
      .subscribe(
        detailsResponse => {
          if (detailsResponse instanceof KgPeopleModel) {
            this.kgAuthenticationService.storeProfile(detailsResponse);
          }

          if (detailsResponse instanceof KgCommunitiesModel) {
            this.communitiesService.storeLocalCommunitiesDetails(
              detailsResponse
            );
          }
        },
        error => {
          console.log("attempt to sign in failed with error:", error);
          this.snackbarService.show({
            labelText:
              "Wrong password or email. Try again or click Forgot password to reset it",
            actionButtonText: "Dismiss",
            timeoutMs: 10000
          });
          this.progressBar.stop();
        },
        () => {
          this.progressBar.stop();
          this.loginSucessNavigation();
          this.kgAuthenticationService.announceUserAuthenticated(true);
          this.progressBar.stop();
          this.isSuccesss = true;
          console.log("login completed");
        }
      );
  }

  emailSignIn() {
    if (this.emailSignInForm.valid) {
      let email = this.emailSignInForm.value.Email;
      let password = this.emailSignInForm.value.Password;
      this.signIn(this.kgAuthenticationService.emailSignIn(email, password));
    }
  }

  mobileSignIn() {
    if (this.mobileNumberSignInForm.valid) {
      let mobileNumber = this.mobileNumberSignInForm.controls["MobileNumber"]
        .value;
      let authenticationCode = this.mobileNumberSignInForm.controls[
        "AuthenticationCode"
      ].value;
      this.signIn(
        this.kgAuthenticationService.mobileAuthentication(
          mobileNumber,
          authenticationCode
        )
      );
    }
  }

  onClear() {
    this.mobileNumberSignInForm.reset();
    this.disableRequestButton = false;
    this.requestedCode = false;
  }

  loginSucessNavigation() {
    if (
      this.uaService.hasAnyBrowser(
        environment.ResourceTyped.FCMSupportedBrowser
      )
    ) {
      console.log("FCM Supported");
      if ((Notification as any).permission != "granted") {
        this.router.navigate(["/setup"]);
      } else {
        this.messagingService.getToken().then(token => {
          this.peopleService
            .saveNotificationToken({
              DateAdded: new Date(),
              IsActive: true,
              Token: token,
              UserAgent: this.uaService.UA.ua
            })
            .subscribe(x => {
              if (x instanceof HttpResponse) {
                localStorage.setItem("fcmtokensaved", "1");
                console.log("token saved to server");
              }
            });
          if (
            this.kgAuthenticationService.hasAccessRole(
              PeopleRoles.SecurityGuard
            )
          ) {
            this.router.navigate(["/security"]);
          } else {
            this.router.navigate(["/dashboard"]);
          }
        });
      }
    } else {
      if (
        this.kgAuthenticationService.hasAccessRole(PeopleRoles.SecurityGuard)
      ) {
        this.router.navigate(["/security"]);
      } else {
        this.router.navigate(["/dashboard"]);
      }
    }
  }

  onRequestAuthenticationCode() {
    this.disableRequestButton = true;
    console.log("requested authentication code");
    this.progressBar.start();
    this.kgAuthenticationService
      .authenticationCodeRequest(
        this.mobileNumberSignInForm.controls["MobileNumber"].value
      )
      .subscribe(
        response => {
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          this.progressBar.stop();
          console.log(error);
          this.disableRequestButton = false;

          error.status == 404
            ? this.snackbarService.show({
                labelText: "Invalid Mobile Number",
                actionButtonText: "Dismiss"
              })
            : null;
        },
        () => {
          this.requestedCode = true;
          this.progressBar.stop();
          console.log("request successful");
        }
      );
  }

  onForgotPassword() {
    this.router.navigate(["/accounts/forgotpassword"]);
  }
}
