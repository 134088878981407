import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { DexieDbService } from "src/app/framework/services/dexiedb.service";
import {
  IKgLog,
  CategoryTypes,
  KgLog
} from "src/app/framework/model/kg-log.model";

@Injectable()
export class KgLoggingService {
  constructor(private dexiedb: DexieDbService) {}

  getAllLog(): Observable<IKgLog[]> {
    return this.dexiedb.get(10, 1);
  }

  getLogByPage(pageSize: number, pageNumber: number) {
    return this.dexiedb.get(pageSize, pageNumber);
  }

  debug(from: string, log: any) {
    this.log(CategoryTypes.Debug, from, log);
  }

  warning(from: string, log: any) {
    this.log(CategoryTypes.Warning, from, log);
  }

  error(from: string, err: any) {
    this.log(CategoryTypes.Error, from, err);
  }

  information(from: string, log: any) {
    this.log(CategoryTypes.Information, from, log);
  }

  exception(from: string, log: any) {
    log = new Error().stack + "======" + JSON.stringify(log);
    this.log(CategoryTypes.Exception, from, log);
  }

  async log(categoryType: CategoryTypes, from: string, log: any) {
    await this.dexiedb.put(
      new KgLog(
        categoryType,
        from,
        JSON.stringify(log, function(key, value) {
          if (key == "parent") {
            return value.id;
          } else {
            return value;
          }
        })
      )
    );
  }
}
