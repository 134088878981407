export interface IKgLog {
  id?: number;
  datetime: Date;
  category: CategoryTypes;
  from: string;
  log: string;
}

export enum CategoryTypes {
  Information,
  Debug,
  Warning,
  Error,
  Exception
}

export class KgLog implements IKgLog {
  id: number;
  datetime: Date = new Date();
  category: CategoryTypes;
  from: string;
  log: string;

  constructor(
    categoryType: CategoryTypes,
    from: string,
    log: string,
    id?: number
  ) {
    this.category = categoryType;
    this.datetime = new Date();
    this.from = from;
    this.log = log;
    if (id) this.id = id;
  }
}
