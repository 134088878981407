import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgProfilePhotoFindPipe } from "./kg-profile-photo-find.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [KgProfilePhotoFindPipe],
  exports: [KgProfilePhotoFindPipe]
})
export class KgPeoplePipesModule {}
