import { Injectable } from "@angular/core";
import { KgManagementsModel } from "../../model/kg-managements/kg-managements.model";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs/Observable";
import { HttpClient } from "@angular/common/http";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { catchError, map } from "rxjs/operators";
import { KgReviver } from "../../framework/model/kg-reviver";
import { _throw } from "../../../../node_modules/rxjs/observable/throw";

@Injectable()
export class KgManagementsService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  getManagementTotalCount() {
    return this.httpClient
      .get<number>(environment.ApiHost + "api/managements/totalcount", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAll(page: number) {
    return this.httpClient
      .get(environment.ApiHost + "api/managements/pages/" + page, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  GetLimitedActiveManagement(maxNumber: number) {
    return this.httpClient
      .get(
        environment.ApiHost +
          "api/managements/dashboard/active/max/" +
          maxNumber,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getById(id: string) {
    return this.httpClient
      .get<KgManagementsModel>(environment.ApiHost + `api/managements/${id}`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(x => KgReviver.Revive(x, KgManagementsModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getManagementsByPage(page: number): Observable<KgManagementsModel[]> {
    return this.httpClient
      .get(`${environment.ApiHost}api/managements/pages/8/${page}`, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(this.extractData),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getByName(name: string) {
    return this.httpClient
      .get(environment.ApiHost + "api/managements/name/" + name, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addNew(managements: KgManagementsModel) {
    return this.httpClient
      .post(environment.ApiHost + "api/managements/addnew", managements, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  edit(managements: KgManagementsModel) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/managements/editmanagements",
        managements,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  private extractData(collection: KgManagementsModel[]): KgManagementsModel[] {
    if (collection != null) {
      return collection.map(x => {
        let newMan = new KgManagementsModel(x);
        return newMan;
      });
    }
  }
}
