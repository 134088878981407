import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { KgComponentMaterialsCardButtonModel } from "../../../../modules/libs/kg-component-materials";

@Component({
  moduleId: module.id,
  selector: "kg-component-materials-card",
  templateUrl: "./kg-component-materials-card.component.html",
  styleUrls: ["./kg-component-materials-card.component.scss"]
})
export class KgComponentMaterialsCardComponent
  implements OnInit, AfterViewInit {
  constructor() {}

  @Input() kgid: string;
  @Input() kgheader: string;
  @Input() kgcontent: string;
  @Input() buttons: KgComponentMaterialsCardButtonModel[];
  @Output() kgcardclick = new EventEmitter<MouseEvent>();

  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  clicked(event: any) {
    console.log("kgcardclicked");
    this.kgcardclick.emit(event);
  }

  emit(b: EventEmitter<boolean>) {
    b.emit(true);
  }

  hov(e: MouseEvent) {
    (e.target as HTMLElement).className = (e.target as HTMLElement).className
      .replace("mdc-elevation--z6", "")
      .trim();
    (e.target as HTMLElement).className =
      (e.target as HTMLElement).className + " mdc-elevation--z6";
  }

  leave(e: MouseEvent) {
    (e.target as HTMLElement).className = (e.target as HTMLElement).className
      .replace("mdc-elevation--z6", "")
      .trim();
  }
}
