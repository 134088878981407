import { PeopleRoles } from "../../model/kg-core";

export class KgDrawerNavigationModel {
  // material icon id
  MaterialIconId: string;
  Title: string;
  CssClass: string = "mdc-list-item";
  RouterLink: string;
  // RouterLinkActive: boolean;
  RequiredRoles: PeopleRoles[];

  constructor(
    title?: string,
    routerLink?: string,
    materialIconId?: string,
    // routerLinkActive?: boolean,
    requiredRoles?: PeopleRoles[]
  ) {
    this.MaterialIconId = materialIconId;
    this.Title = title;
    this.RouterLink = routerLink;
    // this.RouterLinkActive = routerLinkActive;
    this.RequiredRoles = requiredRoles;
  }
}
