import { ProfilePhotosModel } from "src/app/model/kg-people";

export class KgPeopleRespondentModel {
  FirstName: string;
  LastName: string;
  PeopleId: string;
  ProfilePhotos: ProfilePhotosModel[];

  constructor(peopleRespondent?: KgPeopleRespondentModel) {
    if (peopleRespondent == null) {
      return Object.assign(this, peopleRespondent, {
        ProfilePhotos: []
      });
    } else {
      return Object.assign(this, peopleRespondent);
    }
  }
}
