import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { OnChanges } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { ClipboardHelper } from "src/app/framework/utilities/clipboard";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { KgSnackbarService } from "src/app/services/kg-snackbar/kg-snackbar.service";
import { Colors } from "kg-lib-models";

export interface KgChipSettings {
  Title: string;
  Color?: string | Colors;
  MdcIcon?: string;
}

@Component({
  selector: "kg-component-chips",
  templateUrl: "./kg-component-chips.component.html",
  styleUrls: ["./kg-component-chips.component.scss"]
})
export class KgComponentChipsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("chiptitle") titleElement: ElementRef;
  @Input() copyable = false;
  @Input() snackbarservice: KgSnackbarService;
  cursorstyle: string;
  ngOnDestroy(): void {
    console.log("component being destryed");
  }
  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop == "copyable") {
        this.cursorstyle = changes[prop].currentValue ? "pointer" : "auto";
      }
    }
    // console.log(changes, changes.kgchips.currentValue === changes.kgchips.previousValue);
  }
  colors = {};
  @Input() kgchips: KgChipSettings[];
  constructor() {}

  ngOnInit() {}

  copyTitle(e: MouseEvent) {
    if (this.copyable) {
      e.preventDefault();
      let t = e.target as HTMLElement;
      ClipboardHelper.copy(t.parentElement.querySelector(
        ".kgchiplabel"
      ) as HTMLElement);
      this.snackbarservice.show({
        labelText: `Copied ${
          t.parentElement.querySelector(".kgchiplabel").innerHTML
        } to clipboard`,
        actionButtonText: "dismiss",
        closeOnEscape: true
      });
    }
  }
}
