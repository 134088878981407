import { EventEmitter, Type, TemplateRef } from "@angular/core";
import { IBaseRenderDetailsComponent } from "src/app/framework/model/IBaseRenderDetailsComponent";
export class MenuItemModel<T> {
  Title: string;
  Icon?: string;
  OnClick: EventEmitter<T>;
}

export enum KgMoreInfoTypes {
  Expanding,
  Dialog2
}

// export enum KgDatalistDisplayMode{
//   Default=0,
//   NoOnClickIcon=1,
//   No
// }

export type KgFieldFunc<T> = (item: T) => string;
export enum IconModuleTypes {
  Image,
  Mdc,
  IcoMoon
}

export interface IIconField<T> {
  Icon: string | KgFieldFunc<T>;
  Color?: string | KgFieldFunc<T>;
  ModuleType: IconModuleTypes;
}
// export class KgComponentMaterialsDataListSettingModel<T> {
//   PrimaryTitleField?: string | KgFieldFunc<T>;
//   SecondaryTitleField?: string | KgFieldFunc<T>;
//   IconField?: string | KgFieldFunc<T>;
//   MdcIconField?: string | KgFieldFunc<T>;
//   OnItemClick?: EventEmitter<T>;
//   MenuItems?: MenuItemModel<T>[];
//   RenderComponent?: Type<IBaseRenderDetailsComponent<any, any>>;
// }

export class KgComponentMaterialsDataListSettingModel<T> {
  PrimaryTitleField?: string | KgFieldFunc<T>;
  SecondaryTitleField?: string | KgFieldFunc<T>;
  IconField?: IIconField<T>;
  OnItemClick?: EventEmitter<T>;
  HideItemClickIcon?: boolean = true;
  MenuItems?: MenuItemModel<T>[];
  RenderComponent?: Type<IBaseRenderDetailsComponent<any, any>>;
  MoreInfoTypes?: KgMoreInfoTypes;
}
