export class LocationHelper {
  static addHash(hash: string) {
    location.hash = !this.hasHash(hash) ? location.hash + hash : location.hash;
  }

  static removeHash(hash: string) {
    location.hash = location.hash.replace(hash, "");
  }

  static hasHash(hash: string) {
    return location.hash.indexOf(hash) > -1;
  }
}
