import { EventEmitter } from "@angular/core";

export class KgComponentMaterialsButtonModel {
  label: string;
  onClick = new EventEmitter<boolean>();

  constructor(label: string) {
    this.label = label;
  }
}
