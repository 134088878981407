import {
  ManagementsType,
  ManagementsStatus
} from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";

export class KgManagementsModel extends KgBaseModel {
  ManagementsTypeTitle: string;
  ManagementsStatusTitle: string;
  ActionTypeTitle: string;
  ManagementsType: ManagementsType;
  ManagementsName: string;
  BusinessRegistrationNumber: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Postcode: string;
  City: string;
  State: string;
  Country: string;
  ContactNumber: string;
  FaxNumber: string;
  Status: ManagementsStatus;

  CreateByName: string;

  get CreateByIdName(): string {
    return this.CreateByName;
  }

  get getCreateDateTime() {
    return this.CreateDate.toLocaleTimeString();
  }

  get getActionDateTime() {
    return this.ActionDate.toLocaleTimeString();
  }

  constructor(managementsModel?: KgManagementsModel, createByName?: string) {
    super();
    if (managementsModel != null) {
      Object.assign(this, managementsModel, {
        CreateDate: new Date(managementsModel.CreateDate),
        ActionDate: new Date(managementsModel.ActionDate)
      });
      this.CreateByName = createByName;
    } else {
      Object.assign(this, {
        ManagementsTypeTitle: "",
        ManagementsStatusTitle: "",
        ActionTypeTitle: "",
        ManagementsType: 0,
        ManagementsName: "",
        BusinessRegistrationNumber: "",
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        Postcode: "",
        City: "",
        State: "",
        Country: "",
        ContactNumber: "",
        FaxNumber: "",
        Status: 0,
        CreateByName: ""
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return
    return Object.assign({}, this, {
      // convert fields that need converting
      CreateDate: this.CreateDate.toISOString(),
      ActionDate: this.ActionDate.toISOString()
    });
  }
}
