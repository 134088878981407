export const ProductionResources = {
  StaticResource: {
    ProfileUri:
      "https://kgfiles.blob.core.windows.net/static-resources/ic_account_circle_black_48dp.png"
  },
  FCMSupportedBrowser: ["Chrome", "Chromium", "Firefox", "Opera"],
  admin: {
    email: "",
    password: ""
  },
  SelectableRolesByRoleType: {
    Administrator: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    Chairperson: [2, 3, 10],
    Committee: [],
    Manager: [4, 5, 6, 7, 8, 10, 11, 12],
    Resident: [],
    Tenant: [],
    Cleaner: [],
    SecurityGuard: [],
    Maintainer: [],
    Community: [],
    Clerk: [4, 5, 6, 7, 8, 10, 11, 12],
    Owner: [4, 5, 12],
    MasterTenant: []

    // Administrator = 0,
    // Chairperson = 1,
    // Committee = 2,
    // Management = 3,
    // Resident = 4,
    // Tenant = 5,
    // Cleaner = 6,
    // SecurityGuard = 7,
    // Maintainer = 8,
    // Community = 9,
    // Clerk = 10,
    // Owner = 11,
    // MasterTenant = 12
  },
  ValidationMessages: {
    Peoples: {
      FirstName: {
        required: "field are required",
        email: "Please enter a valid email address",
        exist: "email already exist"
      },
      LastName: {
        required: "field are required",
        InvalidAfterDelay: "timer said no no!"
      },
      IdentificationNumber: {
        required: "IdentificationNumber type is required"
      },
      Nationality: {
        required: "Nationality type is required"
      },
      ContactNumber: {
        required: "ContactNumber type is required"
      },
      MobileNumber: {
        required: "MobileNumber type is required",
        mobileexist: "mobile number already exist"
      },
      Password: {
        required: "Password is required",
        minlength: "must be at least 8 character long"
      },
      Email: {
        required: "Email type is required",
        email: "Please enter a valid email address",
        emailexist: "email is invalid"
      },
      Roles: {
        required: "Roles type is required"
      },
      DateOfBirth: {
        invalid: "invalid date format is in past"
      },
      UnitNumber: {
        invalid: "invalid unit number"
      }
    },
    AccessCards: {
      AccessCardId: {
        required: "field are required"
      },
      AccessCardSerialNumber: {
        required: "field are required"
      },
      AccessCardType: {
        required: "field are required"
      }
    },
    Communities: {
      CommunitiesName: {
        required: "CommunitiesName name is required",
        exist: "CommunitiesName name already exist"
      },
      CommunitiesType: {
        required: "Communities type is required"
      },
      AddressLine1: {
        required: "Address line 1 is required"
      },
      Postcode: {
        required: "Postcode is required",
        pattern: "Postcode format incorrect",
        maxlength: "Postcode cannot be more than 5 digits"
      },
      City: {
        required: "City is required"
      },
      State: {
        required: "State is required"
      },
      Country: {
        required: "Country is required"
      }
    },
    Complaint: {
      ExpectedCompletionDateTime: {
        invalid: "invalid date format",
        older: "Date selected are older than current date"
      },
      Title: {
        required: "Ticket title is required"
      },
      Description: {
        required: "Description is required"
      }
    },
    Visitors: {
      FirstName: {
        required: "field are required",
        email: "Please enter a valid email address",
        exist: "email already exist"
      },
      LastName: {
        required: "field are required",
        InvalidAfterDelay: "timer said no no!"
      },
      VisitDateTime: {
        invalid: "invalid date format"
      },
      MobileNumber: {
        required: "field are required",
        invalid: "Include country code e.g 012xxxxxxx"
      },
      Email: {
        email: "Please enter a valid email address"
      },
      VisitingUnit: {
        required: "visiting unit number is required"
      },
      VehicleRegistrationNumber: {
        invalid: "invalid registration number"
      },
      DateRange: {
        invalid: ""
      },
      IdentificationNumber: {
        required: "field are required"
      }
    },
    Announcement: {
      AnnouncementHeader: {
        required: "Announcement header is required"
      },
      AnnouncementContent: {
        required: "Announcement content is required"
      }
    },
    Units: {
      UnitNumber: {
        required: "field are required"
      }
    },
    Managements: {
      ManagementsName: {
        required: "field are required",
        email: "Please enter a valid email address",
        exist: "email already exist"
      },
      ManagementsType: {
        required: "Type of managements is required"
      },
      AddressLine1: {
        required: "Address line is required"
      },
      Postcode: {
        required: "Postcode is required",
        pattern: "Postcode format incorrect",
        maxlength: "Postcode cannot be more than 5 digits"
      },
      City: {
        required: "City is required"
      },
      State: {
        required: "State is required"
      },
      Country: {
        required: "Country is required"
      },
      ContactNumber: {
        required: "Contact number is required"
      }
    },
    Signin: {
      Email: {
        email: "Please enter a valid email address"
      },
      Password: {
        required: "password is required"
      },
      MobileNumber: {
        invalid: "Mobile number is invalid"
      }
    }
  }
};
