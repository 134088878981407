import { Component, OnInit, Input } from "@angular/core";
import { KgManagementsModel } from "src/app/model";
import { KgComponentMaterialsDataListSettingModel } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { KgLoggingService } from "src/app/framework/logging/kg-logging.service";

@Component({
  selector: "app-kg-management-dashboard",
  templateUrl: "./kg-management-dashboard.component.html",
  styleUrls: ["./kg-management-dashboard.component.scss"]
})
export class KgManagementDashboardComponent implements OnInit {
  @Input() kgmanagementList: KgManagementsModel[];
  @Input() kgmanagementSetting: KgComponentMaterialsDataListSettingModel<
    KgManagementsModel
  >;
  errorText: string;

  constructor(private logs: KgLoggingService) {
    if (this.kgmanagementList == null || this.kgmanagementList.length == 0) {
      this.errorText = "Management list is empty";
    }
  }

  ngOnInit() {}

  DisplaySecondaryTitle(item: KgManagementsModel) {
    return item.BusinessRegistrationNumber + " - " + item.ManagementsTypeTitle;
  }
}
