import { AccessCardStatuses } from "../../model/kg-core/kg-core.model";

export class KgAccessCardModel {
  AccessCardId: string = undefined;
  AccessCardSerialNumber: string = undefined;
  AccessCardStatus: AccessCardStatuses = undefined;
  AccessCardType: string = undefined;
  DateActivated: Date = undefined;

  constructor(card?: KgAccessCardModel) {
    if (card != null) {
      return Object.assign(this, card, {
        DateActivated: new Date(card.DateActivated)
      });
    }
  }
}
