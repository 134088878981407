import { Injectable } from "@angular/core";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { environment } from "environments/environment";
import {
  KgUnitModel,
  KgUnitPeopleModel,
  KgOwnershipTypes
} from "src/app/model/kg-units/kg-unit.model";

@Injectable()
export class KgUnitService {
  random = Math.random();

  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  getPeopleUnit() {
    const req = new HttpRequest("GET", `${environment.ApiHost}api/units`, {
      headers: this.authenticationService.getAccessTokenHeader()
    });
    return this.httpClient.request<KgUnitModel[]>(req);
  }

  getPeopleUnitByPeopleId(peopleId: string) {
    const req = new HttpRequest(
      "GET",
      `${environment.ApiHost}api/units/${peopleId}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
    return this.httpClient.request<KgUnitModel[]>(req);
  }

  addNewUnits(units: KgUnitModel[]) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/units/addnew`,
      units,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        responseType: "text"
      }
    );

    return this.httpClient.request<KgUnitModel>(req);
  }

  addNewUnitPeople(newUnitPeople: KgUnitPeopleModel, unitNumber: string) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/units/addnewunitpeople/${unitNumber}`,
      newUnitPeople,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        responseType: "text"
      }
    );

    return this.httpClient.request<KgUnitModel>(req);
  }

  changeOwnershipType(
    peopleId: string,
    unitNumber: string,
    ownershipType: KgOwnershipTypes
  ) {
    const req = new HttpRequest(
      "POST",
      `${
        environment.ApiHost
      }api/units/changeownershiptype/${unitNumber}/${peopleId}/${ownershipType}`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        responseType: "text"
      }
    );

    return this.httpClient.request(req);
  }

  removeUnitPeople(peopleId: string, unitNumber: string) {
    const req = new HttpRequest(
      "POST",
      `${
        environment.ApiHost
      }api/units/removeunitpeople/${unitNumber}/${peopleId}`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        responseType: "text"
      }
    );

    return this.httpClient.request<KgUnitModel>(req);
  }

  addUpdateUnit(unit: KgUnitModel) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/units/addnew`,
      unit,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request<KgUnitModel>(req);
  }
}
