import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  SimpleChanges,
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  TemplateRef,
  ContentChild
} from "@angular/core";
import {
  KgComponentMaterialsDataListSettingModel,
  MenuItemModel,
  KgFieldFunc,
  IconModuleTypes,
  KgMoreInfoTypes
} from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { MDCMenu } from "@material/menu";
import { IBaseDynamicComponent } from "src/app/framework/model/IBaseDynamicComponent";
import { KgComponentMaterialsDataListDirective } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.directive";
import { ScrollToService } from "@nicky-lenaers/ngx-scroll-to";

export class KgComponentMaterialsDataListSettingModelTypes {
  PrimaryTitleFieldType: string = "";
  SecondaryTitleFieldType: string = "";
  IconField: any;
  constructor(setting?: KgComponentMaterialsDataListSettingModel<any>) {
    if (setting) {
      this.PrimaryTitleFieldType = setting.PrimaryTitleField
        ? setting.PrimaryTitleField.constructor.name
        : "";
      this.SecondaryTitleFieldType = setting.SecondaryTitleField
        ? setting.SecondaryTitleField.constructor.name
        : "";
      this.IconField =
        setting.IconField && setting.IconField.Icon
          ? setting.IconField.Icon.constructor.name
          : "";
    }
  }
}
@Component({
  selector: "kg-component-materials-datalist",
  templateUrl: "./kg-component-materials-datalist.component.html",
  styleUrls: ["./kg-component-materials-datalist.component.scss"]
})
export class KgComponentMaterialsDatalistComponent
  implements OnInit, AfterViewInit, OnChanges {
  @Input() kgscrollmore: boolean;
  @Input() kgisloading: boolean;
  @Input() kgdense: boolean;
  @Input() kgid: string;
  @Input() kgtitle: string;
  @Input() kginputdata: any[];
  // @Input() kgsetting: KgComponentMaterialsDataListSettingModel<any>;
  @Input() kgsetting: KgComponentMaterialsDataListSettingModel<any>;
  @Input() kgtemplate: TemplateRef<any>;
  kgSettingTypes: KgComponentMaterialsDataListSettingModelTypes = new KgComponentMaterialsDataListSettingModelTypes();
  @ViewChildren(KgComponentMaterialsDataListDirective) renderers: QueryList<
    KgComponentMaterialsDataListDirective
  >;

  @ContentChild("PrimaryFieldTemplate") primaryFieldTemplate: TemplateRef<any>;
  @ContentChild("SecondaryFieldTemplate") secondaryFieldTemplate: TemplateRef<
    any
  >;
  @ContentChild("ContentBlockTemplate") contentBlockTemplate: TemplateRef<any>;
  @ContentChild("MoreInfoBlockTemplate") moreInfoBlockTemplate: TemplateRef<
    any
  >;
  componentRef: ComponentRef<IBaseDynamicComponent<any, any>>[] = [];

  @ViewChildren("moreinfobox") mib: QueryList<ElementRef>;
  @ViewChildren("moreinfo") mi: QueryList<ElementRef>;
  @ViewChildren("menu") menus: QueryList<ElementRef>;
  @ViewChildren("expandarrow") expandArrow: QueryList<ElementRef>;
  activeMenu: MDCMenu;
  iconTypes = IconModuleTypes;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private scrollToService: ScrollToService
  ) {}

  ngOnInit() {
    // console.log(this.kgsetting);
    // console.log(this.kginputdata);
    // console.debug(this.kgtemplate);
  }

  ngAfterViewInit(): void {
    // this.mib.forEach(x => {
    //   x.nativeElement.style.height = 0;
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName == "kginputdata") {
        // this.componentRef = new Array(changes[propName].currentValue.length);
      } else if (propName == "kgsetting") {
        this.kgSettingTypes = new KgComponentMaterialsDataListSettingModelTypes(
          changes[propName].currentValue
        );
        // console.log(this.kgSettingTypes);
      }
    }
  }

  createComponent(index: number) {
    let f = this.renderers.find((x, i) => {
      return index == i;
    });

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.kgsetting.RenderComponent
    );
    let viewContainerRef = f.viewContainerRef;

    viewContainerRef.clear();
    let ref = viewContainerRef.createComponent(
      componentFactory,
      null,
      this._viewContainerRef.parentInjector
    );
    ref.instance.componentInputData = this.kginputdata[index];

    if (ref.instance.afterInputDataBind) {
      ref.instance.afterInputDataBind();
    }

    return ref;
  }

  hideMenu() {
    if (this.activeMenu) {
      this.activeMenu.open = false;
      this.activeMenu.destroy();
      this.activeMenu = null;
    }
  }

  showMenu(e: MouseEvent, i: number) {
    console.log("showmenu");
    e.stopPropagation();
    this.hideMenu();

    let f = this.menus.find((x, index) => {
      return index == i;
    });
    this.activeMenu = new MDCMenu(f.nativeElement);
    this.activeMenu.open = !this.activeMenu.open;
  }

  expandMoreInfo(e: MouseEvent, item: any, index: number) {
    e.stopPropagation();
    this.hideMenu();

    //only use component factory when there is RenderComponent
    if (this.kgsetting.RenderComponent) {
      //only create when componentRef of index is null
      this.componentRef[index] = this.componentRef[index]
        ? this.componentRef[index]
        : this.createComponent(index);
    }

    let s = this.mi.toArray()[index].nativeElement as HTMLElement;
    let c = this.mib.toArray()[index].nativeElement as HTMLElement;
    let exa = this.expandArrow.toArray()[index].nativeElement as HTMLElement;
    let isExpended = s.style.height != "0px";
    s.style.height = isExpended ? "0px" : "auto";
    s.style.maxHeight = isExpended ? "0px" : "2000px";
    isExpended
      ? exa.classList.remove("rotate-item")
      : exa.classList.add("rotate-item");

    !isExpended
      ? this.scrollToService.scrollTo({
          target: `moreinfo-${this.kgid}-${index}`
        })
      : null;
  }

  callback(menuitem: MenuItemModel<any>, item: any) {
    menuitem.OnClick.emit(item);
    this.hideMenu();
  }

  onItemClicked(e: MouseEvent, item: any, index: number) {
    e.stopPropagation();

    if (this.kgsetting.OnItemClick) {
      this.kgsetting.OnItemClick.emit(item);
    } else {
      if (
        this.kgsetting.RenderComponent ||
        this.kgsetting.MoreInfoTypes == KgMoreInfoTypes.Expanding
      ) {
        this.expandMoreInfo(e, item, index);
      } else {
        this.hideMenu();
      }
    }
  }

  getPrimaryTitleType() {
    // console.log(typeof (this.kgsetting.PrimaryTitleField));
    // return typeof (this.kgsetting.PrimaryTitleField);
  }

  getPrimaryTitleFieldText(item: any) {
    switch (this.kgSettingTypes.PrimaryTitleFieldType) {
      case "String":
        return item[this.kgsetting.PrimaryTitleField as string];
      case "Function":
        return (this.kgsetting.PrimaryTitleField as KgFieldFunc<any>)(item);
    }
  }

  getSecondaryTitleFieldText(item: any) {
    switch (this.kgSettingTypes.SecondaryTitleFieldType) {
      case "String":
        return item[this.kgsetting.SecondaryTitleField as string];
      case "Function":
        return (this.kgsetting.SecondaryTitleField as KgFieldFunc<any>)(item);
    }
  }

  getIconField(item: any) {
    if (this.kgsetting.IconField.ModuleType == IconModuleTypes.Image) {
      if (typeof this.kgsetting.IconField.Icon == "string") {
        return item[this.kgsetting.IconField.Icon.toString()];
      } else {
        return this.kgsetting.IconField.Icon
          ? this.kgsetting.IconField.Icon(item)
          : "view_list";
      }
    }
  }

  getMdcIconField(item: any) {
    if (this.kgsetting.IconField.ModuleType == IconModuleTypes.Mdc) {
      if (typeof this.kgsetting.IconField.Icon == "string") {
        return this.kgsetting.IconField.Icon.toString();
      } else {
        return this.kgsetting.IconField.Icon
          ? this.kgsetting.IconField.Icon(item)
          : "view_list";
      }
    }
  }

  getIcoMoonField(item: any) {
    if (this.kgsetting.IconField.ModuleType == IconModuleTypes.IcoMoon) {
      return item[this.kgsetting.IconField.Icon.toString()];
    } else {
      return this.kgsetting.IconField.Icon
        ? this.kgsetting.IconField.Icon
        : "icomoon-file-text2";
    }
  }

  getIconColor(item: any) {
    if (
      this.kgsetting.IconField.ModuleType == IconModuleTypes.Mdc ||
      this.kgsetting.IconField.ModuleType == IconModuleTypes.IcoMoon
    ) {
      if (typeof this.kgsetting.IconField.Icon == "string") {
        return item[this.kgsetting.IconField.Icon.toString()];
      } else {
        let color =
          typeof this.kgsetting.IconField.Color == "function"
            ? this.kgsetting.IconField.Color(item)
            : "grey";
        return color;
      }
    }
  }

  getIconType(type: any) {
    console.log(type);
  }
}
