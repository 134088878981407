import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from "@angular/router";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { PeopleRoles } from "src/app/model/kg-core";

@Injectable()
export class KgAuthGardService implements CanActivate, CanActivateChild {
  constructor(
    private authenticationService: KgAuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log(route, state);
    if (
      (state.url == "/security/elid" || state.url == "/security/qr") &&
      this.authenticationService.hasSession &&
      this.authenticationService.hasAccessRole(PeopleRoles.SecurityGuard)
    ) {
      return true;
    }

    if (
      state.url != "/security" &&
      this.authenticationService.hasSession &&
      this.authenticationService.hasAccessRole(PeopleRoles.SecurityGuard)
    ) {
      this.router.navigate(["/security"]);
      return true;
    }

    if (
      this.authenticationService.hasSession &&
      this.authenticationService.hasSession &&
      this.authenticationService.hasAccessRoles(route.data.forRoles)
    ) {
      return true;
    } else {
      console.log("Route guard detected unauthorized access");
      this.router.navigate(["/signout"]);
      return false;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
}
