import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgWidgetPeopleDetailsComponent } from "./kg-widget-people-details/kg-widget-people-details.component";
import { KgComponentMaterialsModule } from "../../../modules/libs/kg-component-materials";
import { KgPeoplePipesModule } from "../../pipes/kg-people-pipes/kg-people-pipes.module";

@NgModule({
  imports: [CommonModule, KgComponentMaterialsModule, KgPeoplePipesModule],
  declarations: [KgWidgetPeopleDetailsComponent],
  exports: [KgWidgetPeopleDetailsComponent]
})
export class KgWidgetPeopleDetailsModule {}
