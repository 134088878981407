import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ViewChild
} from "@angular/core";

@Component({
  selector: "kg-component-materials-button",
  templateUrl: "./kg-component-materials-button.component.html",
  styleUrls: ["./kg-component-materials-button.component.scss"]
})
export class KgComponentMaterialsButtonComponent implements OnInit, OnChanges {
  @Input() kgid: string;
  @Input() kglabel: string;
  @Input() kgtype: string;
  @Output() kgclick = new EventEmitter<MouseEvent>();
  @Input() kgdisabled = false;
  @Input() kgclasslist: string[];
  @Input() kgicon: string;
  @Input() kgraisebutton: string;

  @ViewChild("kgbutton") mybutton: ElementRef;

  constructor(private elem: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    // this.setButtonActiveState();
    this.kgclasslist
      ? this.kgclasslist.forEach(x => {
          (this.mybutton.nativeElement as HTMLButtonElement).classList.add(x);
        })
      : null;
  }
  ngOnInit() {
    // this.setButtonActiveState();
  }

  clicked(event: MouseEvent) {
    !this.kgdisabled ? this.kgclick.emit(event) : null;
  }

  // setButtonActiveState() {
  //   if (typeof (this.kgdisabled) != "boolean") {
  //     throw new TypeError("ERROR: expecting kgdisabled to be boolean but have typeof : " + typeof (this.kgdisabled));
  //   }
  //   let button = (this.elem.nativeElement as Element).querySelector(".mdc-button");
  //   this.kgdisabled ? button.setAttribute("disabled", "") : button.removeAttribute("disabled");

  // }
}
