import { Injectable, ErrorHandler } from "@angular/core";
import { KgLoggingService } from "src/app/framework/logging/kg-logging.service";

@Injectable()
export class KgErrorHandler extends ErrorHandler {
  constructor(private loggingService: KgLoggingService) {
    super();
  }
  handleError(error: any) {
    super.handleError(error);
    this.loggingService.error("KgErrorHandler:", error);
  }
}
