import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KgDebugModeComponent } from "src/app/modules/kg-debug/debugmode/debugmode.component";
import { KgDebugRoutingModule } from "src/app/modules/kg-debug/kg-debug-routing.module";
import { KgComponentMaterialsModule } from "src/app/modules/libs/kg-component-materials";
// import { KgAuthenticationService } from '../../services/kg-authentication/kg-authentication.service';

@NgModule({
  imports: [CommonModule, KgDebugRoutingModule, KgComponentMaterialsModule],
  // providers: [KgAuthenticationService],
  declarations: [KgDebugModeComponent]
})
export class KgDebugModule {}
