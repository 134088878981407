// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { ProductionResources } from "environments/resources/production.resource";
import { IEnvironment } from "./i-environment.interface";

export const environment: IEnvironment = {
  production: true,
  environmentType: "LLSProd",
  ApiHost: "https://kgbaru-laiseng.azurewebsites.net/",
  ApiVersion: "1.1",
  ElidApiHost: "https://localhost:5555/",
  // Resource: require("./resources/production.resource.json"),
  ResourceTyped: ProductionResources,
  firebase: {
    apiKey: "AIzaSyDSBL9DiVK5OuRobvDioqxjkskWfGsVNVM",
    databaseURL: "https://kgbarudev.firebaseio.com",
    storageBucket: "kgbarudev.appspot.com",
    authDomain: "kgbarudev.firebaseapp.com",
    messagingSenderId: "1073258743686",
    projectId: "kgbarudev"
  },
  SiteSettings: {
    Evis: true,
    Brandings: {
      HasSecondaryLogo: false,
      HeaderPrimaryLogo: {
        src: "assets/icons/icon-48x48.png",
        href: "/"
      }
    }
  }
};
