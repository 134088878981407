import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { HttpClient } from "@angular/common/http";
import { KgNotificationModel } from "src/app/model/kg-notifications/kg-notification.model";

@Injectable()
export class KgNotificationsService {
  random = Math.random();
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  get() {
    return this.httpClient.get<KgNotificationModel[]>(
      `${environment.ApiHost}api/notifications`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getByPage(pageSize: number, page: number) {
    return this.httpClient.get<KgNotificationModel[]>(
      `${environment.ApiHost}api/notifications/pages/${pageSize}/${page}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getTotalNotificationsCount() {
    return this.httpClient.get<number>(
      `${environment.ApiHost}api/notifications/totalcount`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  countUnseenNotifications() {
    return this.httpClient.get<number>(
      `${environment.ApiHost}api/notifications/countnew`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  setNotificationsSeen(notificationIds: string[]) {
    return this.httpClient.post<KgNotificationModel[]>(
      `${environment.ApiHost}api/notifications/seen`,
      notificationIds,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }
}
