import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  EventEmitter,
  ViewEncapsulation,
  Renderer2
} from "@angular/core";
import { KgComponentMaterialsLinearProgressComponent } from "../../../modules/libs/kg-component-materials/kg-component-materials-linear-progress/kg-component-materials-linear-progress.component";
import { IBaseDynamicComponent } from "src/app/framework/model/IBaseDynamicComponent";
import Cropper from "cropperjs";
import { PhotoSizeTypes } from "src/app/model/kg-people/kg-people.model";
import { UUID } from "angular2-uuid";

export class KgProfileManagerComponentOutput {
  onDone = new EventEmitter<KgPhotoProperties[]>();
  onCancel = new EventEmitter<boolean>();
}

export enum PhotoBlobStatuses {
  New = 0,
  DeletedNew = 1,
  Existing = 2,
  DeletedExisting = 3
}
export interface KgPhotoProperties {
  size: PhotoSizeTypes;
  blob: Blob;
  type: string;
  name: string;
  blobUrl: string;
  photouuid: string;
  isprimary: boolean;
  status: PhotoBlobStatuses;
  // formData: FormData;
}

@Component({
  selector: "kg-profile-manager",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./kg-profile-manager.component.html",
  styleUrls: [
    "./kg-profile-manager.component.scss",
    "../../../../../node_modules/cropperjs/dist/cropper.css"
  ]
})
export class KgProfileManagerComponent
  implements
    OnInit,
    IBaseDynamicComponent<any, KgProfileManagerComponentOutput> {
  componentInputData: number;
  componentOutput: KgProfileManagerComponentOutput;

  @ViewChild("FileInput") fi: ElementRef;
  @ViewChild("cropperbox") cropperBox: ElementRef;
  @ViewChild("progressbar")
  progressBar: KgComponentMaterialsLinearProgressComponent;

  fileInputElement: HTMLInputElement;
  selectedFileName: string;
  selectedFileType: string;
  cropper: Cropper;
  selectedPhotoUuid: string;

  selectedImageSize: string;
  selectedImageCroppedSize: string;

  constructor(private ref: ViewContainerRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.fileInputElement = this.fi.nativeElement as HTMLInputElement;

    this.fileInputElement.addEventListener("change", x => {
      if (this.fileInputElement.files.length != 0) {
        let imgElement = this.cropperBox.nativeElement as HTMLImageElement;
        imgElement.src = URL.createObjectURL(
          this.fi.nativeElement.files.item(0)
        );
        this.selectedFileName = this.fi.nativeElement.files.item(0).name;
        this.selectedFileType = this.fi.nativeElement.files.item(0).type;
        this.selectedPhotoUuid = UUID.UUID();
      }
    });

    this.cropperBox.nativeElement.addEventListener("load", x => {
      var options: Cropper.Options = {
        aspectRatio: 1,
        preview: "#previewimage",
        movable: true,

        dragMode: "move" as Cropper.DragMode,
        ready: e => {
          this.selectedImageSize = `${
            this.cropper.getImageData().naturalWidth
          }x${this.cropper.getImageData().naturalHeight}`;
          console.log(e.type);
        },
        cropstart: e => {
          console.log(e.type, e.detail.action);
        },
        cropmove: e => {
          console.log(e.type, e.detail.action);
        },
        cropend: e => {
          console.log(e.type, e.detail.action);
        },
        crop: e => {
          var data = e.detail;
          this.selectedImageCroppedSize = `${e.detail.width.toFixed(
            0
          )}x${e.detail.height.toFixed(0)}`;

          console.log(e.type);
        },
        zoom: e => {
          console.log(e.type, e.detail.ratio);
        }
      };
      this.cropper ? this.cropper.destroy() : null;
      this.cropper = new Cropper(this.cropperBox.nativeElement, options);
    });
  }

  afterShown() {
    this.fileInputElement.click();
  }

  getSelectedFileText() {
    if (this.fileInputElement.files.length == 0) {
      return "Select photo";
    } else {
      return this.fileInputElement.files.item(0).name;
    }
  }

  showFileDialog(e: MouseEvent) {
    this.fileInputElement.click();
  }

  doFocus() {
    console.log("is in focues");
    this.fileInputElement.click();
  }

  onCancel($event) {
    this.reset();
    this.componentOutput.onCancel.emit(true);
  }

  reset() {
    this.cropper ? this.cropper.destroy() : null;
    this.renderer.setAttribute(this.cropperBox.nativeElement, "src", "#");
    (this.fi.nativeElement as HTMLInputElement).value = "";
  }

  zoom(e: MouseEvent, isZoomIn: boolean) {
    isZoomIn ? this.cropper.zoom(0.1) : this.cropper.zoom(-0.1);
  }

  moveimage(e: MouseEvent, direction: string) {
    switch (direction) {
      case "up":
        this.cropper.move(0, -10);
        break;
      case "down":
        this.cropper.move(0, 10);
        break;
      case "left":
        this.cropper.move(-10, 0);
        break;
      case "right":
        this.cropper.move(10, 0);
        break;
    }
  }

  onDone($event) {
    let result: KgPhotoProperties[] = [];
    result.push(
      this.generateNewKgBlobProperties(
        PhotoSizeTypes.Raw,
        this.fileInputElement.files.item(0),
        this.selectedFileName,
        this.selectedPhotoUuid,
        false,
        PhotoBlobStatuses.New
      )
    );

    this.cropper
      .getCroppedCanvas({
        width: 600,
        height: 600,
        imageSmoothingQuality: "high" as Cropper.ImageSmoothingQuality
      })
      .toBlob(blob600 => {
        result.push(
          this.generateNewKgBlobProperties(
            PhotoSizeTypes.Large600px,
            blob600,
            this.selectedFileName,
            this.selectedPhotoUuid,
            false,
            PhotoBlobStatuses.New
          )
        );

        this.cropper
          .getCroppedCanvas({
            width: 128,
            height: 128,
            imageSmoothingQuality: "high" as Cropper.ImageSmoothingQuality
          })
          .toBlob(blob128 => {
            result.push(
              this.generateNewKgBlobProperties(
                PhotoSizeTypes.Icon128px,
                blob128,
                this.selectedFileName,
                this.selectedPhotoUuid,
                false,
                PhotoBlobStatuses.New
              )
            );

            this.cropper
              .getCroppedCanvas({
                width: 48,
                height: 48,
                imageSmoothingQuality: "high" as Cropper.ImageSmoothingQuality
              })
              .toBlob(blob48 => {
                result.push(
                  this.generateNewKgBlobProperties(
                    PhotoSizeTypes.Icon48px,
                    blob48,
                    this.selectedFileName,
                    this.selectedPhotoUuid,
                    false,
                    PhotoBlobStatuses.New
                  )
                );

                this.componentOutput.onDone.emit(result);
              });
          });
      });
  }

  generateNewKgBlobProperties(
    sizeType: PhotoSizeTypes,
    blob: Blob,
    fileName: string,
    photoUuid: string,
    isPrimary: boolean,
    photoBlobStatus: PhotoBlobStatuses
  ) {
    return {
      size: sizeType,
      blob: blob,
      type: blob.type,
      name: `${this.getFileSizeString(sizeType)}_${photoUuid}_${fileName}`,
      blobUrl: URL.createObjectURL(blob),
      photouuid: photoUuid,
      isprimary: isPrimary,
      status: photoBlobStatus
    };
  }

  getFileSizeString(sizeType: PhotoSizeTypes) {
    switch (sizeType) {
      case PhotoSizeTypes.Icon24px:
        return "24x24";
      case PhotoSizeTypes.Icon36px:
        return "36x36";
      case PhotoSizeTypes.Icon48px:
        return "48x48";
      case PhotoSizeTypes.Icon128px:
        return "128x128";
      case PhotoSizeTypes.Icon192px:
        return "192x192";
      case PhotoSizeTypes.Large600px:
        return "600x600";
      case PhotoSizeTypes.Raw:
        return "raw";
      default:
        throw new Error(`PhotoSizeTypes:${sizeType} not found`);
    }
  }

  afterInputDataBind(): void {}
}
