import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { KgComponentMaterialsLinearProgressComponent } from "src/app/modules/libs/kg-component-materials/kg-component-materials-linear-progress/kg-component-materials-linear-progress.component";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";

@Component({
  selector: "kg-progress-bar",
  templateUrl: "./kg-progress-bar.component.html",
  styleUrls: ["./kg-progress-bar.component.scss"]
})
export class KgProgressBarComponent implements OnInit, AfterViewInit {
  @ViewChild("progressbar")
  progressbar: KgComponentMaterialsLinearProgressComponent;
  isOpen = false;
  constructor(private progressBarService: KgProgressBarService) {}

  ngOnInit() {
    this.progressBarService.progressbar$.subscribe(showProgressBar => {
      console.log("toggle progressbar" + showProgressBar + " " + this.isOpen);

      if (showProgressBar) {
        if (!this.isOpen) {
          this.progressbar.start();
          this.isOpen = true;
        }
      } else {
        this.isOpen = false;
        this.progressbar.stop();
      }
    });
  }

  ngAfterViewInit(): void {}
}
