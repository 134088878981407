import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { KgEnumHandler } from "../../utility/kg-enum-handler";

@NgModule({
  imports: [BrowserModule, FormsModule, ReactiveFormsModule],
  declarations: [],
  exports: [BrowserModule, FormsModule, ReactiveFormsModule],
  providers: [KgEnumHandler]
})
export class CoreModule {
  //this is a new comment
}
