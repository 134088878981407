import { Component, OnInit, Input } from "@angular/core";
import {
  KgAnnouncementModel,
  KgAnnouncementResponseModel
} from "src/app/model/kg-announcement";
import { KgComponentMaterialsDataListSettingModel } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { KgLoggingService } from "src/app/framework/logging/kg-logging.service";
import { KgChipSettings } from "src/app/modules/libs/kg-chips/kg-component-chips/kg-component-chips.component";
import { Colors } from "kg-lib-models";
import { format } from "date-fns";
@Component({
  selector: "app-kg-announcement-dashboard",
  templateUrl: "./kg-announcement-dashboard.component.html",
  styleUrls: ["./kg-announcement-dashboard.component.scss"]
})
export class KgAnnouncementDashboardComponent implements OnInit {
  @Input() kgannouncementList: KgAnnouncementModel;
  @Input() kgannouncementSetting: KgComponentMaterialsDataListSettingModel<
    KgAnnouncementModel
  >;
  announcementDetailsChips: KgChipSettings[] = [];
  announcementTagChips: KgChipSettings[] = [];
  errorText: string;

  constructor(private logs: KgLoggingService) {
    if (this.kgannouncementList == null) {
      this.errorText = "Announcement list is empty";
    }
  }
  ngOnInit() {}

  getDetailsChips(item: KgAnnouncementResponseModel): KgChipSettings[] {
    if (!this.announcementDetailsChips[item.Id]) {
      this.announcementDetailsChips[item.Id] = [];
      let creatorText = "by " + item.CreateByPeople.FirstName;
      let createDate = format(item.CreateDate, "do MMM YYYY hh:mm A");

      this.announcementDetailsChips[item.Id].push({
        Color: Colors.Green500,
        Title: creatorText,
        MdcIcon: "person"
      });
      this.announcementDetailsChips[item.Id].push({
        Color: Colors.Orange500,
        Title: createDate,
        MdcIcon: "date_range"
      });
    }

    return this.announcementDetailsChips[item.Id];
  }
}
