import { Component } from "@angular/core";
import { format } from "date-fns";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { KgAuthenticationService } from "../../services/kg-authentication/kg-authentication.service";

@Component({
  selector: "footer-component",
  templateUrl: "footer.component.html",
  styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
  versionFile = require("../../../build-version.json");
  title = "Footer";
  buildVersion = `${format(
    this.versionFile.date,
    "YYYY-MM-DD ( hh:mm:ss A )"
  )} [${environment.environmentType}]`;
  count = 0;

  footerLinks = [
    { Url: "http://elid.com/", Label: "Home" },
    { Url: "http://elid.com/index.php/about", Label: "About us" }
    // { Url: 'https://kgbaru.com/terms_of_service', Label: 'Terms of Service' },
    // { Url: 'https://kgbaru.com/privacy', Label: 'Privacy Policy' },
    // { Url: 'https://kgbaru.com/#contactus', Label: 'Contact us' },
    // { Url: 'https://docs.kgbaru.com', Label: 'Help' }
  ];

  constructor(
    private router: Router,
    public authService: KgAuthenticationService
  ) {}

  enterdebug() {
    if (this.count == 5) {
      this.count = 0;
      this.router.navigate(["/debugmode"]);
    } else {
      this.count++;
    }
  }
}
