import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/Subject";
import { KgFabItemModel } from "../../model/kg-fab/kg-fab.model";

@Injectable()
export class KgFabService {
  instanceId = Math.random();
  constructor(private router: Router) {}

  // Observable string sources
  private fabSubject = new Subject<KgFabItemModel[]>();

  // Observable string streams
  fab$ = this.fabSubject.asObservable();

  // Service message commands
  announceNewFabList(fabList: KgFabItemModel[]) {
    this.fabSubject.next(fabList);
  }
}
