import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "kg-spinner",
  templateUrl: "./kg-spinner.component.html",
  styleUrls: ["./kg-spinner.component.scss"]
})
export class KgSpinnerComponent implements OnInit {
  @Input() kgshow = false;
  @Input() kgnotfoundtext;
  showNothingFound = false;
  constructor() {}

  ngOnInit() {}

  show(showNothingFound?: boolean) {
    if (showNothingFound) {
      this.kgshow = true;
      this.showNothingFound = true;
      this.hide();
    } else {
      this.showNothingFound = false;
      this.kgshow = true;
    }
  }

  hide() {
    this.kgshow = false;
  }
}
