import { KgPeopleService } from "src/app/services/kg-people";
import {
  AsyncValidatorFn,
  ValidationErrors,
  AbstractControl,
  ValidatorFn
} from "@angular/forms";
import { KgCommunitiesService } from "../../services/kg-communities";
import { KgLoggingService } from "../../framework/logging/kg-logging.service";
import { KgCommunitiesRouterDataService } from "src/app/services/kg-communities/kg-communities-routerdata.service";
import { KgCommunitiesModel } from "src/app/model";
import { Observable, timer, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { isBefore, parse } from "date-fns";

export class KgValidators {
  static asyncEmailExist(peopleService: KgPeopleService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      if (control.value == null || control.value.length === 0) {
        return of(null); // don't validate empty values to allow optional controls
      }

      let v = control.value as string;

      return timer(1000).pipe(
        switchMap(() => {
          console.log("throttled asyncEmailExist 1s switchMapped");
          return peopleService.emailAddressExist(v).pipe(
            map(emailExist => {
              return emailExist ? { emailexist: true } : null;
            })
          );
        })
      );
    };
  }

  static asyncMobileNumberExist(
    peopleService: KgPeopleService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      if (control.value == null || control.value.length === 0) {
        return of(null); // don't validate empty values to allow optional controls
      }

      let v = control.value as string;

      return timer(1000).pipe(
        switchMap(() => {
          console.log("throttled asyncMobileNumberExist 1s switchMapped");
          return peopleService.mobileNumberExist(v).pipe(
            map(emailExist => {
              return emailExist ? { mobileexist: true } : null;
            })
          );
        })
      );
    };
  }

  static asyncCommunityNameExist(
    communitiesService: KgCommunitiesService,
    logs: KgLoggingService,
    routeDataExchange: KgCommunitiesRouterDataService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      if (control.value == null || control.value.length === 0) {
        return of(null); // don't validate empty values to allow optional controls
      }

      let v = control.value as string;

      return timer(2000).pipe(
        switchMap(() => {
          console.log("throttled asyncCommunityNameExist 2s switchMapped");
          return communitiesService.getByName(v).pipe(
            map<KgCommunitiesModel[], ValidationErrors>(
              communities => {
                let resultparse = communities as KgCommunitiesModel[];
                if (
                  routeDataExchange.addNewRecord &&
                  communities.length > 0 &&
                  resultparse[0].CommunitiesName.toLowerCase() ==
                    v.toLowerCase()
                ) {
                  return { exist: true };
                } else if (
                  communities.length > 0 &&
                  resultparse[0].CommunitiesName.toLowerCase() ==
                    v.toLowerCase() &&
                  v.toLowerCase() !=
                    routeDataExchange.data.CommunitiesName.toLowerCase()
                ) {
                  control.markAsTouched();

                  return { exist: true };
                } else {
                  return null;
                }
              },
              err => {
                logs.error("Get communities by communities name", err);
              }
            )
          );
        })
      );
    };
  }

  static isInPast(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || control.value.length === 0) {
        return null; // don't validate empty values to allow optional controls
      }
      let formDate = parse(control.value as string);
      return isBefore(formDate, new Date()) ? { IsInPast: true } : null;
    };
  }

  static isValidMalaysiaMobileNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || control.value.length === 0) {
        return null; // don't validate empty values to allow optional controls
      }
      let s = control.value;
      let mobileRegEx = new RegExp(/^01\d{8,9}/);
      let valid = mobileRegEx.test(s) ? null : { invalid: true };
      return valid;
    };
  }

  static isValidMobileNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || control.value.length === 0) {
        return null; // don't validate empty values to allow optional controls
      }
      let s = control.value;
      let mobileRegEx = new RegExp(/^\d{10,11}/);
      let valid = mobileRegEx.test(s) ? null : { invalid: true };
      return valid;
    };
  }
}
