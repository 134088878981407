import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { catchError } from "rxjs/operators";
import { _throw } from "../../../../node_modules/rxjs/observable/throw";

@Injectable()
export class KgPeopleCategoriesService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  getPeopleByCategories(categories: string) {
    return this.httpClient
      .get(environment.ApiHost + "api/peoples/categories/" + categories, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }
}
