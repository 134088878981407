import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { KgPeopleModel, PhotoSizeTypes } from "../../model/kg-people/index";
import { KgPeopleService } from "../../services/kg-people/index";
import { FormGroup } from "@angular/forms";
import {
  KgComponentMaterialsDialogComponent,
  KgComponentMaterialsDialogButtonModel
} from "../libs/kg-component-materials/";
import { KgPeopleRouterDataService } from "./kg-people-routerdata.service";
import { Subscription, Subject } from "rxjs";
import { KgLoggingService } from "../../framework/logging/kg-logging.service";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { PeopleRoles } from "../../model/kg-core";
import { KgFabService } from "../../services/kg-fab/kg-fab.service";
import { KgFabItemModel } from "../../model/kg-fab/kg-fab.model";
import {
  KgComponentMaterialsDataListSettingModel,
  IconModuleTypes,
  KgMoreInfoTypes
} from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import {
  ProfilePhotosModel,
  PeoplesSearchCategory,
  KgUnitResponse
} from "src/app/model/kg-people/kg-people.model";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";
import { takeUntil } from "rxjs/operators/takeUntil";
import { KgInfiniteScrollService } from "src/app/services/kg-infinite-scroll/kg-infinite-scroll.service";
import { environment } from "environments/environment";
import { KgChipSettings } from "src/app/modules/libs/kg-chips/kg-component-chips/kg-component-chips.component";
import { Colors } from "kg-lib-models";
import { MenuItemModel } from "src/app/model/kg-find/kg-find.model";
import { KgEnumKeyPair, KgEnumHandler } from "src/app/utility/kg-enum-handler";
import { KgComponentMaterialsSelectComponent } from "src/app/modules/libs/kg-component-materials/kg-component-materials-select";
import { PeopleStatuses } from "src/app/model/kg-core/kg-core.model";
import { KgPeopleUnitsResponseModel } from "src/app/model/kg-units/kg-peopleunitsreponse.model";
import { KgSnackbarService } from "src/app/services/kg-snackbar/kg-snackbar.service";
import { DomSanitizer } from "@angular/platform-browser";
import { distanceInWordsToNow } from "date-fns";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  moduleId: module.id,
  selector: "kg-people",
  templateUrl: "kg-people.component.html",
  styleUrls: ["kg-people.component.scss"]
})

// Show all user and loads other component for interaction in UI
export class KgPeopleComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();
  people: KgPeopleModel[];
  peopleUnitsResponse: KgUnitResponse[];
  // peopleUnitsResponseTotalCount: number;
  peoplesForm: FormGroup;
  selectedIndexes: number[] = [];
  userServicesSubscription: Subscription;
  subscriptionGetPeopleDetail: Subscription;
  totalCount: number;
  currentPage: number = 1;

  onEditClicked = new EventEmitter<KgPeopleModel>();
  onRemoveClicked = new EventEmitter<KgPeopleModel>();

  peoplesSetting: KgComponentMaterialsDataListSettingModel<KgPeopleModel> = {
    IconField: {
      Icon: item => {
        return item.ProfilePhotos && item.ProfilePhotos.length > 0
          ? this.getProfilePhotoUri(item.ProfilePhotos)
          : environment.ResourceTyped.StaticResource.ProfileUri;
      },
      ModuleType: IconModuleTypes.Image
    }, //"ProfileIcon",
    // OnItemClick: new EventEmitter<KgPeopleModel>(),
    MenuItems: [
      { Title: "Edit", Icon: "edit", OnClick: this.onEditClicked },
      { Title: "Remove", Icon: "delete", OnClick: this.onRemoveClicked },
      {
        Title: "close",
        Icon: "close",
        OnClick: new EventEmitter<KgPeopleModel>()
      }
    ],
    MoreInfoTypes: KgMoreInfoTypes.Expanding
    // RenderComponent: KgPeopleDetailsComponent
  };

  peopleFindString: string = "";
  peopleFindMenuItems: MenuItemModel<any>[];
  peopleOnSearch = false;

  onNameSearchClicked = new EventEmitter<string>();
  onCreatedBySearchClicked = new EventEmitter<string>();
  onUnitNoSearchClicked = new EventEmitter<string>();
  onIcPassportSearchClicked = new EventEmitter<string>();
  onPhoneNoSearchClicked = new EventEmitter<string>();
  onEmailSearchClicked = new EventEmitter<string>();
  onCountrySearchClicked = new EventEmitter<string>();
  onRoleSearchClicked = new EventEmitter<string>();

  routeParamSub: Subscription;
  searchString: string;
  searchCategory: string;
  subscriptionSearchStringTotalCount: Subscription;
  searchStringTotalCount: number = 0;
  currentSearchPage: number = 1;

  roleFilterList: KgEnumKeyPair[] = [];
  statusFilterList: KgEnumKeyPair[] = [];
  selectedRoleFilterEnum: number;
  selectedStatusFilterEnum: number;
  peopleDetailsChips = [];
  isLoadingData = false;
  roleFilter: number;
  statusFilter: number;
  @ViewChild("peopleFilterSelection")
  peopleFilterSelection: KgComponentMaterialsSelectComponent;
  @ViewChild("peopleStatusFilterSelection")
  peopleStatusFilterSelection: KgComponentMaterialsSelectComponent;

  @ViewChild("searchwarningdialog")
  searchwarningdialog: KgComponentMaterialsDialogComponent;
  warningDialogButtons: KgComponentMaterialsDialogButtonModel[] = [
    { label: "OK", callBackEvent: new EventEmitter<boolean>() }
  ];

  constructor(
    public router: Router,
    public _DomSanitizationService: DomSanitizer,
    private route: ActivatedRoute,
    private peopleService: KgPeopleService,
    private routeDataExchange: KgPeopleRouterDataService,
    public authenticationService: KgAuthenticationService,
    private logs: KgLoggingService,
    private fabService: KgFabService,
    private progressBarService: KgProgressBarService,
    private infiniteScrollService: KgInfiniteScrollService,
    private enumUtility: KgEnumHandler,
    public snacbarService: KgSnackbarService
  ) {
    console.log(this.fabService.instanceId);
    this.populateFabComponet();
    this.onEditClicked.subscribe(x => {
      this.routeDataExchange.data = x;
      this.router.navigate([`/peoples/edit/`, x.Id]);
    });

    this.onRemoveClicked.subscribe(x => {
      if (x.Id == this.authenticationService.myprofile.Id) {
        this.snacbarService.show({
          labelText: "You cannot remove yourself",
          actionButtonText: "OK",

          onClosed: () => {}
        });
      } else {
        for (let r of this.authenticationService.myprofile.Roles) {
          switch (r) {
            case PeopleRoles.Clerk:
              if (
                this.notHasAnyRoles(
                  x.Roles,
                  PeopleRoles.Chairperson,
                  PeopleRoles.Committee,
                  PeopleRoles.Manager
                )
              ) {
                this.removePeople(x);
              } else {
                this.snacbarService.show({
                  labelText: "You are not allowed to remove the person",
                  actionButtonText: "OK",

                  onClosed: () => {}
                });
              }
              break;
            case PeopleRoles.Manager:
              if (
                this.notHasAnyRoles(
                  x.Roles,
                  PeopleRoles.Chairperson,
                  PeopleRoles.Committee
                )
              ) {
                this.removePeople(x);
              } else {
                this.snacbarService.show({
                  labelText: "You are not allowed to remove the person",
                  actionButtonText: "OK",

                  onClosed: () => {}
                });
              }
              break;
            case PeopleRoles.Chairperson:
              if (this.notHasAnyRoles(x.Roles, PeopleRoles.Chairperson)) {
                this.removePeople(x);
              } else {
                this.snacbarService.show({
                  labelText: "You are not allowed to remove the person",
                  actionButtonText: "OK",

                  onClosed: () => {}
                });
              }
              break;
            default:
              this.snacbarService.show({
                labelText: "You are not allowed to remove the person",
                actionButtonText: "OK",

                onClosed: () => {}
              });
              break;
          }
        }
      }
    });
  }

  removePeople(peopleModel: KgPeopleModel) {
    this.progressBarService.start();
    this.peopleService.removePerson(peopleModel.Id).subscribe(
      status => {
        console.log("remove people status:" + status);
      },
      error => {},
      () => {
        this.people.splice(this.people.indexOf(peopleModel), 1);
        this.totalCount--;
        if (this.selectedRoleFilterEnum > -1) {
          let sub = this.peopleService
            .getPeopleRoleByPage(
              this.currentPage - 1,
              PeopleRoles[this.selectedRoleFilterEnum]
            )
            .subscribe(x => {
              this.people.push(x[x.length - 1]);
            });
        } else if (this.selectedStatusFilterEnum > -1) {
          let sub = this.peopleService
            .getPeopleStatusByPage(
              this.currentPage - 1,
              PeopleStatuses[this.selectedStatusFilterEnum]
            )
            .subscribe(x => {
              this.people.push(x[x.length - 1]);
            });
        } else {
          let sub = this.peopleService
            .getPeopleUnitsByPage(this.currentPage - 1)
            .subscribe(x => {
              this.people.push(x[x.length - 1]);
            });
        }
        this.progressBarService.stop();
        this.isLoadingData = false;
      }
    );
  }

  ngOnInit(): void {
    this.infiniteScrollService.start(() => {
      console.log("scroll end from people");
      this.isLoadingData = true;
      this.loadMore();
    });

    this.populateSearchComponent();

    this.routeParamSub = this.route.paramMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        params => {
          this.searchCategory = params.get("searchcategory");
          this.searchString = params.get("searchstring");

          // console.log(this.tagName);
          if (this.authenticationService.hasSession) {
            if (this.searchString != null) {
              this.peopleFindString = this.searchString;
              this.peopleOnSearch = true;
              this.getSearchStringTotalCount(
                this.searchString,
                this.searchCategory
              );
              this.getSearchOutput(this.searchCategory, this.searchString);
            } else {
              this.getPeopleTotalCount();
              this.getPeoplesUnitsByPages();
              this.populateRoleFilterComponent();
              this.populatePeopleStatusFilterComponent();
            }
          }
        },
        error => {
          console.log(error);
        },
        () => {
          console.log("complete done ----------------------------------");
        }
      );
  }

  populateFabComponet() {
    let fabList: KgFabItemModel[] = [
      { icon: "add", route: "/peoples/new", title: "Add new People" }
    ];

    this.fabService.announceNewFabList(fabList);
  }

  populateSearchComponent() {
    this.peopleFindMenuItems = [
      { Title: "By name", Icon: "person", OnClick: this.onNameSearchClicked },
      {
        Title: "By unit No.",
        Icon: "location_city",
        OnClick: this.onUnitNoSearchClicked
      },
      {
        Title: "By IC/Passport",
        Icon: "account_box",
        OnClick: this.onIcPassportSearchClicked
      },
      {
        Title: "By phone number",
        Icon: "phone",
        OnClick: this.onPhoneNoSearchClicked
      },
      { Title: "By email", Icon: "email", OnClick: this.onEmailSearchClicked },
      {
        Title: "By country",
        Icon: "flag",
        OnClick: this.onCountrySearchClicked
      }
    ];

    this.onNameSearchClicked.subscribe(x => {
      if (x != null) {
        this.router.navigate(
          ["search", PeoplesSearchCategory[PeoplesSearchCategory.name], x],
          { relativeTo: this.route.parent }
        );
      }
    });

    this.onUnitNoSearchClicked.subscribe(x => {
      if (x != null) {
        if (this.verifyUnitNoSearchFormat(x)) {
          this.router.navigate(
            ["search", PeoplesSearchCategory[PeoplesSearchCategory.unitno], x],
            { relativeTo: this.route.parent }
          );
        }
      }
    });

    this.onIcPassportSearchClicked.subscribe(x => {
      if (x != null) {
        this.router.navigate(
          [
            "search",
            PeoplesSearchCategory[PeoplesSearchCategory.icpassport],
            x
          ],
          { relativeTo: this.route.parent }
        );
      }
    });

    this.onPhoneNoSearchClicked.subscribe(x => {
      if (x != null) {
        this.router.navigate(
          [
            "search",
            PeoplesSearchCategory[PeoplesSearchCategory.phonenumber],
            x
          ],
          { relativeTo: this.route.parent }
        );
      }
    });

    this.onEmailSearchClicked.subscribe(x => {
      if (x != null) {
        this.router.navigate(
          ["search", PeoplesSearchCategory[PeoplesSearchCategory.email], x],
          { relativeTo: this.route.parent }
        );
      }
    });

    this.onCountrySearchClicked.subscribe(x => {
      if (x != null) {
        this.router.navigate(
          ["search", PeoplesSearchCategory[PeoplesSearchCategory.country], x],
          { relativeTo: this.route.parent }
        );
      }
    });
  }

  populateRoleFilterComponent() {
    this.roleFilterList = this.enumUtility.getEnumKeyItemPairs(PeopleRoles);
    this.roleFilterList.unshift({ key: -1, item: "All" });

    this.roleFilterList.splice(
      this.roleFilterList.findIndex(x => {
        return (x.key as PeopleRoles) == PeopleRoles.Administrator;
      }),
      1
    );
  }

  populatePeopleStatusFilterComponent() {
    this.statusFilterList = this.enumUtility
      .getEnumKeyItemPairs(PeopleStatuses)
      .filter(x => {
        return x.key != PeopleStatuses.New && x.key != PeopleStatuses.Approved;
      });

    this.statusFilterList.unshift({ key: -1, item: "All" });
  }

  onPeopleRoleFilterSelectionChange(roleSelected: PeopleRoles) {
    this.currentPage = 1;
    this.statusFilter = -1;
    this.selectedRoleFilterEnum = roleSelected;
    if (roleSelected > -1) {
      this.getPeopleByRoleTotalCount();
      this.getPeoplesByRoleByPages();
    } else {
      this.getPeopleTotalCount();
      this.getPeoplesUnitsByPages();
    }
    console.log("test:" + roleSelected);
  }

  onPeopleStatusFilterSelectionChange(statusSelected: PeopleStatuses) {
    this.currentPage = 1;
    this.roleFilter = -1;
    this.selectedStatusFilterEnum = statusSelected;
    if (statusSelected > -1) {
      this.getPeopleByStatusTotalCount();
      this.getPeoplesByStatusByPages();
    } else {
      this.getPeopleTotalCount();
      this.getPeoplesUnitsByPages();
    }
    console.log("test:" + statusSelected);
  }

  getSearchStringTotalCount(searchString: string, searchCategory: string) {
    this.subscriptionSearchStringTotalCount = this.peopleService
      .getPeoplesSearchStringTotalCount(searchString, searchCategory)
      .subscribe(
        s => {
          this.searchStringTotalCount = s;
          console.log(this.searchStringTotalCount);
        },
        err => {
          this.logs.error("Get search string total count", err);
        }
      );
  }

  getSearchOutput(findCategory: string, findString: string) {
    this.peopleService
      .getSearchStringByPage(this.currentSearchPage, findCategory, findString)
      .subscribe(x => {
        if (findCategory != "unitno") {
          this.peopleUnitsResponse = null;
          console.log("ObjectType: " + typeof x);
          this.people = x as KgPeopleModel[];
        } else {
          this.peopleUnitsResponse = x as KgUnitResponse[];
        }
      });
    return this.people;
  }

  onFabRefreshClick() {
    console.log("refresh clicked");
  }
  gets(i: any) {
    console.log(i);
    return " from people not that i wanted";
  }
  onRowSelected(selectedIndex: number) {
    if (
      this.selectedIndexes.some(x => {
        return x == selectedIndex;
      })
    ) {
      //remove element that already exist
      this.selectedIndexes.splice(
        this.selectedIndexes.indexOf(selectedIndex),
        1
      );
    } else {
      //if not exist then add them in
      this.selectedIndexes.push(selectedIndex);
    }
  }

  onPageNumber(page: number) {
    this.currentPage = page;
    this.getPeoplesUnitsByPages();
  }

  getPeopleTotalCount() {
    this.progressBarService.toggleProgressBar(true);
    this.userServicesSubscription = this.peopleService
      .getTotalCount()
      .subscribe(
        x => {
          this.totalCount = x;
        },
        err => {
          this.logs.error("people", err);
          console.log(err);

          console.log("from people");
          console.log(this.logs.getAllLog());
          this.progressBarService.toggleProgressBar(false);
        },
        () => {
          this.progressBarService.toggleProgressBar(false);
        }
      );
  }

  getPeoplesUnitsByPages() {
    this.progressBarService.toggleProgressBar(true);

    if (this.checkForRoles()) {
      this.userServicesSubscription = this.peopleService
        .getPeopleUnitsByPage(this.currentPage)
        .subscribe(
          x => {
            this.people = x;
            // this.people$.next(x);
            this.currentPage++;
          },
          err => {
            this.logs.error("people", err);
            console.log(err);

            console.log("from people");
            console.log(this.logs.getAllLog());
            this.progressBarService.toggleProgressBar(false);
          },
          () => {
            this.progressBarService.toggleProgressBar(false);
          }
        );
    }
  }

  getPeopleByRoleTotalCount() {
    this.progressBarService.toggleProgressBar(true);
    this.userServicesSubscription = this.peopleService
      .getPeopleRoleTotalCount(PeopleRoles[this.selectedRoleFilterEnum])
      .subscribe(
        x => {
          this.totalCount = x;
        },
        err => {
          this.logs.error("people", err);
          console.log(err);

          console.log("from people");
          console.log(this.logs.getAllLog());
          this.progressBarService.toggleProgressBar(false);
        },
        () => {
          this.progressBarService.toggleProgressBar(false);
        }
      );
  }
  ///kkkkdfafdas

  getPeoplesByRoleByPages() {
    this.progressBarService.toggleProgressBar(true);

    if (this.checkForRoles()) {
      this.userServicesSubscription = this.peopleService
        .getPeopleRoleByPage(
          this.currentPage,
          PeopleRoles[this.selectedRoleFilterEnum]
        )
        .subscribe(
          x => {
            this.people = x;
            // this.people$.next(x);
            if (this.people.length < this.totalCount) {
              this.currentPage++;
            }
          },
          err => {
            this.logs.error("people", err);
            console.log(err);
            this.people = null;

            this.progressBarService.toggleProgressBar(false);
          },
          () => {
            this.progressBarService.toggleProgressBar(false);
          }
        );
    }
  }

  getPeopleByStatusTotalCount() {
    this.progressBarService.toggleProgressBar(true);
    this.userServicesSubscription = this.peopleService
      .getPeopleStatusTotalCount(PeopleStatuses[this.selectedStatusFilterEnum])
      .subscribe(
        x => {
          this.totalCount = x;
        },
        err => {
          this.logs.error("people", err);
          console.log(err);

          console.log("from people");
          console.log(this.logs.getAllLog());
          this.progressBarService.toggleProgressBar(false);
        },
        () => {
          this.progressBarService.toggleProgressBar(false);
        }
      );
  }
  ///kkkkdfafdas

  getPeoplesByStatusByPages() {
    this.progressBarService.toggleProgressBar(true);

    if (this.checkForRoles()) {
      this.userServicesSubscription = this.peopleService
        .getPeopleStatusByPage(
          this.currentPage,
          PeopleStatuses[this.selectedStatusFilterEnum]
        )
        .subscribe(
          x => {
            this.people = x;
            // this.people$.next(x);
            if (this.people.length < this.totalCount) {
              this.currentPage++;
            }
          },
          (err: HttpErrorResponse) => {
            this.logs.error("people", err);
            console.log(
              `getPeopleStatusByPage(${this.currentPage},${
                PeopleStatuses[this.selectedStatusFilterEnum]
              }) return with error ${err.status}`
            );

            if (err.status == 404) {
              this.snacbarService.show({
                labelText: `No record found for Status: ${
                  PeopleStatuses[this.selectedStatusFilterEnum]
                }`,
                actionButtonText: "Dismiss",
                timeoutMs: 10000
              });
              this.statusFilter = 0;
            }
            this.progressBarService.toggleProgressBar(false);
          },
          () => {
            this.progressBarService.toggleProgressBar(false);
          }
        );
    }
  }

  getProfilePhotoUri(photo: ProfilePhotosModel[]) {
    let found = photo.find(x => {
      return x.IsPrimary && x.PhotoSizeType == PhotoSizeTypes.Icon48px;
    });
    return found ? found.FileUri : null;
  }

  // getPeoples() {
  //   if (this.authenticationService.hasAccessRole(PeopleRoles.Administrator)) {
  //     this.userServicesSubscription = this.peopleService.getAll().subscribe(x => {
  //       this.people = x;
  //     },
  //       err => {
  //         this.logs.error("people", err);
  //         console.log(err);
  //         console.log("from people");
  //         console.log(this.logs.getAllLog());
  //       });
  //   }
  //   else {
  //     this.userServicesSubscription = this.peopleService.getPeopleCreatedById(this.authenticationService.myprofile.Id).subscribe(x => {
  //       this.people = x;
  //     },
  //       err => {
  //         this.logs.error("people", err);
  //         console.log(err);

  //         console.log("from people");
  //         console.log(this.logs.getAllLog());
  //       });
  //   }
  // }

  onFindStringChange(findString: string) {
    this.peopleFindString = findString;
    if (findString.length == 0) {
      this.router.navigate(["peoples"]);
    }
  }

  onClearFind() {
    this.router.navigate(["peoples"]);
  }

  verifyUnitNoSearchFormat(searchString: string): boolean {
    let unitNoString = searchString;
    let unitStringArray = unitNoString.split("-");
    let wildcardArray = unitStringArray.filter(x => x == "*");
    if (unitStringArray.length != 3 || wildcardArray.length > 1) {
      this.searchwarningdialog.mdcDialog.open();
      this.warningDialogButtons[0].callBackEvent.subscribe(
        () => {
          this.router.navigate(["peoples"]);
        },
        error => {
          throw new Error("Error in delete" + error.status);
        }
      );

      return false;
    }

    return true;
  }

  checkRightForDeactivate(peopleDetail: KgPeopleModel) {
    if (peopleDetail.Id == this.authenticationService.myprofile.Id) {
      return false;
    } else {
      // If clerk or manager, cannot deactivate chairperson and committee
      for (let r of this.authenticationService.myprofile.Roles) {
        switch (r) {
          case PeopleRoles.Clerk:
          case PeopleRoles.Manager:
            return this.notHasAnyRoles(
              peopleDetail.Roles,
              PeopleRoles.Chairperson,
              PeopleRoles.Committee
            );
          case PeopleRoles.Chairperson:
            return this.notHasAnyRoles(
              peopleDetail.Roles,
              PeopleRoles.Chairperson
            );
          case PeopleRoles.Administrator:
            return true;
        }
      }
    }
  }

  notHasAnyRoles(rolesToCheck: PeopleRoles[], ...roles: PeopleRoles[]) {
    for (let r of rolesToCheck) {
      return roles.findIndex(x => x == r) == -1;
    }
  }

  checkForRoles() {
    if (this.authenticationService.hasSession) {
      if (
        this.authenticationService.hasAccessRoles([
          PeopleRoles.Administrator,
          PeopleRoles.Chairperson,
          PeopleRoles.Committee,
          PeopleRoles.Manager,
          PeopleRoles.Clerk
        ])
      ) {
        return true;
      } else {
        this.router.navigate(["dashboard"]);
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.fabService.announceNewFabList([]);

    console.log("people component destroyed");
    if (
      this.subscriptionGetPeopleDetail &&
      !this.userServicesSubscription.closed
    ) {
      this.userServicesSubscription.unsubscribe();
    }
    if (
      this.subscriptionGetPeopleDetail &&
      !this.subscriptionGetPeopleDetail.closed
    ) {
      this.subscriptionGetPeopleDetail.unsubscribe();
    }
    this.infiniteScrollService.stop();
  }

  onAdd() {
    this.routeDataExchange.clear();
    this.subscriptionGetPeopleDetail = this.peopleService
      .getById(this.authenticationService.myprofile.Id)
      .subscribe(peopleDetail => {
        let resultParse = peopleDetail as KgPeopleModel;
        let localPeopleModel: KgPeopleModel = new KgPeopleModel();
        localPeopleModel.CommunitiesId = resultParse.CommunitiesId;
        this.routeDataExchange.data = localPeopleModel;
        this.router.navigate(["../new"], { relativeTo: this.route });
      });
  }

  // onEdit(b: boolean) {
  //   this.selectedIndexes.forEach(element => {
  //     this.routeDataExchange.data = this.people[element];
  //   });
  //   this.router.navigate([`edit/`, this.people[this.selectedIndexes[this.selectedIndexes.length - 1]].Id], { relativeTo: this.route });
  // }

  loadMore() {
    if (this.searchString != null) {
      if (
        this.people != null &&
        this.people.length < this.searchStringTotalCount
      ) {
        this.progressBarService.start();
        this.peopleService
          .getSearchStringByPage(
            ++this.currentSearchPage,
            this.searchCategory,
            this.searchString
          )
          .subscribe(t => {
            this.people.push(...(t as KgPeopleModel[]));

            console.log(
              `Page#: ${this.currentSearchPage} ReceivedNumber : ${t.length}`
            );

            this.progressBarService.stop();
            this.isLoadingData = false;
          });
      } else if (
        this.peopleUnitsResponse != null &&
        this.peopleUnitsResponse.length < this.searchStringTotalCount
      ) {
        this.progressBarService.start();
        this.peopleService
          .getSearchStringByPage(
            ++this.currentSearchPage,
            this.searchCategory,
            this.searchString
          )
          .subscribe(t => {
            this.peopleUnitsResponse.push(...(t as KgUnitResponse[]));

            console.log(
              `Page#: ${this.currentSearchPage} ReceivedNumber : ${t.length}`
            );

            this.progressBarService.stop();
            this.isLoadingData = false;
          });
      }
    } else if (this.people) {
      if (this.people.length < this.totalCount) {
        this.progressBarService.start();

        if (this.selectedRoleFilterEnum > -1) {
          let sub = this.peopleService
            .getPeopleRoleByPage(
              this.currentPage,
              PeopleRoles[this.selectedRoleFilterEnum]
            )
            .subscribe(x => {
              this.people.push(...x);
              this.progressBarService.stop();
              this.isLoadingData = false;
              this.currentPage++;
            });
        } else if (this.selectedStatusFilterEnum > -1) {
          let sub = this.peopleService
            .getPeopleStatusByPage(
              this.currentPage,
              PeopleStatuses[this.selectedStatusFilterEnum]
            )
            .subscribe(x => {
              this.people.push(...x);
              this.progressBarService.stop();
              this.isLoadingData = false;
              this.currentPage++;
            });
        } else {
          let sub = this.peopleService
            .getPeopleUnitsByPage(this.currentPage)
            .subscribe(x => {
              this.people.push(...x);
              this.progressBarService.stop();
              this.isLoadingData = false;
              this.currentPage++;
            });
        }
      }
    }
  }

  getCreatedDate(item: KgPeopleUnitsResponseModel): string {
    return distanceInWordsToNow(item.CreateDate);
  }

  chipObj: string[] = [];
  getDetailsChips(item: KgPeopleUnitsResponseModel): KgChipSettings[] {
    if (!this.chipObj[item.Id] || this.chipObj[item.Id] != item.PeopleStatus) {
      this.chipObj[item.Id] = item.PeopleStatus;
      this.peopleDetailsChips[item.Id] = this.generateChipObject(item);
    } else {
      this.chipObj[item.Id] = item.PeopleStatus;
    }

    if (!this.peopleDetailsChips[item.Id]) {
      this.peopleDetailsChips[item.Id] = this.generateChipObject(item);
    }

    return this.peopleDetailsChips[item.Id];
  }

  generateChipObject(item: KgPeopleUnitsResponseModel) {
    let c: KgChipSettings[] = [];
    c.push({
      Color: this.getStatusColor(item.PeopleStatus),
      Title: PeopleStatuses[item.PeopleStatus],
      MdcIcon: "person"
    });
    c.push({
      Color: Colors.Green1,
      Title: item.MobileNumber,
      MdcIcon: "phone"
    });
    c.push({ Color: Colors.Orange2, Title: item.Email, MdcIcon: "email" });

    item.Roles.forEach(x => {
      c.push({
        Color: this.getRoleChipColor(x),
        Title: PeopleRoles[x],
        MdcIcon: "visibility"
      });
    });

    item.PeopleUnits
      ? item.PeopleUnits.forEach(u => {
          c.push({
            Color: Colors.Grey500,
            Title: u.UnitNumber,
            MdcIcon: "home"
          });
        })
      : null;
    return c;
  }
  getStatusColor(status: PeopleStatuses) {
    switch (status) {
      case PeopleStatuses.Activated:
        return Colors.Green500;
      case PeopleStatuses.Approved:
        return Colors.Green200;
      case PeopleStatuses.Barred:
        return Colors.Red400;
      case PeopleStatuses.New:
        return Colors.Yellow400;
      case PeopleStatuses.Submitted:
        return Colors.PurpleA200;
      case PeopleStatuses.Removed:
        return Colors.RedA200;
    }
  }

  getRoleChipColor(role: PeopleRoles) {
    switch (role) {
      case PeopleRoles.Administrator:
        return Colors.Amber400;
      case PeopleRoles.Chairperson:
        return Colors.Blue400;
      case PeopleRoles.Cleaner:
        return Colors.Blue500;
      case PeopleRoles.Clerk:
        return Colors.Green300;
      case PeopleRoles.Committee:
        return Colors.Green400;
      case PeopleRoles.Community:
        return Colors.Orange100;
      case PeopleRoles.Maintainer:
        return Colors.Orange300;
      case PeopleRoles.Manager:
        return Colors.Orange400;
      case PeopleRoles.Owner:
        return Colors.Purple300;
      case PeopleRoles.Resident:
        return Colors.Purple500;
      case PeopleRoles.SecurityGuard:
        return Colors.Teal500;
      case PeopleRoles.Tenant:
        return Colors.Teal300;
      case PeopleRoles.MasterTenant:
        return Colors.Purple300;
    }
  }

  do() {
    // console.log("next ondestroy");
    // LocationHelper.removeHash("aaa");
  }
}
