import { IdentificationTypes } from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";
import { KgFileItemInfoModel } from "src/app/model/kg-filemanager/kg-filemanager.model";
import { KgUnitModel } from "src/app/model/kg-units/kg-unit.model";

export enum VisitorSearchCategory {
  name = 0,
  phonenumber = 1
}

export enum VisitorDisplayType {
  MyVisitor = 0,
  AllVisitor = 1
}

export enum VisitorStatuses {
  New = 0,
  Approved = 1,
  Visiting = 2,
  Visited = 3,
  Rejected = 4,
  Removed = 5
}

export enum VisitorTypes {
  Invited,
  UnInvited
}
export class KgVisitorModel extends KgBaseModel {
  FirstName: string;
  LastName: string;
  IdentificationType: number;
  IdentificationNumber: IdentificationTypes;
  VisitDateTime: Date;
  DurationMinutes: number;
  VisitStatus: VisitorStatuses;
  MobileNumberCountryCode: string;
  MobileNumber: string;
  Email: string;
  Email2: string;
  VehicleRegistrationNumber: string;
  PinNumber: string;
  VisitorType: VisitorTypes;
  IdPhoto: KgFileItemInfoModel;
  VisitUnit: KgUnitModel;

  constructor(visitor?: KgVisitorModel) {
    super();
    if (visitor != null) {
      Object.assign(this, visitor, {
        VisitDateTime: new Date(visitor.VisitDateTime)
      });
    } else {
      Object.assign(this, {
        FirstName: "",
        LastName: "",
        IdentificationType: 0,
        IdentificationNumber: "",
        VisitDateTime: new Date(0),
        DurationMinutes: "",
        VisitStatus: 0,
        MobileNumber: "",
        Email: "",
        Email2: "",
        VehicleRegistrationNumber: "",
        PinNumber: "",
        VisitorType: 0,
        VisitUnit: new KgUnitModel()
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return
    return Object.assign({}, this, {
      // convert fields that need converting
      VisitDateTime: this.VisitDateTime.toISOString()
    });
  }
}
export class KgVisitorResponseModel extends KgVisitorModel {
  CreateByDetails: {
    FirstName: string;
    LastName: string;
  }[];
}
