import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Renderer2,
  AfterViewInit
} from "@angular/core";
import { KgComponentMaterialsCheckboxModel } from "./kg-component-materials-checkbox.model";
import { MDCFormField } from "@material/form-field";
import { MDCCheckbox } from "@material/checkbox";
export interface MDCCheckboxEmitModel {
  mdc: MDCCheckbox;
  key: string;
}
@Component({
  selector: "kg-component-materials-checkbox",
  templateUrl: "./kg-component-materials-checkbox.component.html",
  styleUrls: ["./kg-component-materials-checkbox.component.scss"]
})
export class KgComponentMaterialsCheckboxComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() data: KgComponentMaterialsCheckboxModel;

  @Input() kgid: string;
  @Input() kglabel: string;
  @Input() kgchecked: boolean;
  @Output() kgclick: EventEmitter<MDCCheckboxEmitModel> = new EventEmitter<
    MDCCheckboxEmitModel
  >();
  // @ViewChild(`mdcff`) mdcff: ElementRef;
  // @ViewChild('mdccb') mdccb: ElementRef;
  @Input() kgvalue: string;

  mdcCheckbox: MDCCheckbox;
  mdcFormField: MDCFormField;
  constructor(private rootEl: ElementRef) {}

  ngOnInit() {
    this.mdcFormField = new MDCFormField(this.rootEl.nativeElement);
    this.mdcCheckbox = new MDCCheckbox(
      (this.rootEl.nativeElement as HTMLElement).querySelector(".mdc-checkbox")
    );
    this.mdcFormField.input = this.mdcCheckbox;
  }

  ngAfterViewInit(): void {
    this.mdcCheckbox.checked = this.kgchecked;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == "kgchecked" && this.mdcCheckbox) {
        console.log("changes checked : ", changes["kgchecked"].currentValue);
        this.mdcCheckbox.checked = changes["kgchecked"].currentValue;
      } else if (propName == "kgvalue" && this.mdcCheckbox) {
        console.log("changes kgvalue : ", changes["kgvalue"].currentValue);
        this.mdcCheckbox.value = changes["kgvalue"].currentValue;
      }
    }
  }

  onCheckboxChange(e: Event) {
    console.log("inputchanged", e, this.mdcCheckbox);
    this.kgclick.emit({ mdc: this.mdcCheckbox, key: this.kgvalue });
  }
}
