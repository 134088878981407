import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { KgDebugModeComponent } from "src/app/modules/kg-debug/debugmode/debugmode.component";

@NgModule({
  imports: [
    RouterModule.forChild([{ path: "", component: KgDebugModeComponent }])
  ],
  exports: [RouterModule]
})
export class KgDebugRoutingModule {}
