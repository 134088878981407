import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  AfterViewInit,
  EventEmitter,
  Provider,
  ElementRef,
  SimpleChanges
} from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  NG_VALIDATORS
} from "@angular/forms";
import { MDCTextField } from "@material/textfield";
import { OnChanges } from "@angular/core";
import { MDCTextFieldHelperText } from "@material/textfield/helper-text";

const noop = () => {};

export const TEXT_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KgComponentMaterialsTextfieldComponent),
  multi: true
};

export const MY_VALIDATOR_X: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => KgComponentMaterialsTextfieldComponent),
  multi: true
};

@Component({
  selector: "kg-component-materials-textfield",
  templateUrl: "./kg-component-materials-textfield.component.html",
  styleUrls: ["./kg-component-materials-textfield.component.scss"],
  providers: [TEXT_INPUT_VALUE_ACCESSOR, MY_VALIDATOR_X]
})
export class KgComponentMaterialsTextfieldComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, AfterViewInit {
  control: AbstractControl;
  @Output() onfocus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  @Output() onchange: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onfocusout: EventEmitter<FocusEvent> = new EventEmitter<
    FocusEvent
  >();
  @Output() onclick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Input() kgid: string;
  @Input() kglabel: string;

  @Input() kgtype: string = "text";
  @Input() kgdisabled = false;
  @Input() kgrequired: string;
  @Input() kgmaterialicon: string;
  @Input() kgleadingicon: boolean;
  @Input() kgalertmessage: { [key: string]: any };
  @Input() kgdense = false;
  @Input() kgreadonly = false;
  @Input() kgfullwidth = false;
  @Input() kgmargintop = true;

  @Input() rows: number;
  @Input() cols: number;
  @Input() unfocus = false;

  @Output() onIconClicked = new EventEmitter<MouseEvent>();

  kgShowAlert: boolean;
  private thisControlValue: any;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private onValidatorChange: () => void = noop;
  private _onChange: () => void;
  textField: MDCTextField;
  helperText: MDCTextFieldHelperText;
  kgalertmessagestring: string;
  prefilled = false;

  constructor(private elem: ElementRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop == "kgdisabled") {
        if (this.textField) {
          this.textField.disabled = changes[prop].currentValue;
        }
      }
      // else if (prop == "kgreadonly") {
      //   if (this.textField && changes[prop].currentValue) {
      //     // (this.textField.getDefaultFoundation().getNativeInput_() as HTMLInputElement).setAttribute("readonly", "");
      //   }
      // }
    }
  }
  validate(c: AbstractControl): { [key: string]: any } {
    //Keep a reference copy of this AbstractControl for status change detection
    if (!this.control) {
      this.control = c;
      this.subscribeToControlStatusChange();
    }

    return null;
  }

  subscribeToControlStatusChange() {
    if (this.control != null) {
      this.control.statusChanges.subscribe(x => {
        this.kgalertmessagestring = "";
        // console.log(this.kgid, this.control.errors);
        for (const key in this.control.errors) {
          if (this.kgalertmessage[key] != null) {
            this.kgalertmessagestring += `${this.kgalertmessage[key]},\n`;
          } else {
            throw `kgalertmessage for ${key} is not defined`;
          }
        }

        this.kgalertmessagestring = this.kgalertmessagestring.slice(
          0,
          this.kgalertmessagestring.length - 2
        );
        this.kgShowAlert =
          this.control.touched &&
          !this.control.pristine &&
          this.control.status != "VALID";
        this.textField.valid = !this.kgShowAlert; // ? false : true;
      });
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  ngAfterViewInit(): void {
    this.textField = new MDCTextField(
      this.elem.nativeElement.querySelector(".mdc-text-field")
    );

    this.helperText = new MDCTextFieldHelperText(
      this.elem.nativeElement.querySelector(".mdc-text-field-helper-text")
    );

    // this.textField.valid = true;
    this.textField.disabled = this.kgdisabled;
    // this.kgreadonly ? (this.textField.getDefaultFoundation().getNativeInput_() as HTMLInputElement).setAttribute("readonly", "") : null;
  }

  get value(): any {
    return this.thisControlValue;
  }

  set value(v: any) {
    if (v !== this.thisControlValue) {
      this.thisControlValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.thisControlValue) {
      this.thisControlValue = value;

      if (value != null && value != "") {
        this.prefilled = true;
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onBlurTriggerTouch() {
    if (this.thisControlValue != "") {
      this.onTouchedCallback();
      this.control.markAsTouched();
    }

    if (this.control != null) {
      this.kgShowAlert =
        this.control.touched &&
        !this.control.pristine &&
        this.control.status != "VALID";
      this.textField.valid = !this.kgShowAlert;
    }
  }

  emitFocus(e: FocusEvent) {
    console.log("emitfocus");
    if (this.control && this.control.value != "") {
      this.control.markAsDirty();
      this.control.markAsTouched();
    }

    this.unfocus ? (e.target as HTMLElement).blur() : null;
    this.onfocus.emit(e);
  }

  emitChange(e: Event) {
    console.log("emitfocus");

    this.onchange.emit(e);
  }

  emitFocusOut(e: FocusEvent) {
    this.onfocusout.emit(e);
  }

  iconClicked(event: MouseEvent) {
    this.onIconClicked.emit(event);
    console.log("icon clicked");
  }

  emitOnClick(e: MouseEvent) {
    this.onclick.emit(e);
  }
}
