import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { KgAuthenticationService } from "../kg-authentication/kg-authentication.service";
import { environment } from "environments/environment";

@Injectable()
export class KgAccountsService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  sendResetPasswordEmail(email: string) {
    return this.httpClient.post(
      `${environment.ApiHost}api/account/sendresetpasswordemail`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader(),
        params: {
          email: email
        }
      }
    );
  }

  resetPassword(newPassword: string, token: string) {
    return this.httpClient.post(
      `${environment.ApiHost}api/account/resetpassword`,
      null,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
          SkipAuthorization: "true",
          ResetPassword: "true"
        }),
        params: {
          newPassword: newPassword
        }
      }
    );
  }
}
