import { Injectable } from "@angular/core";
import {
  KgCommunitiesModel,
  CommunitiesConfiguration
} from "../../model/kg-communities/index";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { catchError } from "rxjs/operators/catchError";
import { _throw } from "rxjs/observable/throw";
import { KgReviver } from "../../framework/model/kg-reviver";
import { map } from "rxjs/operators";

@Injectable()
export class KgCommunitiesService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  getAll() {
    return this.httpClient
      .get(environment.ApiHost + "api/communities", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getCommunitiesByPage(page: number) {
    return this.httpClient
      .get(environment.ApiHost + "api/communities/pages/" + page, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAllCommunities() {
    return this.httpClient
      .get(environment.ApiHost + "api/communities/all", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getAllCommunitiesTotalCount() {
    return this.httpClient
      .get<number>(environment.ApiHost + "api/communities/all/totalcount", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  // Get communities information by using CreateById
  getById(id: string, page: number) {
    return this.httpClient
      .get(environment.ApiHost + "api/communities/id/" + id + "/" + page, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getMyCommunitiesDetails() {
    return this.httpClient
      .get<KgCommunitiesModel>(environment.ApiHost + "api/communities/self", {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        map(x => KgReviver.Revive<KgCommunitiesModel>(x, KgCommunitiesModel)),
        catchError(error => {
          return _throw(error);
        })
      );
  }

  // Get communities information by using CreateById
  getByName(name: string) {
    return this.httpClient
      .get<KgCommunitiesModel[]>(
        environment.ApiHost + "api/communities/name/" + name,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addNew(communities: KgCommunitiesModel) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/communities/add",
        JSON.stringify(communities),
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  edit(communities: KgCommunitiesModel) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/communities/edit",
        JSON.stringify(communities),
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  delete(communities: KgCommunitiesModel) {
    return this.httpClient
      .post(
        environment.ApiHost + "api/communities/delete",
        JSON.stringify(communities),
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getCommunitiesConfiguration(communitiesid: string) {
    return this.httpClient
      .get<CommunitiesConfiguration>(
        environment.ApiHost + "api/communities/configuration/" + communitiesid,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  addAccessCardTypes(communities: KgCommunitiesModel) {
    return this.httpClient
      .post(
        environment.ApiHost +
          "api/communities/configuration/addaccesscardtypes",
        communities,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getStorageCommunitiesDetails() {
    return KgReviver.Revive<KgCommunitiesModel>(
      JSON.parse(localStorage.getItem("MyCommunitiesDetail")),
      KgCommunitiesModel
    );
  }

  storeLocalCommunitiesDetails(myCommunitiesDetail: KgCommunitiesModel) {
    localStorage.setItem(
      "MyCommunitiesDetail",
      JSON.stringify(myCommunitiesDetail)
    );
  }

  removeLocalCommunitiesDetails(myCommunitiesDetail: KgCommunitiesModel) {
    localStorage.removeItem("MyCommunitiesDetail");
  }
}
