import { PeopleRoles } from "../../model/kg-core/kg-core.model";
import { KgBaseModel } from "../../model/kg-core/kg-base.model";

export enum NotificationTypes {
  People = 0,
  Announcement = 1,
  Complaint = 2,
  Visitor = 3
}

export class KgNotificationModel extends KgBaseModel {
  PeopleId: string;
  ForRoles: PeopleRoles[];
  Title: string;
  Body: string;
  NotificationType: NotificationTypes;
  ClickAction: string;
  SeenByIds: string[];
  ReadByIds: string[];

  constructor(newNotification?: KgNotificationModel) {
    super();
    if (newNotification != null) {
      Object.assign(this, newNotification);
    } else {
      Object.assign(this, {
        PeopleId: "",
        ForRoles: [],
        Title: "",
        Body: "",
        NotificationType: 0,
        ClickAction: "",
        IsSeen: false,
        SeenByIds: [],
        ReadByIds: []
      });
    }
  }
}
