// Angular
import { NgModule } from "@angular/core";
// import { HttpModule } from '@angular/http';

// Sevices
import { KgPeopleService } from "./services/kg-people/kg-people.service";
import { KgCommunitiesService } from "./services/kg-communities/index";
import { KgManagementsService } from "./services/kg-managements/index";
import { KgAnnouncementService } from "./services/kg-announcement/index";

// Module
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./modules/core/core.module";

// Kg
import { AppComponent } from "./app.component";
import { KgHeaderComponent } from "./components/kg-header/kg-header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { KgComponentDrawerComponent } from "./components/kg-component-drawer/kg-component-drawer.component";
import { KgLoginComponent } from "./components/kg-login/index";

// KgModules
import { KgComponentMaterialsModule } from "./modules/libs/kg-component-materials/index";

import { KgErrorHandler } from "./framework/kg-errorhandler";
import { KgLoggingService } from "./framework/logging/kg-logging.service";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KgFabService } from "./services/kg-fab/kg-fab.service";
import { KgFabComponent } from "./components/kg-fab/kg-fab.component";
import { KgProgressBarComponent } from "src/app/components/kg-progress-bar/kg-progress-bar.component";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";
import { KgInfiniteScrollService } from "src/app/services/kg-infinite-scroll/kg-infinite-scroll.service";
import { KgComplaintDashboardComponent } from "./components/dashboard/kg-complaint-dashboard/kg-complaint-dashboard.component";
import { KgProfileComponent } from "./components/kg-profile/kg-profile.component";
import { KgNotificationsService } from "src/app/services/kg-notifications/kg-notifications.service";
import { KgSpinnerModule } from "src/app/modules/libs/kg-spinner/kg-spinner.module";
import { KgComplaintService } from "src/app/services/kg-complaint/kg-complaint.service";
import { KgComplaintRouterDataService } from "src/app/services/kg-complaint/kg-complaint-routerdata.service";
import { KgManagementDashboardComponent } from "./components/dashboard/kg-management-dashboard/kg-management-dashboard.component";
import { KgVisitorDashboardComponent } from "./components/dashboard/kg-visitor-dashboard/kg-visitor-dashboard.component";
import { KgVisitorService } from "src/app/services/kg-visitor/kg-visitor.service";
import { KgAnnouncementDashboardComponent } from "./components/dashboard/kg-announcement-dashboard/kg-announcement-dashboard.component";
import { KgPeopleCategoriesDashboardComponent } from "./components/dashboard/kg-people-categories-dashboard/kg-people-categories-dashboard.component";
import { KgPeopleCategoriesService } from "src/app/services/kg-people-categories/kg-people-categories.service";
import { KgFirebaseMessagingService } from "src/app/services/kg-firebase/kg-firebase-messaging.service";
import { KgAuthGardService } from "src/app/services/kg-auth-guard/kg-auth-guard.service";
import { KgRequestInterceptorService } from "src/app/services/kg-interceptor/kg-interceptor.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { KGUaService } from "src/app/framework/services/ua.service";
import { KgSnackbarService } from "src/app/services/kg-snackbar/kg-snackbar.service";
import { KgChipsModule } from "src/app/modules/libs/kg-chips/kg-chips.module";
import { DexieDbService } from "src/app/framework/services/dexiedb.service";
import { KgDebugModule } from "src/app/modules/kg-debug/kg-debug.module";
import { ErrorHandler } from "@angular/core";
import { KgTitleModule } from "./modules/libs/kg-title/kg-title.module";
// import { KgCountryCodeModule } from './modules/libs/kg-countrycode/kg-countrycode.module';
import { KgAccountsModule } from "./modules/kg-accounts/kg-accounts.module";
import { KgPeopleRouterDataService } from "./modules/kg-people";
import { KgWidgetPeopleDetailsModule } from "./shared/widgets/kg-widget-people-details/kg-widget-people-details.module";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { KgLibUiMdcIconButtonModule } from "kg-lib-ui-mdc-icon-button";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { KgServiceWorkerService } from "./services/kg-service-worker/kg-service-worker.service";
import { KgLibUiTypographyModule } from "kg-lib-ui-typography";
import { KgDrawerService } from "./services/kg-drawer/kg-drawer.service";

@NgModule({
  imports: [
    CoreModule,
    AppRoutingModule,
    // HttpModule,
    KgComponentMaterialsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    KgSpinnerModule,
    KgChipsModule,
    KgDebugModule,
    KgTitleModule,
    // KgCountryCodeModule,
    KgAccountsModule,
    KgWidgetPeopleDetailsModule,
    KgLibUiMdcIconButtonModule,
    KgLibUiTypographyModule,
    ScrollToModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  declarations: [
    AppComponent,
    KgHeaderComponent,
    FooterComponent,
    DashboardComponent,
    KgComponentDrawerComponent,
    KgLoginComponent,
    KgFabComponent,
    KgProgressBarComponent,
    KgComplaintDashboardComponent,
    KgProfileComponent,
    KgManagementDashboardComponent,
    KgVisitorDashboardComponent,
    KgAnnouncementDashboardComponent,
    KgPeopleCategoriesDashboardComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    KgAuthenticationService,
    KgPeopleService,
    KgCommunitiesService,
    KgManagementsService,
    KgAnnouncementService,
    KgLoggingService,
    KgFabService,
    KgProgressBarService,
    KgInfiniteScrollService,
    KgNotificationsService,
    KgComplaintService,
    KgComplaintRouterDataService,
    KgVisitorService,
    KgPeopleCategoriesService,
    KgFirebaseMessagingService,
    KgAuthGardService,
    KgDrawerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KgRequestInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: KgErrorHandler
    },
    KGUaService,
    KgSnackbarService,
    DexieDbService,
    KgPeopleRouterDataService,
    KgServiceWorkerService
  ]
})
export class AppModule {}
