import {
  Component,
  OnInit,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  Input,
  OnDestroy
} from "@angular/core";
import { KgPeopleService } from "src/app/services/kg-people";
import { ActivatedRoute, Router } from "@angular/router";
import { KgPeopleModel } from "src/app/model/kg-people";
import {
  Salutation,
  PeopleStatuses,
  Gender,
  IdentificationTypes,
  PeopleRoles
} from "src/app/model/kg-core";
import { KgEnumHelper } from "src/app/framework/helper/kg-enum.helper";
import { IBaseRenderDetailsComponent } from "src/app/framework/model/IBaseRenderDetailsComponent";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { KgProgressBarService } from "src/app/services/kg-progress-bar/kg-progress-bar.service";
import { Subject } from "rxjs/Subject";
import { takeUntil } from "rxjs/operators/takeUntil";
import { Colors } from "kg-lib-models";
import { CountriesHelper } from "../../../../framework/helper/countries.helper";
import { format } from "date-fns";
import { KgPeopleUnitsResponseModel } from "../../../../model/kg-units/kg-peopleunitsreponse.model";
import { KgSnackbarService } from "../../../../services/kg-snackbar/kg-snackbar.service";

@Component({
  selector: "kg-widget-people-details",
  templateUrl: "./kg-widget-people-details.component.html",
  styleUrls: ["./kg-widget-people-details.component.scss"] //, '../../../../../../node_modules/flag-icon-css/sass/flag-icon.scss']
})
export class KgWidgetPeopleDetailsComponent
  implements
    OnInit,
    OnChanges,
    OnDestroy,
    IBaseRenderDetailsComponent<
      KgPeopleModel,
      { [key: string]: EventEmitter<any> }
    > {
  onDestroy$ = new Subject();
  @Input() componentInputData: KgPeopleModel | KgPeopleUnitsResponseModel;
  @Input() componentOutput: { [key: string]: EventEmitter<any> };
  @Input() kgeditable: boolean = false;
  @Input() people: KgPeopleModel | KgPeopleUnitsResponseModel;

  setStatusSteps = [
    {
      Status: PeopleStatuses.New,
      StatusText: "Submit Person",
      NextStatus: PeopleStatuses.Submitted
    },
    {
      Status: PeopleStatuses.Submitted,
      StatusText: "Activate Person",
      NextStatus: PeopleStatuses.Activated
    },
    {
      Status: PeopleStatuses.Approved,
      StatusText: "Activate Person",
      NextStatus: PeopleStatuses.Activated
    },
    {
      Status: PeopleStatuses.Activated,
      StatusText: "Deactivate Person",
      NextStatus: PeopleStatuses.Barred
    },
    {
      Status: PeopleStatuses.Barred,
      StatusText: "Activate Person",
      NextStatus: PeopleStatuses.Activated
    }
  ];
  constructor(
    private peopleService: KgPeopleService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: KgAuthenticationService,
    private snackbarService: KgSnackbarService,
    private progressBar: KgProgressBarService
  ) {
    console.log(peopleService.random);
    this.route.paramMap.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      let id = x.get("id") ? x.get("id") : null;
      if (id) {
        this.peopleService.getById(id).subscribe(x => {
          this.people = x;
        });
      }
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName == "componentInputData") {
        changes[propName].isFirstChange()
          ? (this.people = changes[propName].currentValue)
          : null;
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  afterInputDataBind() {
    this.people = this.componentInputData;
  }

  bindPeopleData(people: KgPeopleModel) {
    this.people = people;
  }

  getStatusMdcIcon(people: KgPeopleModel) {
    switch (people.PeopleStatus) {
      case PeopleStatuses.Activated:
        return { Icon: "verified_user", Color: Colors.Green400 };
      case PeopleStatuses.Approved:
        return { Icon: "check_circle", Color: Colors.Green300 };
      case PeopleStatuses.Barred:
        return { Icon: "do_not_disturb", Color: Colors.Red400 };
      case PeopleStatuses.New:
        return { Icon: "fiber_new", Color: Colors.Yellow400 };
      case PeopleStatuses.Submitted:
        return { Icon: "cloud_done", Color: Colors.Yellow500 };
      case PeopleStatuses.Removed:
        return { Icon: "delete_forever", Color: Colors.Red500 };
    }
  }

  getRolesFormatted() {
    return this.people.Roles.map(x => {
      return PeopleRoles[x];
    });
  }

  getStatusSteps(currentStatus: PeopleStatuses) {
    return this.setStatusSteps.find(x => {
      return x.Status == currentStatus;
    });
  }

  onSetStatusButtonClick(currentStatus: PeopleStatuses) {
    if (this.peopleService.isPeopleDetailsReady(this.people)) {
      console.log("setStatus" + currentStatus);
      this.progressBar.start();

      this.peopleService
        .setPepleStatus(
          this.people.Id,
          this.getStatusSteps(currentStatus).NextStatus
        )
        .subscribe(
          result => {
            if (result) {
              this.componentInputData
                ? (this.componentInputData.PeopleStatus = this.getStatusSteps(
                    currentStatus
                  ).NextStatus)
                : null;
              this.people
                ? (this.people.PeopleStatus = this.getStatusSteps(
                    currentStatus
                  ).NextStatus)
                : null;
            } else {
              this.snackbarService.show({
                actionButtonText: "Dismiss",
                labelText:
                  "Failed to change person status, please contact administrator",
                onClosed: () => {
                  console.log("dismissed");
                },
                timeoutMs: 10000
              });
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.progressBar.stop();
          }
        );
    } else {
      this.snackbarService.show({
        actionButtonText: "Dismiss",
        labelText:
          "Person has not assigned with Role and Unit, please assign assign them before changing status",
        onClosed: () => {
          console.log("dismissed");
        },
        timeoutMs: 10000
      });
    }
  }

  onEditPeople(person: KgPeopleModel) {
    this.router.navigate([`/peoples/edit/`, person.Id]);
  }

  get DateOfBirthFormatted() {
    return format(this.people.DateOfBirth, "YYYY-MM-DD");
  }
  get SalutationText() {
    return KgEnumHelper.GetEnumString(this.people.Salutation, Salutation);
  }
  get PeopleStatusText() {
    return KgEnumHelper.GetEnumString(this.people.PeopleStatus, PeopleStatuses);
  }
  get GenderText() {
    return KgEnumHelper.GetEnumString(this.people.Gender, Gender);
  }
  get IdentificationTypeText() {
    return KgEnumHelper.GetEnumString(
      this.people.IdentificationType,
      IdentificationTypes
    );
  }

  getCountryName(countryCode: string) {
    let found = CountriesHelper.COUNTRIESCODES.find(
      x => x.CountryCode == countryCode
    );
    return found ? found.Name : "Unknown";
  }
}
