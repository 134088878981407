export interface ICountryDetails {
  Name: string;
  DialCode: string;
  CountryCode: string;
}
export class CountriesHelper {
  static COUNTRIESWITHCODE = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard and Mcdonald Islands",
    VA: "Holy See Vatican",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's DP",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    PM: "St Pierre and Miquelon",
    VC: "St Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and SSI",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States of America",
    UM: "US Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
    BQ: "Bonaire",
    CW: "Curaçao",
    GG: "Guernsey",
    IM: "Isle of Man",
    JE: "Jersey",
    ME: "Montenegro",
    BL: "Saint Barthélemy",
    MF: "Saint Martin French part)",
    RS: "Serbia",
    SX: "Sint Maarten (Dutch part)",
    SS: "South Sudan",
    XK: "Kosovo"
  };

  static COUNTRIESCODES = [
    { Name: "Afghanistan", DialCode: "93", CountryCode: "AF" },
    { Name: "Albania", DialCode: "355", CountryCode: "AL" },
    { Name: "Algeria", DialCode: "213", CountryCode: "DZ" },
    { Name: "American Samoa", DialCode: "1-684", CountryCode: "AS" },
    { Name: "Andorra", DialCode: "376", CountryCode: "AD" },
    { Name: "Angola", DialCode: "244", CountryCode: "AO" },
    { Name: "Anguilla", DialCode: "1-264", CountryCode: "AI" },
    { Name: "Antarctica", DialCode: "672", CountryCode: "AQ" },
    { Name: "Antigua and Barbuda", DialCode: "1-268", CountryCode: "AG" },
    { Name: "Argentina", DialCode: "54", CountryCode: "AR" },
    { Name: "Armenia", DialCode: "374", CountryCode: "AM" },
    { Name: "Aruba", DialCode: "297", CountryCode: "AW" },
    { Name: "Australia", DialCode: "61", CountryCode: "AU" },
    { Name: "Austria", DialCode: "43", CountryCode: "AT" },
    { Name: "Azerbaijan", DialCode: "994", CountryCode: "AZ" },
    { Name: "Bahamas", DialCode: "1-242", CountryCode: "BS" },
    { Name: "Bahrain", DialCode: "973", CountryCode: "BH" },
    { Name: "Bangladesh", DialCode: "880", CountryCode: "BD" },
    { Name: "Barbados", DialCode: "1-246", CountryCode: "BB" },
    { Name: "Belarus", DialCode: "375", CountryCode: "BY" },
    { Name: "Belgium", DialCode: "32", CountryCode: "BE" },
    { Name: "Belize", DialCode: "501", CountryCode: "BZ" },
    { Name: "Benin", DialCode: "229", CountryCode: "BJ" },
    { Name: "Bermuda", DialCode: "1-441", CountryCode: "BM" },
    { Name: "Bhutan", DialCode: "975", CountryCode: "BT" },
    { Name: "Bolivia", DialCode: "591", CountryCode: "BO" },
    { Name: "Bosnia and Herzegovina", DialCode: "387", CountryCode: "BA" },
    { Name: "Botswana", DialCode: "267", CountryCode: "BW" },
    { Name: "Brazil", DialCode: "55", CountryCode: "BR" },
    {
      Name: "British Indian Ocean Territory",
      DialCode: "246",
      CountryCode: "IO"
    },
    { Name: "British Virgin Islands", DialCode: "1-284", CountryCode: "VG" },
    { Name: "Brunei", DialCode: "673", CountryCode: "BN" },
    { Name: "Bulgaria", DialCode: "359", CountryCode: "BG" },
    { Name: "Burkina Faso", DialCode: "226", CountryCode: "BF" },
    { Name: "Burundi", DialCode: "257", CountryCode: "BI" },
    { Name: "Cambodia", DialCode: "855", CountryCode: "KH" },
    { Name: "Cameroon", DialCode: "237", CountryCode: "CM" },
    { Name: "Canada", DialCode: "1", CountryCode: "CA" },
    { Name: "Cape Verde", DialCode: "238", CountryCode: "CV" },
    { Name: "Cayman Islands", DialCode: "1-345", CountryCode: "KY" },
    { Name: "Central African Republic", DialCode: "236", CountryCode: "CF" },
    { Name: "Chad", DialCode: "235", CountryCode: "TD" },
    { Name: "Chile", DialCode: "56", CountryCode: "CL" },
    { Name: "China", DialCode: "86", CountryCode: "CN" },
    { Name: "Christmas Island", DialCode: "61", CountryCode: "CX" },
    { Name: "Cocos Islands", DialCode: "61", CountryCode: "CC" },
    { Name: "Colombia", DialCode: "57", CountryCode: "CO" },
    { Name: "Comoros", DialCode: "269", CountryCode: "KM" },
    { Name: "Cook Islands", DialCode: "682", CountryCode: "CK" },
    { Name: "Costa Rica", DialCode: "506", CountryCode: "CR" },
    { Name: "Croatia", DialCode: "385", CountryCode: "HR" },
    { Name: "Cuba", DialCode: "53", CountryCode: "CU" },
    { Name: "Curacao", DialCode: "599", CountryCode: "CW" },
    { Name: "Cyprus", DialCode: "357", CountryCode: "CY" },
    { Name: "Czech Republic", DialCode: "420", CountryCode: "CZ" },
    {
      Name: "Democratic Republic of the Congo",
      DialCode: "243",
      CountryCode: "CD"
    },
    { Name: "Denmark", DialCode: "45", CountryCode: "DK" },
    { Name: "Djibouti", DialCode: "253", CountryCode: "DJ" },
    { Name: "Dominica", DialCode: "1-767", CountryCode: "DM" },
    {
      Name: "Dominican Republic",
      DialCode: "1-809, 1-829, 1-849",
      CountryCode: "DO"
    },
    { Name: "East Timor", DialCode: "670", CountryCode: "TL" },
    { Name: "Ecuador", DialCode: "593", CountryCode: "EC" },
    { Name: "Egypt", DialCode: "20", CountryCode: "EG" },
    { Name: "El Salvador", DialCode: "503", CountryCode: "SV" },
    { Name: "Equatorial Guinea", DialCode: "240", CountryCode: "GQ" },
    { Name: "Eritrea", DialCode: "291", CountryCode: "ER" },
    { Name: "Estonia", DialCode: "372", CountryCode: "EE" },
    { Name: "Ethiopia", DialCode: "251", CountryCode: "ET" },
    { Name: "Falkland Islands", DialCode: "500", CountryCode: "FK" },
    { Name: "Faroe Islands", DialCode: "298", CountryCode: "FO" },
    { Name: "Fiji", DialCode: "679", CountryCode: "FJ" },
    { Name: "Finland", DialCode: "358", CountryCode: "FI" },
    { Name: "France", DialCode: "33", CountryCode: "FR" },
    { Name: "French Polynesia", DialCode: "689", CountryCode: "PF" },
    { Name: "Gabon", DialCode: "241", CountryCode: "GA" },
    { Name: "Gambia", DialCode: "220", CountryCode: "GM" },
    { Name: "Georgia", DialCode: "995", CountryCode: "GE" },
    { Name: "Germany", DialCode: "49", CountryCode: "DE" },
    { Name: "Ghana", DialCode: "233", CountryCode: "GH" },
    { Name: "Gibraltar", DialCode: "350", CountryCode: "GI" },
    { Name: "Greece", DialCode: "30", CountryCode: "GR" },
    { Name: "Greenland", DialCode: "299", CountryCode: "GL" },
    { Name: "Grenada", DialCode: "1-473", CountryCode: "GD" },
    { Name: "Guam", DialCode: "1-671", CountryCode: "GU" },
    { Name: "Guatemala", DialCode: "502", CountryCode: "GT" },
    { Name: "Guernsey", DialCode: "44-1481", CountryCode: "GG" },
    { Name: "Guinea", DialCode: "224", CountryCode: "GN" },
    { Name: "Guinea-Bissau", DialCode: "245", CountryCode: "GW" },
    { Name: "Guyana", DialCode: "592", CountryCode: "GY" },
    { Name: "Haiti", DialCode: "509", CountryCode: "HT" },
    { Name: "Honduras", DialCode: "504", CountryCode: "HN" },
    { Name: "Hong Kong", DialCode: "852", CountryCode: "HK" },
    { Name: "Hungary", DialCode: "36", CountryCode: "HU" },
    { Name: "Iceland", DialCode: "354", CountryCode: "IS" },
    { Name: "India", DialCode: "91", CountryCode: "IN" },
    { Name: "Indonesia", DialCode: "62", CountryCode: "ID" },
    { Name: "Iran", DialCode: "98", CountryCode: "IR" },
    { Name: "Iraq", DialCode: "964", CountryCode: "IQ" },
    { Name: "Ireland", DialCode: "353", CountryCode: "IE" },
    { Name: "Isle of Man", DialCode: "44-1624", CountryCode: "IM" },
    { Name: "Israel", DialCode: "972", CountryCode: "IL" },
    { Name: "Italy", DialCode: "39", CountryCode: "IT" },
    { Name: "Ivory Coast", DialCode: "225", CountryCode: "CI" },
    { Name: "Jamaica", DialCode: "1-876", CountryCode: "JM" },
    { Name: "Japan", DialCode: "81", CountryCode: "JP" },
    { Name: "Jersey", DialCode: "44-1534", CountryCode: "JE" },
    { Name: "Jordan", DialCode: "962", CountryCode: "JO" },
    { Name: "Kazakhstan", DialCode: "7", CountryCode: "KZ" },
    { Name: "Kenya", DialCode: "254", CountryCode: "KE" },
    { Name: "Kiribati", DialCode: "686", CountryCode: "KI" },
    { Name: "Kosovo", DialCode: "383", CountryCode: "XK" },
    { Name: "Kuwait", DialCode: "965", CountryCode: "KW" },
    { Name: "Kyrgyzstan", DialCode: "996", CountryCode: "KG" },
    { Name: "Laos", DialCode: "856", CountryCode: "LA" },
    { Name: "Latvia", DialCode: "371", CountryCode: "LV" },
    { Name: "Lebanon", DialCode: "961", CountryCode: "LB" },
    { Name: "Lesotho", DialCode: "266", CountryCode: "LS" },
    { Name: "Liberia", DialCode: "231", CountryCode: "LR" },
    { Name: "Libya", DialCode: "218", CountryCode: "LY" },
    { Name: "Liechtenstein", DialCode: "423", CountryCode: "LI" },
    { Name: "Lithuania", DialCode: "370", CountryCode: "LT" },
    { Name: "Luxembourg", DialCode: "352", CountryCode: "LU" },
    { Name: "Macau", DialCode: "853", CountryCode: "MO" },
    { Name: "Macedonia", DialCode: "389", CountryCode: "MK" },
    { Name: "Madagascar", DialCode: "261", CountryCode: "MG" },
    { Name: "Malawi", DialCode: "265", CountryCode: "MW" },
    { Name: "Malaysia", DialCode: "60", CountryCode: "MY" },
    { Name: "Maldives", DialCode: "960", CountryCode: "MV" },
    { Name: "Mali", DialCode: "223", CountryCode: "ML" },
    { Name: "Malta", DialCode: "356", CountryCode: "MT" },
    { Name: "Marshall Islands", DialCode: "692", CountryCode: "MH" },
    { Name: "Mauritania", DialCode: "222", CountryCode: "MR" },
    { Name: "Mauritius", DialCode: "230", CountryCode: "MU" },
    { Name: "Mayotte", DialCode: "262", CountryCode: "YT" },
    { Name: "Mexico", DialCode: "52", CountryCode: "MX" },
    { Name: "Micronesia", DialCode: "691", CountryCode: "FM" },
    { Name: "Moldova", DialCode: "373", CountryCode: "MD" },
    { Name: "Monaco", DialCode: "377", CountryCode: "MC" },
    { Name: "Mongolia", DialCode: "976", CountryCode: "MN" },
    { Name: "Montenegro", DialCode: "382", CountryCode: "ME" },
    { Name: "Montserrat", DialCode: "1-664", CountryCode: "MS" },
    { Name: "Morocco", DialCode: "212", CountryCode: "MA" },
    { Name: "Mozambique", DialCode: "258", CountryCode: "MZ" },
    { Name: "Myanmar", DialCode: "95", CountryCode: "MM" },
    { Name: "Namibia", DialCode: "264", CountryCode: "NA" },
    { Name: "Nauru", DialCode: "674", CountryCode: "NR" },
    { Name: "Nepal", DialCode: "977", CountryCode: "NP" },
    { Name: "Netherlands", DialCode: "31", CountryCode: "NL" },
    { Name: "Netherlands Antilles", DialCode: "599", CountryCode: "AN" },
    { Name: "New Caledonia", DialCode: "687", CountryCode: "NC" },
    { Name: "New Zealand", DialCode: "64", CountryCode: "NZ" },
    { Name: "Nicaragua", DialCode: "505", CountryCode: "NI" },
    { Name: "Niger", DialCode: "227", CountryCode: "NE" },
    { Name: "Nigeria", DialCode: "234", CountryCode: "NG" },
    { Name: "Niue", DialCode: "683", CountryCode: "NU" },
    { Name: "North Korea", DialCode: "850", CountryCode: "KP" },
    { Name: "Northern Mariana Islands", DialCode: "1-670", CountryCode: "MP" },
    { Name: "Norway", DialCode: "47", CountryCode: "NO" },
    { Name: "Oman", DialCode: "968", CountryCode: "OM" },
    { Name: "Pakistan", DialCode: "92", CountryCode: "PK" },
    { Name: "Palau", DialCode: "680", CountryCode: "PW" },
    { Name: "Palestine", DialCode: "970", CountryCode: "PS" },
    { Name: "Panama", DialCode: "507", CountryCode: "PA" },
    { Name: "Papua New Guinea", DialCode: "675", CountryCode: "PG" },
    { Name: "Paraguay", DialCode: "595", CountryCode: "PY" },
    { Name: "Peru", DialCode: "51", CountryCode: "PE" },
    { Name: "Philippines", DialCode: "63", CountryCode: "PH" },
    { Name: "Pitcairn", DialCode: "64", CountryCode: "PN" },
    { Name: "Poland", DialCode: "48", CountryCode: "PL" },
    { Name: "Portugal", DialCode: "351", CountryCode: "PT" },
    { Name: "Puerto Rico", DialCode: "1-787, 1-939", CountryCode: "PR" },
    { Name: "Qatar", DialCode: "974", CountryCode: "QA" },
    { Name: "Republic of the Congo", DialCode: "242", CountryCode: "CG" },
    { Name: "Reunion", DialCode: "262", CountryCode: "RE" },
    { Name: "Romania", DialCode: "40", CountryCode: "RO" },
    { Name: "Russia", DialCode: "7", CountryCode: "RU" },
    { Name: "Rwanda", DialCode: "250", CountryCode: "RW" },
    { Name: "Saint Barthelemy", DialCode: "590", CountryCode: "BL" },
    { Name: "Saint Helena", DialCode: "290", CountryCode: "SH" },
    { Name: "Saint Kitts and Nevis", DialCode: "1-869", CountryCode: "KN" },
    { Name: "Saint Lucia", DialCode: "1-758", CountryCode: "LC" },
    { Name: "Saint Martin", DialCode: "590", CountryCode: "MF" },
    { Name: "Saint Pierre and Miquelon", DialCode: "508", CountryCode: "PM" },
    {
      Name: "Saint Vincent and the Grenadines",
      DialCode: "1-784",
      CountryCode: "VC"
    },
    { Name: "Samoa", DialCode: "685", CountryCode: "WS" },
    { Name: "San Marino", DialCode: "378", CountryCode: "SM" },
    { Name: "Sao Tome and Principe", DialCode: "239", CountryCode: "ST" },
    { Name: "Saudi Arabia", DialCode: "966", CountryCode: "SA" },
    { Name: "Senegal", DialCode: "221", CountryCode: "SN" },
    { Name: "Serbia", DialCode: "381", CountryCode: "RS" },
    { Name: "Seychelles", DialCode: "248", CountryCode: "SC" },
    { Name: "Sierra Leone", DialCode: "232", CountryCode: "SL" },
    { Name: "Singapore", DialCode: "65", CountryCode: "SG" },
    { Name: "Sint Maarten", DialCode: "1-721", CountryCode: "SX" },
    { Name: "Slovakia", DialCode: "421", CountryCode: "SK" },
    { Name: "Slovenia", DialCode: "386", CountryCode: "SI" },
    { Name: "Solomon Islands", DialCode: "677", CountryCode: "SB" },
    { Name: "Somalia", DialCode: "252", CountryCode: "SO" },
    { Name: "South Africa", DialCode: "27", CountryCode: "ZA" },
    { Name: "South Korea", DialCode: "82", CountryCode: "KR" },
    { Name: "South Sudan", DialCode: "211", CountryCode: "SS" },
    { Name: "Spain", DialCode: "34", CountryCode: "ES" },
    { Name: "Sri Lanka", DialCode: "94", CountryCode: "LK" },
    { Name: "Sudan", DialCode: "249", CountryCode: "SD" },
    { Name: "Suriname", DialCode: "597", CountryCode: "SR" },
    { Name: "Svalbard and Jan Mayen", DialCode: "47", CountryCode: "SJ" },
    { Name: "Swaziland", DialCode: "268", CountryCode: "SZ" },
    { Name: "Sweden", DialCode: "46", CountryCode: "SE" },
    { Name: "Switzerland", DialCode: "41", CountryCode: "CH" },
    { Name: "Syria", DialCode: "963", CountryCode: "SY" },
    { Name: "Taiwan", DialCode: "886", CountryCode: "TW" },
    { Name: "Tajikistan", DialCode: "992", CountryCode: "TJ" },
    { Name: "Tanzania", DialCode: "255", CountryCode: "TZ" },
    { Name: "Thailand", DialCode: "66", CountryCode: "TH" },
    { Name: "Togo", DialCode: "228", CountryCode: "TG" },
    { Name: "Tokelau", DialCode: "690", CountryCode: "TK" },
    { Name: "Tonga", DialCode: "676", CountryCode: "TO" },
    { Name: "Trinidad and Tobago", DialCode: "1-868", CountryCode: "TT" },
    { Name: "Tunisia", DialCode: "216", CountryCode: "TN" },
    { Name: "Turkey", DialCode: "90", CountryCode: "TR" },
    { Name: "Turkmenistan", DialCode: "993", CountryCode: "TM" },
    { Name: "Turks and Caicos Islands", DialCode: "1-649", CountryCode: "TC" },
    { Name: "Tuvalu", DialCode: "688", CountryCode: "TV" },
    { Name: "U.S. Virgin Islands", DialCode: "1-340", CountryCode: "VI" },
    { Name: "Uganda", DialCode: "256", CountryCode: "UG" },
    { Name: "Ukraine", DialCode: "380", CountryCode: "UA" },
    { Name: "United Arab Emirates", DialCode: "971", CountryCode: "AE" },
    { Name: "United Kingdom", DialCode: "44", CountryCode: "GB" },
    { Name: "United States", DialCode: "1", CountryCode: "US" },
    { Name: "Uruguay", DialCode: "598", CountryCode: "UY" },
    { Name: "Uzbekistan", DialCode: "998", CountryCode: "UZ" },
    { Name: "Vanuatu", DialCode: "678", CountryCode: "VU" },
    { Name: "Vatican", DialCode: "379", CountryCode: "VA" },
    { Name: "Venezuela", DialCode: "58", CountryCode: "VE" },
    { Name: "Vietnam", DialCode: "84", CountryCode: "VN" },
    { Name: "Wallis and Futuna", DialCode: "681", CountryCode: "WF" },
    { Name: "Western Sahara", DialCode: "212", CountryCode: "EH" },
    { Name: "Yemen", DialCode: "967", CountryCode: "YE" },
    { Name: "Zambia", DialCode: "260", CountryCode: "ZM" },
    { Name: "Zimbabwe", DialCode: "263", CountryCode: "ZW" }
  ];

  static contains(partial: string) {
    // var re = new RegExp(partial, "i");
    // return this.COUNTRIES..filter(x => {
    //   return x.match(re) ? true : false;
    // })
  }
}
