import { KgBaseModel } from "../../model/kg-core/kg-base.model";
import { KgFileItemInfoModel } from "src/app/model/kg-filemanager/kg-filemanager.model";
import { Salutation } from "src/app/model/kg-core";

export enum AnnouncementSearchCategory {
  // Header = 0,
  content = 0,
  creator = 1
}
export class KgAnnouncementModel extends KgBaseModel {
  AnnouncementHeader: string;
  AnnouncementContent: string;
  AnnouncementTag: string[];
  AnnouncementPublish: boolean;
  EncryptAnnouncement: boolean;
  PlainTextAnnouncement: string;
  AnnouncementInBrief: string;
  AnnouncementAttachment: KgFileItemInfoModel[];
  AnnouncementLanguage: string;
  CreateByName: string;

  get CreateByIdName(): string {
    return this.CreateByName;
  }

  get getCreateDateTime() {
    return this.CreateDate.toLocaleTimeString();
  }

  get getActionDateTime() {
    return this.ActionDate.toLocaleTimeString();
  }

  constructor(announcementModel?: KgAnnouncementModel, createByName?: string) {
    super(announcementModel);
    if (announcementModel != null) {
      Object.assign(this, announcementModel);
      this.CreateByName = createByName;
    } else {
      Object.assign(this, {
        AnnouncementHeader: "",
        AnnouncementContent: "",
        AnnouncementTag: [],
        AnnouncementPublish: false,
        EncryptAnnouncement: false,
        PlainTextAnnouncement: "",
        AnnouncementInBrief: "",
        AnnouncementAttachment: [],
        AnnouncementLanguage: "english"
      });
    }
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return
    return Object.assign({}, this, {
      // convert fields that need converting
      CreateDate: this.CreateDate.toISOString(),
      ActionDate: this.ActionDate.toISOString()
    });
  }
}
export class KgAnnouncementResponseModel extends KgAnnouncementModel {
  constructor(announcementResponse?: KgAnnouncementResponseModel) {
    super();
    if (announcementResponse != null) {
      Object.assign(this, announcementResponse, {
        CreateDate: new Date(announcementResponse.CreateDate),
        ActionDate: new Date(announcementResponse.ActionDate)
      });
    } else {
      Object.assign(this, {
        CreateByPeople: {
          FirstName: "",
          LastName: "",
          Salutation: Salutation.Mr
        }
      });
    }
  }
  CreateByPeople: {
    FirstName: string;
    LastName: String;
    Salutation: Salutation;
  };
}
