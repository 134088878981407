import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  Provider,
  ElementRef,
  Renderer2
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

const noop = () => {};

export const SWITCH_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KgComponentMaterialsSwitchComponent),
  multi: true
};

@Component({
  selector: "kg-component-materials-switch",
  templateUrl: "./kg-component-materials-switch.component.html",
  styleUrls: ["./kg-component-materials-switch.component.scss"],
  providers: [SWITCH_VALUE_ACCESSOR]
})
export class KgComponentMaterialsSwitchComponent
  implements OnInit, ControlValueAccessor {
  @Input() kgid: string;
  @Input() kglabel: string;

  private thisControlValue: boolean;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private onValidatorChange: () => void = noop;
  private _onChange: () => void;

  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {
    // console.log(_renderer);
    // console.log(_elementRef);
  }

  ngOnInit() {}

  get value(): boolean {
    return this.thisControlValue;
  }

  set value(v: boolean) {
    if (v !== this.thisControlValue) {
      this.thisControlValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: boolean) {
    if (value !== this.thisControlValue) {
      this.thisControlValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled
      ? this.renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByTagName("input")[0],
          "disabled",
          ""
        )
      : this.renderer.removeAttribute(
          this._elementRef.nativeElement.getElementsByTagName("input")[0],
          "disabled"
        );
    // isDisabled ? this._elementRef.nativeElement.getElementsByTagName("input")[0].setAttribute("disabled", "") :
    // this._elementRef.nativeElement.getElementsByTagName("input")[0].removeAttribute("disabled");
  }
}
