import { NgModule } from "@angular/core";
import { SharedModule } from "../../../modules/shared/shared.module";
import { KgComponentMaterialsButtonComponent } from "./kg-component-materials-button/kg-component-materials-button.component";
import { KgComponentMaterialsCheckboxComponent } from "./kg-component-materials-checkbox/kg-component-materials-checkbox.component";
import { KgComponentMaterialsRadioComponent } from "./kg-component-materials-radio/kg-component-materials-radio.component";
import { KgComponentMaterialsSelectComponent } from "./kg-component-materials-select/kg-component-materials-select.component";
import { KgComponentMaterialsSwitchComponent } from "./kg-component-materials-switch/kg-component-materials-switch.component";
import { KgComponentMaterialsTextfieldComponent } from "./kg-component-materials-textfield/kg-component-materials-textfield.component";
import { KgComponentMaterialsDialogComponent } from "./kg-component-materials-dialog/kg-component-materials-dialog.component";
import { KgComponentMaterialsDialog2Component } from "./kg-component-materials-dialog2/kg-component-materials-dialog2.component";
import { KgComponentMaterialsDialog2Directive } from "./kg-component-materials-dialog2/kg-component-materials-dialog2.directive";
import { KgComponentMaterialsCard2Component } from "./kg-component-materials-card2/kg-component-materials-card2.component";
import { KgComponentMaterialsCard2Directive } from "./kg-component-materials-card2/kg-component-materials-card2.directive";
import { KgComponentMaterialsCardComponent } from "./kg-component-materials-card/kg-component-materials-card.component";
import { KgComponentMaterialsIconToggleComponent } from "./kg-comnponent-materials-icontoggle/kg-component-materials-icontoggle.component";
import { KgComponentMaterialsLinearProgressComponent } from "./kg-component-materials-linear-progress/kg-component-materials-linear-progress.component";
import { KgComponentMaterialsDatepickerComponent } from "./kg-component-materials-datepicker/kg-component-materials-datepicker.component";
import { KgComponentMaterialsDatepickerDialogComponent } from "./kg-component-materials-datepicker/kg-component-materials-datepicker-dialog/kg-component-materials-datepicker-dialog.component";
import { KgComponentMaterialsDatalistComponent } from "./kg-component-materials-datalist/kg-component-materials-datalist.component";
import { KgComponentMaterialsTabComponent } from "./kg-component-materials-tab/kg-component-materials-tab.component";
import { KgComponentMaterialsDataListDirective } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.directive";
import { KgComponentMaterialsDataListColorDirective } from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist-color.directive";
import { KgComponentMaterialsDatepickerDatesDirective } from "./kg-component-materials-datepicker/kg-component-materials-datepicker-dialog/kg-component-materials-datepicker-dates.directive";
import { KgComponentMaterialsIconsComponent } from "src/app/modules/libs/kg-component-materials/kg-component-materials-icons/kg-component-materials-icons.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    KgComponentMaterialsButtonComponent,
    KgComponentMaterialsCheckboxComponent,
    KgComponentMaterialsRadioComponent,
    KgComponentMaterialsSelectComponent,
    KgComponentMaterialsSwitchComponent,
    KgComponentMaterialsTextfieldComponent,
    KgComponentMaterialsDialogComponent,
    KgComponentMaterialsDialog2Component,
    KgComponentMaterialsDialog2Directive,
    KgComponentMaterialsCard2Component,
    KgComponentMaterialsCard2Directive,
    KgComponentMaterialsDataListDirective,
    KgComponentMaterialsDataListColorDirective,
    KgComponentMaterialsCardComponent,
    KgComponentMaterialsIconToggleComponent,
    KgComponentMaterialsLinearProgressComponent,
    KgComponentMaterialsDatepickerComponent,
    KgComponentMaterialsDatepickerDialogComponent,
    KgComponentMaterialsDatalistComponent,
    KgComponentMaterialsTabComponent,
    KgComponentMaterialsDatepickerDatesDirective,
    KgComponentMaterialsIconsComponent
  ],
  exports: [
    KgComponentMaterialsButtonComponent,
    KgComponentMaterialsCheckboxComponent,
    KgComponentMaterialsRadioComponent,
    KgComponentMaterialsSelectComponent,
    KgComponentMaterialsSwitchComponent,
    KgComponentMaterialsTextfieldComponent,
    KgComponentMaterialsDialogComponent,
    KgComponentMaterialsDialog2Component,
    KgComponentMaterialsCard2Component,
    KgComponentMaterialsCardComponent,
    KgComponentMaterialsIconToggleComponent,
    KgComponentMaterialsLinearProgressComponent,
    KgComponentMaterialsDatepickerComponent,
    KgComponentMaterialsDatalistComponent,
    KgComponentMaterialsTabComponent,
    KgComponentMaterialsIconsComponent
  ]
})
export class KgComponentMaterialsModule {}
