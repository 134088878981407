import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { Colors } from "kg-lib-models";

@Directive({
  selector: "[ChipStyle]"
})
export class ChipStyleDirective implements OnInit {
  @Input() color: string | Colors;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      this.el.nativeElement,
      "background-color",
      typeof this.color == "string" ? this.color : Colors[this.color as Colors]
    );
  }
}
