import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { KgLoginComponent } from "./components/kg-login/index";
import { KgProfileComponent } from "src/app/components/kg-profile/kg-profile.component";
import { KgAuthGardService } from "src/app/services/kg-auth-guard/kg-auth-guard.service";
import { PeopleRoles } from "src/app/model/kg-core";

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [KgAuthGardService],
    data: {
      forRoles: [
        PeopleRoles.Administrator,
        PeopleRoles.Chairperson,
        PeopleRoles.Clerk,
        PeopleRoles.Committee,
        PeopleRoles.Manager,
        PeopleRoles.Owner,
        PeopleRoles.Resident,
        PeopleRoles.Tenant,
        PeopleRoles.MasterTenant
      ]
    }
  },
  { path: "signin", component: KgLoginComponent, data: { signout: false } },
  { path: "signout", component: KgLoginComponent, data: { signout: true } },
  { path: "myprofile", component: KgProfileComponent },
  {
    path: "accounts",
    loadChildren: "./modules/kg-accounts/kg-accounts.module#KgAccountsModule"
  },
  {
    path: "communities",
    loadChildren:
      "./modules/kg-communities/kg-communities.module#KgCommunitiesModule"
  },
  {
    path: "managements",
    loadChildren:
      "./modules/kg-managements/kg-managements.module#KgManagementsModule"
  },
  {
    path: "peoples",
    loadChildren: "./modules/kg-people/kg-people.module#KgPeopleModule"
  },
  {
    path: "announcements",
    loadChildren:
      "./modules/kg-announcement/kg-announcement.module#KgAnnouncementModule"
  },
  {
    path: "complaint",
    loadChildren: "./modules/kg-complaint/kg-complaint.module#KgComplaintModule"
  },
  {
    path: "filemanager",
    loadChildren:
      "./modules/kg-filemanager/kg-filemanager.module#KgFileManagerModule"
  },
  {
    path: "visitors",
    loadChildren: "./modules/kg-visitors/kg-visitors.module#KgVisitorsModule"
  },
  {
    path: "v",
    loadChildren:
      "./modules/kg-invitation/kg-invitation.module#KgInvitationModule"
  },
  {
    path: "setup",
    loadChildren: "./modules/kg-setup/kg-setup.module#KgSetupModule"
  },
  {
    path: "units",
    loadChildren: "./modules/kg-units/kg-units.module#KgUnitsModule"
  },
  {
    path: "notifications",
    loadChildren:
      "./modules/kg-notifications/kg-notifications.module#KgNotificationsModule"
  },
  {
    path: "debugmode",
    loadChildren: "./modules/kg-debug/kg-debug.module#KgDebugModule"
  },
  {
    path: "security",
    loadChildren: "./modules/kg-security/kg-security.module#KgSecurityModule",
    canActivate: [KgAuthGardService],
    data: {
      forRoles: [PeopleRoles.Administrator, PeopleRoles.SecurityGuard]
    }
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "/dashboard" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  //this is a new comment
}
