import {
  Component,
  OnInit,
  EventEmitter,
  OnDestroy,
  AfterViewInit
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { KgLoggingService } from "../../framework/logging/kg-logging.service";
import { KgFabItemModel } from "src/app/model/kg-fab/kg-fab.model";
import { KgFabService } from "src/app/services/kg-fab/kg-fab.service";
import {
  KgComplaintModel,
  ComplaintStatuses
} from "src/app/model/kg-complaint/kg-complaint";
import {
  KgComponentMaterialsDataListSettingModel,
  IconModuleTypes
} from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { Subscription } from "rxjs";
import { KgComplaintService } from "src/app/services/kg-complaint/kg-complaint.service";
import { KgComplaintRouterDataService } from "src/app/services/kg-complaint/kg-complaint-routerdata.service";
import { PeopleRoles, ManagementsStatus } from "src/app/model/kg-core";
import { KgManagementsService } from "src/app/services/kg-managements";
import { KgManagementsModel } from "src/app/model";
import { KgVisitorService } from "src/app/services/kg-visitor/kg-visitor.service";
import { HttpResponse } from "@angular/common/http";
import { KgVisitorModel } from "src/app/model/kg-visitors/kg-visitor.model";
import {
  KgAnnouncementModel,
  KgAnnouncementResponseModel
} from "src/app/model/kg-announcement";
import { KgAnnouncementService } from "src/app/services/kg-announcement";
import { KgPeopleCategoriesService } from "src/app/services/kg-people-categories/kg-people-categories.service";
import {
  KgPeopleModel,
  ProfilePhotosModel,
  PhotoSizeTypes
} from "src/app/model/kg-people";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { environment } from "environments/environment";
import { format } from "date-fns";

@Component({
  moduleId: module.id,
  selector: "my-dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  maxItems: number = 5;
  progress: string;
  selecteddatetime: string;

  OnRouteComplaint = new EventEmitter<KgComplaintModel>();
  OnRouteManagement = new EventEmitter<KgManagementsModel>();
  OnRouteVisitor = new EventEmitter<KgVisitorModel>();
  OnRouteAnnouncement = new EventEmitter<KgAnnouncementModel>();
  OnRoutePeople = new EventEmitter<KgPeopleModel>();

  subscriptionGetComplaint: Subscription;
  subscriptionGetManagements: Subscription;
  count = 0;
  subscriptionGetVisitors: Subscription;
  subscriptionGetAnnouncement: Subscription;
  subscriptionGetCommittees: Subscription;
  subscriptionGetPeopleManagement: Subscription;

  complaintList: KgComplaintModel[] = [];
  complaintSetting: KgComponentMaterialsDataListSettingModel<
    KgComplaintModel
  > = {
    IconField: {
      Icon: item => {
        return this.DisplayComplaintIconStatus(item.ComplaintStatus);
      },
      ModuleType: IconModuleTypes.Mdc
    },
    OnItemClick: this.OnRouteComplaint,
    RenderComponent: null
  };
  managementList: KgManagementsModel[] = [];
  managementSetting: KgComponentMaterialsDataListSettingModel<
    KgManagementsModel
  > = {
    IconField: {
      Icon: item => {
        return this.DisplayManagementIconStatus(item.Status);
      },
      ModuleType: IconModuleTypes.Mdc
    },
    OnItemClick: this.OnRouteManagement,
    RenderComponent: null
  };
  visitorList: KgVisitorModel[] = [];
  visitorSetting: KgComponentMaterialsDataListSettingModel<KgVisitorModel> = {
    // IconField: { Icon: item => { return this.DisplayVisitorIconStatus(item.VisitStatus) }, ModuleType: IconModuleTypes.Mdc },
    OnItemClick: this.OnRouteVisitor,
    RenderComponent: null
  };
  announcementList: KgAnnouncementResponseModel[] = [];
  announcementSetting: KgComponentMaterialsDataListSettingModel<
    KgAnnouncementModel
  > = {
    // IconField: { Icon: "rss_feed", ModuleType: IconModuleTypes.Mdc },
    OnItemClick: this.OnRouteAnnouncement,
    RenderComponent: null
  };
  committeeList: KgPeopleModel[] = [];
  peopleManagementList: KgPeopleModel[] = [];
  peopleSetting: KgComponentMaterialsDataListSettingModel<KgPeopleModel> = {
    // IconField: { Icon: "verified_user", ModuleType: IconModuleTypes.Mdc },
    IconField: {
      Icon: item => {
        return item.ProfilePhotos && item.ProfilePhotos.length > 0
          ? this.getProfilePhotoUri(item.ProfilePhotos)
          : environment.ResourceTyped.StaticResource.ProfileUri;
      },
      ModuleType: IconModuleTypes.Image
    }, //"ProfileIcon",
    OnItemClick: this.OnRoutePeople,
    RenderComponent: null
  };

  constructor(
    private logs: KgLoggingService,
    private fabService: KgFabService,
    private routeDataExchange: KgComplaintRouterDataService,
    public router: Router,
    private route: ActivatedRoute,
    private authenticationService: KgAuthenticationService,
    private complaintService: KgComplaintService,
    private managementService: KgManagementsService,
    private visitorService: KgVisitorService,
    private announcementService: KgAnnouncementService,
    private peopleCategoriesService: KgPeopleCategoriesService
  ) {
    this.OnRouteComplaint.subscribe((x: KgComplaintModel) => {
      routeDataExchange.data = new KgComplaintModel();
      routeDataExchange.data = x;
      this.router.navigate(["../complaint/" + x.Id], {
        relativeTo: this.route
      });
    });
    this.OnRouteManagement.subscribe((x: KgManagementsModel) => {
      this.router.navigate(["../managements/" + x.Id], {
        relativeTo: this.route
      });
    });
    this.OnRouteVisitor.subscribe((x: KgVisitorModel) => {
      this.router.navigate(["../visitors/" + x.Id], { relativeTo: this.route });
    });
    this.OnRouteAnnouncement.subscribe((x: KgAnnouncementModel) => {
      this.router.navigate([`../announcements/id/`, x.Id], {
        relativeTo: this.route.parent
      });
    });
    this.OnRoutePeople.subscribe((x: KgPeopleModel) => {
      this.router.navigate([`../peoples/`, x.Id], {
        relativeTo: this.route.parent
      });
    });
  }

  ngOnInit(): void {
    this.getAnnouncementList();
    this.getComplaintList();
    this.getManagementsList();
    this.getVisitorList();
    this.getCommitteesList();
    this.getPeopleManagementList();
    this.populateFabComponet();
  }

  ngAfterViewInit() {
    // this.getComplaintList();
    // this.getManagementsList();
    // this.getVisitorList();
    // this.getAnnouncementList();
    // this.getCommitteesList();
    // this.getPeopleManagementList();
  }

  getComplaintList() {
    if (
      this.authenticationService.hasAccessRoles([
        PeopleRoles.Administrator,
        PeopleRoles.Chairperson,
        PeopleRoles.Committee,
        PeopleRoles.Clerk,
        PeopleRoles.Manager
      ])
    ) {
      this.subscriptionGetComplaint = this.complaintService
        .getLimitedComplaint(this.maxItems, "ActionDate")
        .subscribe(
          complaint => {
            let resultparse = complaint as KgComplaintModel[];
            this.complaintList = resultparse.map(x => {
              return new KgComplaintModel(x);
            });
          },
          err => {
            this.complaintList = null;
            this.logs.error("Get maximum 5 complaint for dashboard", err);
          }
        );
    } else {
      this.subscriptionGetComplaint = this.complaintService
        .getLimitedComplaintCreatedByMe(this.maxItems)
        .subscribe(
          complaint => {
            let resultparse = complaint as KgComplaintModel[];
            this.complaintList = resultparse.map(x => {
              return new KgComplaintModel(x);
            });
          },
          err => {
            this.complaintList = null;
            this.logs.error("Get maximum 5 complaint for dashboard", err);
          }
        );
    }
  }

  DisplayComplaintIconStatus(complaintStatus: ComplaintStatuses) {
    switch (complaintStatus) {
      case ComplaintStatuses.New:
        return "fiber_new";
      case ComplaintStatuses.Done:
        return "done";
      case ComplaintStatuses.Pending:
        return "pan_tool";
      case ComplaintStatuses.InProgress:
        return "move_to_inbox";
      case ComplaintStatuses.Rejected:
        return "block";
    }
  }

  DisplayManagementIconStatus(managementStatus: ManagementsStatus) {
    switch (managementStatus) {
      case ManagementsStatus.Active:
        return "verified_user";
      case ManagementsStatus.Deactivated:
        return "report_problem";
      case ManagementsStatus.Banned:
        return "error";
      case ManagementsStatus.Removed:
        return "delete_forever";
    }
  }

  getManagementsList() {
    this.subscriptionGetManagements = this.managementService
      .GetLimitedActiveManagement(this.maxItems - 4)
      .subscribe(
        managements => {
          let resultparse = managements as KgManagementsModel[];
          this.managementList = resultparse.map(x => {
            return new KgManagementsModel(x);
          });
        },
        err => {
          this.managementList = null;
          this.logs.error("Get active management for dashboard", err);
        }
      );
  }

  getVisitorList() {
    this.subscriptionGetVisitors = this.visitorService
      .GetLimitedVisitors(this.maxItems)
      .subscribe(
        visitors => {
          if (visitors instanceof HttpResponse) {
            if (visitors.status == 204) {
              this.visitorList = null;
            } else {
              this.visitorList = visitors.body.map(x => {
                return Object.assign(x, {
                  FullName: `${x.FirstName}, ${x.LastName}`,
                  VisitDateTimeFormatted: `Visit on : ${format(
                    x.VisitDateTime,
                    "Do MMM YYYY hh:mm A"
                  )}`
                });
              });
            }
          }
        },
        err => {
          this.visitorList = null;
          this.logs.error("Get visitor list for dashboard", err);
        }
      );
  }

  getAnnouncementList() {
    this.subscriptionGetAnnouncement = this.announcementService
      .getLimitedAnnouncements(this.maxItems)
      .subscribe(
        announcements => {
          // let resultparse = announcements as KgAnnouncementModel[];
          this.announcementList = announcements.map(x => {
            return new KgAnnouncementResponseModel(x);
          });
        },
        err => {
          this.announcementList = null;
          this.logs.error("Get active management for dashboard", err);
        }
      );
  }

  getCommitteesList() {
    this.subscriptionGetCommittees = this.peopleCategoriesService
      .getPeopleByCategories("Committees")
      .subscribe(
        committees => {
          let resultparse = committees as KgPeopleModel[];
          this.committeeList = resultparse.map(x => {
            return new KgPeopleModel(x);
          });
        },
        err => {
          this.committeeList = null;
          this.logs.error("Get committees list for dashboard", err);
        }
      );
  }

  getPeopleManagementList() {
    this.subscriptionGetPeopleManagement = this.peopleCategoriesService
      .getPeopleByCategories("Management")
      .subscribe(
        management => {
          let resultparse = management as KgPeopleModel[];
          this.peopleManagementList = resultparse.map(x => {
            return new KgPeopleModel(x);
          });
        },
        err => {
          this.peopleManagementList = null;
          this.logs.error("Get committees list for dashboard", err);
        }
      );
  }

  populateFabComponet() {
    let fabList: KgFabItemModel[] = [];

    this.authenticationService.myprofile.Roles.forEach(r => {
      switch (r) {
        case PeopleRoles.Administrator:
        case PeopleRoles.Chairperson:
        case PeopleRoles.Manager:
        case PeopleRoles.Clerk:
          fabList = this.addFabItemIfNotExist(fabList, {
            icon: "person_add",
            route: "peoples/new",
            title: "Add new People"
          });
          fabList = this.addFabItemIfNotExist(fabList, {
            icon: "insert_drive_file",
            route: "/announcements/new",
            title: "Add new announcement"
          });
          break;
        case PeopleRoles.Owner:
        case PeopleRoles.MasterTenant:
          fabList = this.addFabItemIfNotExist(fabList, {
            icon: "person_add",
            route: "peoples/new",
            title: "Add new People"
          });
          break;
      }
    });

    fabList = this.addFabItemIfNotExist(fabList, {
      icon: "forum",
      route: "complaint/new",
      title: "Add Complaint"
    });
    fabList = this.addFabItemIfNotExist(fabList, {
      icon: "add",
      route: "visitors/invitation",
      title: "Create Invitation"
    });
    this.fabService.announceNewFabList(fabList);
  }

  addFabItemIfNotExist(fabs: KgFabItemModel[], fab: KgFabItemModel) {
    fabs.findIndex(x => {
      return x.route == fab.route;
    }) == -1
      ? fabs.push(fab)
      : null;
    return fabs;
  }

  ngOnDestroy() {
    if (this.subscriptionGetComplaint) {
      this.subscriptionGetComplaint.unsubscribe();
    }
    if (this.subscriptionGetManagements) {
      this.subscriptionGetManagements.unsubscribe();
    }
    if (this.subscriptionGetVisitors) {
      this.subscriptionGetVisitors.unsubscribe();
    }
    if (this.subscriptionGetAnnouncement) {
      this.subscriptionGetAnnouncement.unsubscribe();
    }
    this.fabService.announceNewFabList([]);
  }

  getProfilePhotoUri(photo: ProfilePhotosModel[]) {
    let found = photo.find(x => {
      return x.IsPrimary && x.PhotoSizeType == PhotoSizeTypes.Icon48px;
    });
    return found ? found.FileUri : null;
  }
}
