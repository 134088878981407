import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ContentChild,
  TemplateRef
} from "@angular/core";
import { Colors } from "kg-lib-models";

export interface IKgMaterialIcons {
  Icon: string;
  Color: Colors;
}

@Component({
  selector: "kg-component-materials-icons",
  templateUrl: "./kg-component-materials-icons.component.html",
  styleUrls: ["./kg-component-materials-icons.component.scss"]
})
export class KgComponentMaterialsIconsComponent implements OnInit, OnChanges {
  @Input() kgid: string;
  @Input() kglabel: string;
  @Output() kgclick = new EventEmitter<MouseEvent>();
  @Input() kgclass: string | string[] | Set<string> | { [klass: string]: any };
  @Input() kgstyle: { [key: string]: string };
  @Input() kgicon: IKgMaterialIcons;
  @ContentChild("KGContent") kgiconcontent: TemplateRef<any>;
  @Input() kgaligncenter = true;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {}

  get iconColor() {
    return { color: this.kgicon.Color };
  }
}
