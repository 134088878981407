import { EventEmitter } from "@angular/core";

export class KgComponentMaterialsRadioModel {
  onClick = new EventEmitter<boolean>();
  items: KgComponentMaterialsRadioItemModel[];
  constructor(items: KgComponentMaterialsRadioItemModel[]) {
    this.items = items;
  }
}

export class KgComponentMaterialsRadioItemModel {
  value: any;
  label: string;

  constructor(value: any, label: string) {
    this.value = value;
    this.label = label;
  }
}
