import { Observable, Observer } from "rxjs";
import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { KgLog, IKgLog } from "src/app/framework/model/kg-log.model";
class KgDexieDatabase extends Dexie {
  kglogs: Dexie.Table<IKgLog, number>; // number = type of the primkey
  constructor(databaseName: string, version: number) {
    super(databaseName);
    this.version(version).stores({
      kglogs: "++id,datetime,category,from,log"
    });
  }
}

@Injectable()
export class DexieDbService {
  db: KgDexieDatabase;

  constructor() {
    this.db = new KgDexieDatabase("kglog", 1);
    // this.db.kglogs
    //   .reverse()
    //   .offset(10)
    //   .where()
    //   .below()
    //   .delete()
    //   .then(count => {
    //     console.log("deleted kglog count:", count);
    //   })
  }

  async put(log: KgLog) {
    return await this.db.kglogs.put(log);
  }

  get(pageSize: number, pageNumber: number): Observable<IKgLog[]> {
    return Observable.create(async (observer: Observer<IKgLog[]>) => {
      try {
        let logs = await this.db.kglogs
          .reverse()
          .offset(pageSize * (pageNumber - 1))
          .limit(pageSize)
          .toArray();

        observer.next(logs);
        observer.complete();
      } catch (error) {
        observer.error(error);
      }
    });
  }
}
