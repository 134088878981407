import { Component, OnInit } from "@angular/core";
import { KgFabItemModel } from "../../model/kg-fab/kg-fab.model";
import { Router, ActivatedRoute } from "@angular/router";
import { KgFabService } from "../../services/kg-fab/kg-fab.service";

@Component({
  selector: "kg-fab",
  templateUrl: "./kg-fab.component.html",
  styleUrls: ["./kg-fab.component.scss"]
})
export class KgFabComponent implements OnInit {
  fabItemList: KgFabItemModel[];
  showFab = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fabService: KgFabService
  ) {
    this.fabService.fab$.subscribe(x => {
      this.fabItemList = [];
      this.fabItemList = x;
    });
  }

  ngOnInit() {}

  doButtonClick(e: MouseEvent, i: KgFabItemModel) {
    this.fabItemList = [];
    i.onclick
      ? i.onclick.emit(true)
      : this.router.navigate([i.route], { relativeTo: this.route });
  }

  async toggleFab(e: MouseEvent, item: KgFabItemModel) {
    if ((this.showFab && item) || this.fabItemList.length == 1) {
      await setTimeout(() => {
        this.doButtonClick(e, item);
      }, 500);
    }
    this.showFab = !this.showFab && this.fabItemList.length > 1;
  }
}
