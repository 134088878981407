import {
  Component,
  OnInit,
  Output,
  Input,
  AfterViewInit,
  EventEmitter,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
  Type,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  ComponentRef,
  ContentChild,
  TemplateRef
} from "@angular/core";
import { Location } from "@angular/common";
import { MDCDialog, MDCDialogFoundation } from "@material/dialog";
import { KgComponentMaterialsDialog2Directive } from "../../../../modules/libs/kg-component-materials/kg-component-materials-dialog2/kg-component-materials-dialog2.directive";
import { IBaseDynamicComponent } from "src/app/framework/model/IBaseDynamicComponent";
@Component({
  selector: "kg-component-materials-dialog2",
  templateUrl: "./kg-component-materials-dialog2.component.html",
  styleUrls: ["./kg-component-materials-dialog2.component.scss"]
})
export class KgComponentMaterialsDialog2Component
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  mdcDialog: MDCDialog;
  @Input() kgid: string;
  @Input() kgtitle: string;

  @Input() renderComponent: Type<any>;
  @Input() kginput: any;
  @Input() kgoutput: any;
  @Output() kgonclose = new EventEmitter<boolean>();

  lastTarget: HTMLElement;
  @ContentChild("RenderComponentTemplate") renderComponentTemplate: TemplateRef<
    any
  >;

  @ViewChild(KgComponentMaterialsDialog2Directive)
  renderer: KgComponentMaterialsDialog2Directive;
  componentRef: ComponentRef<IBaseDynamicComponent<any, any>>;
  dialogCancelFunc: () => void = () => {
    console.log("dialog canceled canceled");
    this.kgonclose.emit(true);
  };

  dialogAcceptFunc: () => void = () => {
    this.lastTarget.focus();
    this.kgonclose.emit(true);
  };
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.renderComponent ? this.loadComponent() : null;
  }

  ngAfterViewInit() {
    this.mdcDialog = new MDCDialog(
      document.querySelector(`#mdc-dialog2-${this.kgid}`)
    );
    this.mdcDialog.listen(
      MDCDialogFoundation.strings.CLOSED_EVENT,
      this.dialogCancelFunc
    );
    // this.mdcDialog.listen(MDCDialogFoundation.strings.ACCEPT_EVENT, this.dialogAcceptFunc);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef != null && this.componentRef != undefined) {
      console.log(changes);
      for (let propName in changes) {
        if (propName == "kginput") {
          let chng = changes[propName].currentValue;
          console.log(chng);
          this.componentRef.instance.componentInputData = chng;
          this.componentRef.instance.afterInputDataBind();
        }
        if (propName == "kgoutput") {
          this.componentRef.instance.componentOutput =
            changes[propName].currentValue;
        }
      }
    }
  }

  ngOnDestroy() {
    this.mdcDialog.unlisten(
      MDCDialogFoundation.strings.CLOSED_EVENT,
      this.dialogCancelFunc
    );
  }

  loadComponent() {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.renderComponent
    );
    let viewContainerRef = this.renderer.viewContainerRef;

    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(
      componentFactory,
      null,
      this._viewContainerRef.parentInjector
    );
    this.componentRef.instance.componentInputData = this.kginput;
    this.componentRef.instance.componentOutput = this.kgoutput;
  }

  show(lastTarget?: HTMLElement) {
    this.lastTarget = lastTarget;
    this.componentRef.instance.afterShown
      ? this.componentRef.instance.afterShown()
      : null;
    this.mdcDialog.isOpen ? null : this.mdcDialog.open();
  }

  //Close dialog
  close() {
    if (this.mdcDialog.isOpen) {
      this.mdcDialog.close();
      this.kgonclose.emit(true);
    } else {
      null;
    }
  }
}
