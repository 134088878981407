import { FormGroup, ValidatorFn, AsyncValidatorFn } from "@angular/forms";
import { KgFormControlsHelper } from "../../framework/helper/kg-form-controls.helper";
export class KgFormGroupBase<T> extends FormGroup {
  constructor(
    kgmodel: T,
    validators: { [key: string]: ValidatorFn[] },
    asyncValidators?: { [key: string]: AsyncValidatorFn[] }
  ) {
    super(
      KgFormControlsHelper.GetFormControlsWithValidators(
        kgmodel,
        validators,
        asyncValidators
      )
    );
  }
}
