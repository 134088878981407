import { KgBaseModel } from "../kg-core/kg-base.model";
import { KgCommunitiesSettings } from "./kg-communities-settings";

export class KgCommunitiesSettingsResponse extends KgBaseModel {
  CommunitiesSettings: KgCommunitiesSettings;

  constructor(communitiesSettingsResponse?: KgCommunitiesSettingsResponse) {
    super();
    if (communitiesSettingsResponse != null) {
      Object.assign(this, communitiesSettingsResponse);
    } else {
      Object.assign(this, {
        CommunitiesSettings: { EnableVMS: false }
      });
    }
  }
}
