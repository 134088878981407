import {
  Component,
  OnInit,
  EventEmitter,
  ElementRef,
  ViewChild
} from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { Router, ActivatedRoute } from "@angular/router";

import { PeopleRoles } from "../../model/kg-core/kg-core.model";
import { KgPeopleService } from "../../services/kg-people/index";
import { KgPeopleCommunitiesModel } from "../../model/kg-joint_model/index";
import { MDCMenu } from "@material/menu";
import { PhotoSizeTypes } from "src/app/model/kg-people";
import { KgNotificationsService } from "src/app/services/kg-notifications/kg-notifications.service";
import {
  KgNotificationModel,
  NotificationTypes
} from "src/app/model/kg-notifications/kg-notification.model";
import {
  KgComponentMaterialsDataListSettingModel,
  IconModuleTypes
} from "src/app/modules/libs/kg-component-materials/kg-component-materials-datalist/kg-component-materials-datalist.model";
import { KgSpinnerComponent } from "src/app/modules/libs/kg-spinner/kg-spinner/kg-spinner.component";
import { KgFirebaseMessagingService } from "src/app/services/kg-firebase/kg-firebase-messaging.service";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { environment } from "environments/environment";
import { KGUaService } from "src/app/framework/services/ua.service";
import { LocationHelper } from "src/app/framework/helper/kg-location.helper";
import { Location } from "@angular/common";
import { KgDrawerService } from "../../services/kg-drawer/kg-drawer.service";

@Component({
  moduleId: module.id,
  selector: "kg-header-component",
  templateUrl: "kg-header.component.html",
  styleUrls: ["kg-header.component.scss"]
})
export class KgHeaderComponent implements OnInit {
  newNotificationCount: number;
  @ViewChild("menu") menu: ElementRef;
  @ViewChild("notifications") notificationsMenu: ElementRef;
  @ViewChild("notificationPanel") notificationPanel: ElementRef;
  @ViewChild("myspinner") spinner: KgSpinnerComponent;
  env = environment;
  mdcMenu: MDCMenu;
  mdcNotificationsMenu: MDCMenu;
  subscriptionAuthenticate: Subscription;
  showConfiguration: boolean;
  peopleId: string;
  peopleCommunitiesModel: KgPeopleCommunitiesModel;
  accountSetting;
  authorizedRoles: PeopleRoles[] = [PeopleRoles.Clerk, PeopleRoles.Manager];
  showNotificationPanel = false;
  goToNotification: EventEmitter<KgNotificationModel>;
  notifications: KgNotificationModel[] = [];
  pushNotifications: KgNotificationModel[] = [];
  notificationsSetting: KgComponentMaterialsDataListSettingModel<
    KgNotificationModel
  > = {
    // PrimaryTitleField: "Title",
    // SecondaryTitleField: "Body",
    IconField: {
      Icon: item => {
        switch (item.NotificationType) {
          case NotificationTypes.Announcement:
            return "rss_feed";
          case NotificationTypes.Complaint:
            return "forum";
          case NotificationTypes.People:
            return "recent_actors";
          case NotificationTypes.Visitor:
            return "assignment_ind";
          default:
            return "info_outline";
        }
      },
      ModuleType: IconModuleTypes.Mdc
    },
    OnItemClick: new EventEmitter<KgNotificationModel>(),
    HideItemClickIcon: true
  };

  fcmMessageSubscription: Subscription;

  constructor(
    private peopleService: KgPeopleService,
    private router: Router,
    public authenticationService: KgAuthenticationService,
    private route: ActivatedRoute,
    private notificationService: KgNotificationsService,
    private messagingService: KgFirebaseMessagingService,
    private uaService: KGUaService,
    private _location: Location,
    private drawerService: KgDrawerService
  ) {
    this.subscriptionAuthenticate = this.authenticationService.authentication$.subscribe(
      userAuthenticated => {
        if (userAuthenticated) {
          if (this.authenticationService.hasSession) {
            this.getNotificationCount();
            this.fcmMessageSubscription = this.messagingService.fcmMessageObservable.subscribe(
              x => {
                console.log(x);
                this.pushNotifications.unshift(x.data);
                this.newNotificationCount++;
              }
            );
          }
        } else {
          this.fcmMessageSubscription
            ? this.fcmMessageSubscription.unsubscribe()
            : null;
        }
      }
    );

    this.notificationsSetting.OnItemClick.subscribe(
      (x: KgNotificationModel) => {
        this.showNotificationPanel = false;
        let url = new URL(x.ClickAction);
        this.router.navigate([url.pathname + url.search], { replaceUrl: true });
      }
    );
  }

  ngOnInit() {
    this._location.subscribe(x => {
      this.showNotificationPanel =
        x.type == "hashchange" && LocationHelper.hasHash("latest");
    });

    if (this.authenticationService.hasSession) {
      this.getNotificationCount();
    }
    console.log(this.spinner);

    if (
      this.uaService.hasAnyBrowser(
        environment.ResourceTyped.FCMSupportedBrowser
      )
    ) {
      this.accountSetting = [
        {
          icon: "perm_identity",
          title: "My Profile",
          onClick: e => this.onProfileClick(e)
        },
        {
          icon: "phonelink_setup",
          title: "Setup",
          onClick: e => this.onSetupClick(e)
        },
        {
          icon: "exit_to_app",
          title: "Sign out",
          onClick: e => this.onSignoutClick(e)
        }
      ];
    } else {
      this.accountSetting = [
        {
          icon: "perm_identity",
          title: "My Profile",
          onClick: e => this.onProfileClick(e)
        },
        {
          icon: "exit_to_app",
          title: "Sign out",
          onClick: e => this.onSignoutClick(e)
        }
      ];
    }
  }

  onProfileClick(e) {
    this.router.navigate(["/myprofile"]);
  }

  onSignoutClick(e: MouseEvent) {
    e.stopPropagation();
    this.mdcMenu.open = false;
    this.mdcMenu.destroy();
    this.mdcMenu = null;
    this.router.navigate(["/signout"]);
  }

  onSetupClick(e) {
    this.router.navigate(["/setup"]);
  }

  getNotificationCount() {
    this.notificationService.countUnseenNotifications().subscribe(x => {
      this.newNotificationCount = x;
    });
  }

  showMenu(e: MouseEvent) {
    this.mdcMenu = !this.mdcMenu ? this.initMdcMenu() : this.mdcMenu;
    this.mdcMenu.open = !this.mdcMenu.open;
  }

  initMdcMenu() {
    let newmenu = new MDCMenu(this.menu.nativeElement);
    newmenu.setAnchorCorner(9);
    // newmenu.setAnchorMargin({
    //   top: 20,
    //   right: 0,
    //   bottom: 0,
    //   left: 0
    // });
    return newmenu;
  }

  // checkRoles() {
  //   if (this.authenticationService.hasAccessRoles(this.authorizedRoles)) {
  //     this.showConfiguration = true;
  //   } else {
  //     this.showConfiguration = false;
  //   }
  // }

  getProfileIcon() {
    let photoItem = this.authenticationService.myprofile.ProfilePhotos
      ? this.authenticationService.myprofile.ProfilePhotos.find(x => {
          return x.IsPrimary && x.PhotoSizeType == PhotoSizeTypes.Icon48px;
        })
      : null;

    return photoItem ? photoItem.FileUri : null;
  }

  shownotification(e: MouseEvent) {
    //    this.mdcNotificationsMenu = !this.mdcNotificationsMenu ? new MDCSimpleMenu(this.notificationsMenu.nativeElement) : this.mdcNotificationsMenu;
    //  this.mdcNotificationsMenu.open ? this.mdcNotificationsMenu.hide() : this.mdcNotificationsMenu.show();
    if (this.showNotificationPanel) {
      this.showNotificationPanel = false;
      // LocationHelper.removeHash("latest");
      this._location.back();
    } else {
      this.spinner.show();
      this.notificationService.getByPage(10, 1).subscribe(x => {
        this.notifications = x;
        this.newNotificationCount -= x.length;
        this.newNotificationCount =
          this.newNotificationCount < 0 ? 0 : this.newNotificationCount;

        x.length > 0 ? this.spinner.hide() : this.spinner.show(true);
        this.notifications.length > 0
          ? this.setNotificationsSeen()
          : console.log(
              "no new notifications, skip set seen for notifications"
            );
      });
      this.showNotificationPanel = true;
      LocationHelper.addHash("latest");
    }
  }

  addLocationHash(hash: string) {
    location.hash = location.hash + hash;
  }

  removeLocationHash(hash: string) {
    location.hash = location.hash.replace(hash, "");
  }

  closeNotificationPanel() {
    // this.showNotificationPanel = false;
    // LocationHelper.removeHash('latest');
    this._location.back();
  }

  viewAllNotifications(e: MouseEvent) {
    this.showNotificationPanel = false;
    this.router.navigate(["/notifications"]);
  }

  setNotificationsSeen() {
    this.notificationService
      .setNotificationsSeen(
        this.notifications.map(x => {
          return x.Id;
        })
      )
      .subscribe(y => {
        console.log(y);
      });
  }

  showNew(item: KgNotificationModel) {
    return item.SeenByIds.length > 0 ? "Seen" : "New";
  }

  toggleDrawer() {
    this.drawerService.showDrawer();
    // // this.temporaryDrawer.open = this.temporaryDrawer.open ? false : true;
    // if (this._location.path(true).indexOf("#drawershow") == -1) {
    //   LocationHelper.addHash("#drawershow");
    // }
  }
}
