import { Injectable } from "@angular/core";
import { KgAuthenticationService } from "src/app/services/kg-authentication/kg-authentication.service";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { environment } from "environments/environment";
import {
  KgVisitorModel,
  VisitorStatuses,
  KgVisitorResponseModel,
  VisitorDisplayType
} from "src/app/model/kg-visitors/kg-visitor.model";
import { Observable } from "rxjs/Observable";
import { catchError } from "rxjs/operators/catchError";
import { _throw } from "rxjs/observable/throw";

@Injectable()
export class KgVisitorService {
  visitorStatusEnum = VisitorStatuses;
  visitorDisplayTypeEnum = VisitorDisplayType;
  random = Math.random();
  constructor(
    private httpClient: HttpClient,
    private authenticationService: KgAuthenticationService
  ) {}

  addNewVisitor(visitor: KgVisitorModel) {
    return this.httpClient.post<KgVisitorModel>(
      `${environment.ApiHost}api/visitors/addnew`,
      visitor,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
  }

  PatchVisitorStatus(visitorId: string, visitorStatus: VisitorStatuses) {
    const req = new HttpRequest(
      "POST",
      `${environment.ApiHost}api/visitors/${visitorId}/${
        this.visitorStatusEnum[visitorStatus]
      }`,
      null,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request(req);
  }

  deleteVisitor(visitor: KgVisitorModel) {
    const req = new HttpRequest(
      "DELETE",
      `${environment.ApiHost}api/visitors/`,
      visitor,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );

    return this.httpClient.request(req);
  }

  getAllVisitorsCount(displayType: VisitorDisplayType) {
    const req = new HttpRequest(
      "GET",
      `${environment.ApiHost}api/visitors/allvisitorscount/` +
        this.visitorDisplayTypeEnum[displayType],
      { headers: this.authenticationService.getAccessTokenHeader() }
    );

    return this.httpClient.request<number>(req);
  }

  getVisitorByPage(pageNumber: number, displayType: VisitorDisplayType) {
    // const req = new HttpRequest('GET', `${environment.ApiHost}api/visitors/pages/8/` + pageNumber, { headers: this.authenticationService.getAccessTokenHeader() });

    return this.httpClient.get<KgVisitorResponseModel[]>(
      `${environment.ApiHost}api/visitors/` +
        this.visitorDisplayTypeEnum[displayType] +
        `/pages/8/` +
        pageNumber,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getAllVisitors() {
    const req = new HttpRequest("GET", `${environment.ApiHost}api/visitors`, {
      headers: this.authenticationService.getAccessTokenHeader()
    });

    return this.httpClient.request<KgVisitorResponseModel[]>(req);
  }

  GetLimitedVisitors(maxNumber: number) {
    const req = new HttpRequest(
      "GET",
      `${environment.ApiHost}api/visitors/dashboard/` + maxNumber,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );

    return this.httpClient.request<KgVisitorModel[]>(req);
  }

  GetLimitedVisitorsByVisitorStatus(
    maxNumber: number,
    visitorStatus: VisitorStatuses
  ) {
    const req = new HttpRequest(
      "GET",
      `${environment.ApiHost}api/visitors/dashboard/` +
        maxNumber +
        "/visitorstatus/" +
        this.visitorStatusEnum[visitorStatus],
      { headers: this.authenticationService.getAccessTokenHeader() }
    );

    return this.httpClient.request<KgVisitorModel[]>(req);
  }

  GetVisitorById(visitorId: string) {
    const req = new HttpRequest(
      "GET",
      `${environment.ApiHost}api/visitors/byid/` + visitorId,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );

    return this.httpClient.request<KgVisitorResponseModel>(req);
  }

  GetInvitationByMobileNumber(visitorId: string, mobileNumber: string) {
    return this.httpClient.get<KgVisitorResponseModel>(
      `${
        environment.ApiHost
      }api/invitations/bymobilenumber/${visitorId}/${mobileNumber}`,
      { headers: this.authenticationService.getSkipAuthorizationHeader() }
    );
  }

  GetVisitorByPinNumber(pinNumber: string) {
    return this.httpClient
      .get<KgVisitorModel>(environment.ApiHost + "api/visitors/" + pinNumber, {
        headers: this.authenticationService.getAccessTokenHeader()
      })
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getVisitorsSearchStringTotalCount(
    searchString: string,
    searchCategory: string
  ) {
    return this.httpClient.get<number>(
      environment.ApiHost +
        `api/visitors/searchstringtotalcount/${searchCategory}/${searchString}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getSearchStringByPage(
    page: number,
    searchCategory: string,
    searchString: string
  ): Observable<KgVisitorModel[]> {
    return this.httpClient
      .get<KgVisitorModel[]>(
        `${
          environment.ApiHost
        }api/visitors/search/${searchCategory}/${searchString}/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  getVisitorsSearchByDateTotalCount(startDate: Date, endDate: Date) {
    return this.httpClient.get<number>(
      environment.ApiHost +
        `api/visitors/date/${startDate.toISOString()}/${endDate.toISOString()}`,
      { headers: this.authenticationService.getAccessTokenHeader() }
    );
  }

  getVisitorsByDateRangeByPage(page: number, startDate: Date, endDate: Date) {
    return this.httpClient
      .get<KgVisitorModel[]>(
        `${
          environment.ApiHost
        }api/visitors/${startDate.toISOString()}/${endDate.toISOString()}/8/${page}`,
        { headers: this.authenticationService.getAccessTokenHeader() }
      )
      .pipe(
        catchError(error => {
          return _throw(error);
        })
      );
  }

  uploadIdPhoto(visitId: string, idPhotoFormData: FormData) {
    return this.httpClient.post<any>(
      `${environment.ApiHost}api/visitors/uploadidphoto/${visitId}`,
      idPhotoFormData,
      {
        reportProgress: true,
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
  }

  getPhotoToken() {
    return this.httpClient.get<string>(
      `${environment.ApiHost}api/visitors/phototoken`,
      {
        headers: this.authenticationService.getAccessTokenHeader()
      }
    );
  }
}
