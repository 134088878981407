// <reference path="ddd"
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Provider,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { MDCSelect } from "@material/select";
import {
  KgEnumHandler,
  KgEnumKeyPair
} from "../../../../utility/kg-enum-handler";

const noop = () => {};

export const SELECT_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KgComponentMaterialsSelectComponent),
  multi: true
};

@Component({
  selector: "kg-component-materials-select",
  templateUrl: "./kg-component-materials-select.component.html",
  styleUrls: ["./kg-component-materials-select.component.scss"],
  providers: [SELECT_INPUT_VALUE_ACCESSOR]
})
export class KgComponentMaterialsSelectComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {
  @Input() kgid: string;
  @Input() kglabel: string;
  @Input() kgtype: string;
  @Input() kgrequired: string;
  @Input() kgalertmessage: string;
  @Input() kgselectionlist: KgEnumKeyPair[]; // kgselectlist: { [key: string]: any };
  @Input() kgselectionenum: any;
  @Input() kgvaluetype: string = "number";
  @Input() kgdefaulttext: string;
  @Output() kgonchange = new EventEmitter<any>();
  @Input() kgdisabled: boolean = false;
  @Input() kgwidth: string;
  @ViewChild("mdcselect") mdcSelectViewChild: ElementRef;
  mdcSelect: MDCSelect;
  kgShowAlert: boolean;

  // readonly maxWidth = "60vw";

  private thisControlValue: any = -1;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private onValidatorChange: () => void = noop;
  private _onChange: () => void;

  prefilled = false;
  typedString: string = "";

  constructor(private el: ElementRef, private enumUtility: KgEnumHandler) {}

  ngOnInit() {
    if (this.kgselectionenum) {
      this.kgselectionlist = this.enumUtility.getEnumKeyItemPairs(
        this.kgselectionenum
      );
    }
  }

  ngAfterViewInit() {
    // console.log(this.el);
    this.mdcSelect = new MDCSelect(this.mdcSelectViewChild.nativeElement);

    this.mdcSelect.listen("click", (e: MouseEvent) => {
      e.stopPropagation();
    });

    this.mdcSelect.listen("change", () => {
      console.log(
        "Selected list item index: " + this.mdcSelect.selectedIndex,
        this.mdcSelect.value
      );
      switch (this.kgvaluetype) {
        case "number":
          this.value = +this.mdcSelect.value;
          break;
        case "string":
          this.value = this.mdcSelect.value;
          break;
        default:
          console.error("unknown uknown value for kgvaluetype");
          break;
      }

      console.log("Selected list item value: " + this.value);
      this.kgonchange ? this.kgonchange.emit(this.value) : null;
    });

    this.kgselectionlist ? this.refreshSelection() : null;
    // this.kgwidth ? this.select.getDefaultFoundation().adapter_.setStyle('width', `${this.kgwidth}px`) : null;

    this.mdcSelect.disabled = this.kgdisabled;
    // this.select.getDefaultFoundation().adapter_.setStyle('max-width', '60vw');
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop == "kgselectionlist") {
        this.mdcSelect ? this.refreshSelection() : null;
      } else if (prop == "kgdisabled") {
        this.mdcSelect
          ? (this.mdcSelect.disabled = changes[prop].currentValue)
          : null;
      }
    }
  }

  refreshSelection() {
    //offset 1 index because there is empty unselectable option item in list at index 0
    this.mdcSelect.selectedIndex =
      this.kgselectionlist
        .map(x => {
          return x.key;
        })
        .indexOf(this.thisControlValue.toString()) + 1;

    if (this.mdcSelect.selectedIndex != -1) {
      (this.mdcSelect.getDefaultFoundation() as any).adapter_.floatLabel(true);
    }
  }

  reset() {
    this.thisControlValue = -1;
    this.refreshSelection();
  }

  get value(): any {
    return this.thisControlValue;
  }

  set value(v: any) {
    if (v !== this.thisControlValue) {
      this.thisControlValue = v;
      this.refreshSelection();
      this.onChangeCallback(v);
    }
  }

  writeValue(value: number) {
    if (value != null) {
      if (value !== this.thisControlValue) {
        this.thisControlValue = value;
        this.mdcSelect ? this.refreshSelection() : null;
        if (value != -1) {
          this.prefilled = true;
        }
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onBlurTriggerTouch() {
    if (this.thisControlValue != -1) {
      this.onTouchedCallback();
    }
  }
  getDefaultText() {
    return this.kgdefaulttext ? this.kgdefaulttext : this.kglabel;
  }
}
